/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import axios from "axios";
import { BiLaptop } from "react-icons/bi";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import Slider from "react-slick";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import MenuNavigation from "./MenuNavigation";
import ProductList from "./ProductList";
import ProductDetail from "./ProductDetail";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  pickupId,
  mediaUrl,
  siteURL,
} from "../Helpers/Config";
import {
  getAliasName,
  stripslashes,
  showLoader,
  hideLoader,
  getReferenceID,
  showAlert,
} from "../Helpers/SettingHelper";
import {
  GET_GLOBAL_SETTINGS,
  GET_MENU_NAVIGATION,
  GET_ALL_OUTLETS,
  GET_SITE_LOCATION,
} from "../../actions";
var base64 = require("base-64");
import noimage from "../../common/images/no-img-product.png";
import zoomimg from "../../common/images/search-org.png";
import filterimg from "../../common/images/filter.png";
import gridimg from "../../common/images/grid.png";
import groupred from "../../common/images/group-red.png";
import groupdark from "../../common/images/group.png";
import groupwhite from "../../common/images/group-white.png";
import closebl from "../../common/images/close-block.png";
import ProductSlider1 from "../../common/images/product-slider-1.png";
import ProductSlider2 from "../../common/images/product-slider-2.png";
import restuarantbanner from "../../common/images/restuarant-banner.jpg";
import productLogo from "../../common/images/logo.png";
import facebook from "../../common/images/facebook-brand.svg";
import instagram from "../../common/images/instagram-brand.svg";

import searchorg from "../../common/images/search-org.png";
import filter from "../../common/images/filter.png";

import { type } from "jquery";
import moment from "moment";
const isEqual = require("react-fast-compare");
var Parser = require("html-react-parser");

class Index extends Component {
  constructor(props) {
    super(props);
    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll =
      currentPageUrl[1] !== "" &&
      typeof currentPageUrl[1] !== undefined &&
      typeof currentPageUrl[1] !== "undefined"
        ? currentPageUrl[1]
        : "";
    var current_Page_Url = "";
    var current_Page_Url2 =
      currentPageUrl[2] !== "" &&
      typeof currentPageUrl[2] !== undefined &&
      typeof currentPageUrl[2] !== "undefined"
        ? currentPageUrl[2]
        : "";
    var resbreadcrumb = "";
    if (currentPageUrll == "food") {
      current_Page_Url = "food";
      resbreadcrumb = "Restaurants";
    } else {
      current_Page_Url = "retail";
      resbreadcrumb = "Retail";
    }

    current_Page_Url2 = current_Page_Url + "/" + current_Page_Url2;

    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    var outletLocationId =
      cookie.load("outletLocationId") !== "" &&
      typeof cookie.load("outletLocationId") !== undefined &&
      typeof cookie.load("outletLocationId") !== "undefined"
        ? cookie.load("outletLocationId")
        : "";
    var tempOutletLocationId =
      cookie.load("tempOutletLocationId") !== "" &&
      typeof cookie.load("tempOutletLocationId") !== undefined &&
      typeof cookie.load("tempOutletLocationId") !== "undefined"
        ? cookie.load("tempOutletLocationId")
        : "";
    var diffLocation = "N";
    if (outletLocationId !== "") {
      if (outletLocationId !== tempOutletLocationId) {
        diffLocation = "Y";
      }
    } else {
      outletLocationId = tempOutletLocationId;
    }

    const orderPostalCode =
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";

    this.state = {
      settings: [],
      currentPageUrl: current_Page_Url,
      currentpage_group_url: current_Page_Url2,
      slugEvent:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent != "undefined"
          ? this.props.match.params.slugEvent
          : "",
      deliveryPostalCode: deliveryPostalCode,
      resbreadcrumb: resbreadcrumb,
      navigateMenu: [],
      selectedCategoryName: "",
      selectedNavigation: "",
      selectedSlugType: "",
      searchSubCat: "",
      searchProVal: "",
      viewProductSlug: "",
      catNavIndex: 0,
      catslugType: "",
      catslugValue: "",
      cartTriggerFlg: "No",
      outlet_slug: this.props.match.params.slugRestaurant,
      siteLocation: this.props.match.params.siteLocation,
      defaultAvilTy: avilablityIdTxt,
      outletslist: [],
      alloutlets: [],
      tag: [],
      promotion: [],
      restaurantdetails: "",
      order_date: new Date(),
      cartOutletList: [],
      load_product: "Yes",
      searchProResult: [],
      selectedProResult: [],
      totalCartCount: 0,
      orderOutletId:
        cookie.load("orderOutletId") !== "" &&
        typeof cookie.load("orderOutletId") !== undefined
          ? cookie.load("orderOutletId")
          : "",
      shopeClosed: 0,
      shareGroupUrl: "",
      orderPostalCode: orderPostalCode,
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      cartDetails: "",
      cartGroups: "",
      cartClearTriggerFlg: "no",
      selectPickupTime: "No",
      res_min_date: "",
      tempOutletLocationId: tempOutletLocationId,
      outletLocationId: outletLocationId,
      diffLocation: diffLocation,
      restaurantbackgroundImg: "",
      applyFilter: "N",
      tagList: [],
      tagList_error_message: "",
      tagdisplay: "",
      filterTag: [],
      siteLocationList: [],
      mallname: "",
      outletLocationId:
        cookie.load("outletLocationId") !== "" &&
        typeof cookie.load("outletLocationId") !== undefined &&
        typeof cookie.load("outletLocationId") !== "undefined"
          ? cookie.load("outletLocationId")
          : "",
    };

    var orderOutletId = cookie.load("orderOutletId");
    if (orderOutletId === "" || orderOutletId === undefined) {
      //cookie.save("orderPopuptrigger", "Yes");
      //this.props.history.push("/");
      //return;
    }

    showLoader("restaurant-main", "class");

    this.props.getSettings();
    this.props.getMenuNavigationList(
      this.props.match.params.slugRestaurant,
      avilablityIdTxt,
      this.state.currentPageUrl,
      this.state.slugEvent
    );
    this.loadProductTagList();
    //this.loadSiteLocation();
    this.props.getloadSiteLocation();
  }

  componentWillReceiveProps(nextProps) {
    /*if(this.state.selectedCategoryName!==nextProps.selectedCatry){
      this.setState({selectedCategoryName: nextProps.selectedCatry});
    }*/

    $(".dvLoadrCls").fadeOut(2000);

    let slugType =
      typeof this.props.match.params.slugType !== "undefined"
        ? this.props.match.params.slugType
        : "";
    let slugValue =
      typeof this.props.match.params.slugValue !== "undefined"
        ? this.props.match.params.slugValue
        : "";
    let proValue =
      typeof this.props.match.params.proValue !== "undefined"
        ? this.props.match.params.proValue
        : "";
    let selectedNavigation = nextProps.selectedNavigation;
    let navigateMenu = nextProps.navigateMenu;

    let selectedSlugType = nextProps.selectedSlugType;
    var searchSubCat = "";
    if (!isEqual(this.props.match.params, nextProps.match.params)) {
      slugType =
        typeof nextProps.match.params.slugType !== "undefined"
          ? nextProps.match.params.slugType
          : "";
      slugValue =
        typeof nextProps.match.params.slugValue !== "undefined"
          ? nextProps.match.params.slugValue
          : "";
      proValue =
        typeof nextProps.match.params.proValue !== "undefined"
          ? nextProps.match.params.proValue
          : "";
    }

    if (slugValue !== "") {
      if (slugType !== "category" && slugType !== "subcategory") {
        searchSubCat = slugValue;
        slugValue = slugType;
        slugType = "category";
        /*$('.search_result').hide();*/
        var tmpVl = "";
        $("#productsearch").val(tmpVl);
        $("#clearSearch").hide();
        $(".hsearch_sec").removeClass("open");
        $(".hsearch_trigger").removeClass("active");
        setTimeout(function () {
          if ($("." + proValue).length > 0) {
            $("html, body").animate(
              {
                scrollTop: $("." + proValue).offset().top - 150,
              },
              500
            );
          }
        }, 1000);
      }
      selectedNavigation = slugValue;
    }

    if (slugType === "") {
      slugType = selectedSlugType;
    }

    if (
      selectedNavigation !== this.state.selectedNavigation ||
      this.state.selectedCategoryName !== nextProps.selectedCatry
    ) {
      /* showLoader("productlist-main-div", "class"); */

      if (proValue === "") {
        /*  setTimeout(function () {
          $(window).scrollTo($(".common-inner-banner"), 800);
        }, 3000); */
      }

      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        navigateMenu: navigateMenu,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
        loadproduct: "Yes",
      });
    }

    if (nextProps.navigateMenu !== this.state.navigateMenu) {
      if (Object.keys(nextProps.navigateMenu).length > 0) {
        this.setState({ load_product: "Yes" });
      } else {
        this.setState({ load_product: "No" });
      }
    }

    if (this.state.load_product === "No") {
      if (slugType === "subcategory") {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_subcate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].subcategory_name
            : nextProps.selectedCatry;
      } else {
        var navIndex = nextProps.navigateMenu.findIndex(
          (p) => p.pro_cate_slug == selectedNavigation
        );
        var categoryNameTxt =
          Object.keys(nextProps.navigateMenu).length > 0
            ? nextProps.navigateMenu[navIndex].category_name
            : nextProps.selectedCatry;
      }

      this.setState({
        selectedNavigation: selectedNavigation,
        catNavIndex: navIndex,
        catslugType: slugType,
        catslugValue: slugValue,
        navigateMenu: navigateMenu,
        selectedCategoryName: categoryNameTxt,
        searchSubCat: searchSubCat,
        searchProVal: proValue,
        loadproduct: "Yes",
        load_product: "No",
      });
    }

    /* if (nextProps.alloutlets !== this.state.outletslist) {
      if (
        Object.keys(nextProps.alloutlets).length > 0 &&
        nextProps.alloutlets !== "" &&
        nextProps.alloutlets !== undefined &&
        nextProps.alloutlets !== null
      ) {
        var resultSetN = nextProps.alloutlets[0].result_set;
        var outletLocationId = this.state.outletLocationId;

        if (
          resultSetN !== "" &&
          resultSetN !== undefined &&
          resultSetN !== null
        ) {
          outletLocationId = resultSetN[0].outlet_location_id;
          var outlet_id = resultSetN[0].outlet_id;
          var orderOutletName = resultSetN[0].outlet_name;
          this.setState(
            {
              outletslist: resultSetN,
              tag: nextProps.alloutlets[0].tag,
              promotion: nextProps.alloutlets[0].promotion,
              outletLocationId: outletLocationId,
            },
            function () {
              if (cookie.load("orderOutletId") !== outlet_id) {
                cookie.save("orderOutletId", outlet_id, { path: "/" });
                cookie.save("orderOutletName", orderOutletName, { path: "/" });
              }

              this.displayOutletDetails();
            }
          );
        } else {
          this.setState({
            outletslist: [],
            tag: [],
            promotion: [],
            outletLocationId: "",
          });

          showAlert("Notice", "No outlets found");
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });

          this.props.history.push("/");
        }
      }
    } */

    if (nextProps.globalsettings !== this.state.globalsettings) {
      if (
        nextProps.globalsettings[0] !== "" &&
        typeof nextProps.globalsettings[0] !== undefined &&
        typeof nextProps.globalsettings[0] !== "undefined"
      ) {
        this.setState({ settings: nextProps.globalsettings[0].result_set });
      }
    }

    if (nextProps.siteLocationList !== this.state.siteLocationList) {
      if (Object.keys(nextProps.siteLocationList).length > 0) {
        if (nextProps.siteLocationList[0].status === "ok") {
          this.setState(
            {
              siteLocationList: nextProps.siteLocationList[0].result_set,
              locationImage: nextProps.siteLocationList[0].common,
            },
            function () {
              this.displaymallname();
            }
          );
        }
      }
    }
  }

  componentDidMount() {
    /*SCROLL FIXED PRODUCT PAGE*/
    $(window).scroll(function () {
      var productlist_height = $(".productlist-main-div").offset();
      if (productlist_height !== undefined) {
        var mycustomscroll = $(".productlist-main-div").offset().top - 122,
          wind_toptxt = $(window).scrollTop();

        if (wind_toptxt > mycustomscroll) {
          $(".bakery_row").addClass("catogry_row_fixed");
        } else {
          $(".bakery_row").removeClass("catogry_row_fixed");
        }
      }
    });
    var event_slug = "";
    var shop_type = "";
    var shop_type = "";
    var zip_code = "";
    this.loadOultetDetails();
    /* this.props.getAllOutlets(
      this.state.defaultAvilTy,
      this.state.outlet_slug,
      event_slug,
      shop_type,
      zip_code,
      this.state.siteLocation
    ); */
    this.getSearchProductList();
    /*  this.checkTimeAvilablity(); */

    if (
      cookie.load("openGroupShare") !== "" &&
      typeof cookie.load("openGroupShare") !== undefined &&
      typeof cookie.load("openGroupShare") !== "undefined"
    ) {
      this.openGropShare();
      cookie.remove("openGroupShare", { path: "/" });
    }

    $(document).ready(function () {
      $("body").on("click", ".read-more", function () {
        $(this).siblings(".more-text").contents().unwrap();
        $(this).remove();
        $(".show-read-more").text(
          $(".show-read-more").text().replaceAll("see more...", "")
        );
      });
    });
  }

  loadOultetDetails() {
    var availability =
      this.state.defaultAvilTy !== "" &&
      typeof this.state.defaultAvilTy !== undefined &&
      typeof this.state.defaultAvilTy !== "undefined"
        ? this.state.defaultAvilTy
        : deliveryId;
    axios
      .get(
        apiUrlV2 +
          "outlets/getAllOutles?app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&outlet_slug=" +
          this.state.outlet_slug +
          "&site_location_slug=" +
          this.state.siteLocation
      )
      .then((response) => {
        //console.log(response.data, "response.data");
        if (response.data.status === "ok") {
          var resultSetN = response.data.result_set;
          var outletLocationId = this.state.outletLocationId;
          if (
            resultSetN !== "" &&
            resultSetN !== undefined &&
            resultSetN !== null
          ) {
            outletLocationId = resultSetN[0].outlet_location_id;
            var outlet_id = resultSetN[0].outlet_id;
            var orderOutletName = resultSetN[0].outlet_name;
            this.setState(
              {
                outletslist: resultSetN,
                tag: response.data.tag,
                promotion: response.data.promotion,
                outletLocationId: outletLocationId,
                orderOutletId: outlet_id,
              },
              function () {
                if (cookie.load("orderOutletId") !== outlet_id) {
                  cookie.save("orderOutletId", outlet_id, { path: "/" });
                  cookie.save("orderOutletName", orderOutletName, {
                    path: "/",
                  });
                }
                this.checkTimeAvilablity();
                this.displayOutletDetails();
              }
            );
          }

          /* this.setState({ outletslist: response.data.result_set }, function () {
          var resultSetN = response.data.result_set;
          var outlet_id = resultSetN[0].outlet_id;
          var orderOutletName = resultSetN[0].outlet_name;
          var outletLocationId = resultSetN[0].outlet_location_id;

          this.setState(
            {
              outletslist: resultSetN,
              outlet_id: outlet_id,
              outletLocationId: outletLocationId,
            },
            function () {
              this.checkTimeAvilablity();
              if (cookie.load("orderOutletId") !== outlet_id) {
                cookie.save("orderOutletId", outlet_id, { path: "/" });
                cookie.save("orderOutletName", orderOutletName, { path: "/" });
              }
            }
          );
        }); */
        }
      });
  }

  loadGruopShare() {
    showLoader("sharegroup-body", "class");
    $("#sharegroup-popup").modal({ keyboard: false });
    var current = this;
    var outlet_slugs = "";
    if (this.props.match.params.slugRestaurant !== "restaurants") {
      outlet_slugs = "&outlet_slug=" + this.props.match.params.slugRestaurant;
    }
    axios
      .get(
        apiUrl +
          "cart/contents?app_id=" +
          appId +
          "&status=A&cart_id=" +
          base64.decode(this.props.match.params.orderID) +
          outlet_slugs
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (
            res.data.result_set !== "" &&
            typeof res.data.result_set !== undefined &&
            typeof res.data.result_set !== "undefined"
          ) {
            this.setState(
              { cartDetails: res.data.result_set.cart_details },
              function () {
                hideLoader("sharegroup-body", "class");
              }
            );
          } else {
            $("#sharegroup-popup").modal("hide");
            setTimeout(function () {
              current.loadAlert("Error", "Invalid Group");
            }, 2000);
          }
        } else {
          $("#sharegroup-popup").modal("hide");
          setTimeout(function () {
            current.loadAlert("Error", "Invalid Group");
          }, 2000);
        }
      });
  }

  displaymallname() {
    if (
      this.state.siteLocationList !== "" &&
      this.state.siteLocationList !== undefined &&
      this.state.siteLocationList !== null
    ) {
      var mallname = "";
      var malllist = this.state.siteLocationList.map((item, index) => {
        var mall_url = this.state.currentpage_group_url;

        var split_url = mall_url.split("/");

        if (item.sl_slug === split_url[1]) {
          mallname = item.sl_name;
        }
      });

      this.setState({
        mallname: mallname,
      });
    }
  }

  loadProductTagList() {
    var order_outlet_id = this.state.orderOutletId;
    axios
      .get(
        apiUrl +
          "products/product_tags?app_id=" +
          appId +
          "&tag_outlet_id=" +
          order_outlet_id
      )
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({ tagList: response.data.result_set }, function () {
            this.displayTag();
          });
        } else {
          this.setState({ tagList_error_message: "No Products Tags found." });
        }
      });
  }

  displayTag() {
    var tagdisplay = this.state.tagList.map((item, index) => {
      return (
        <li className="custom_checkbox" key={index}>
          <input
            type="checkbox"
            className="restaurants_tag"
            onChange={this.handleChange.bind(this)}
            value={item.pro_tag_id}
          />
          <span>{item.pro_tag_name}</span>
        </li>
      );
    });
    this.setState({ tagdisplay: tagdisplay });
  }

  handleChange(event) {
    let filterTag = this.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.setState({ applyFilter: "Y" });
    });
  }

  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState({ filterTag: [] }, function () {
      this.setState({ applyFilter: "Y" });
    });
  }

  checkTimeAvilablity() {
    if (this.state.orderOutletId !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              this.state.orderOutletId +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime
          )
          .then((response) => {
            var res_min_date = moment(response.data.min_date).format(
              "D MMM YY"
            );
            if (response.data.status === "success") {
              this.setState({
                shopeClosed: 0,
                res_min_date: res_min_date,
              });
            } else {
              this.setState({
                shopeClosed: 1,
                res_min_date: res_min_date,
              });
            }
          });
      }
    }
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "cartclearflg") {
      this.setState({ cartClearTriggerFlg: value });
    }
    if (field === "view_pro_data" && value !== "") {
      this.setState(
        { viewProductSlug: value },
        function () {
          this.openProDetailPopup();
        }.bind(this)
      );
    }
    if (field === "cartOutletList" && value !== "") {
      this.setState({ cartOutletList: value });
    }
    if (field === "loadproduct" && value !== "") {
      this.setState({ loadproduct: "No" });
    }
    if (field === "applyFilter" && value !== "") {
      this.setState({ applyFilter: "N" });
    }

    if (field === "totalCartCount" && value !== "") {
      this.setState({ totalCartCount: value }, function () {
        this.displayOutletDetails();
      });
    }

    if (field === "cartDetails" && value !== "") {
      if (
        value.cart_id !== "" &&
        typeof value.cart_id !== undefined &&
        typeof value.cart_id !== "undefined"
      ) {
        var cart_id = base64.encode(value.cart_id);
        var orderOutletId = base64.encode(cookie.load("orderOutletId"));
        var deliveryDate =
          cookie.load("deliveryDate") !== "" &&
          typeof cookie.load("deliveryDate") !== "undefined" &&
          typeof cookie.load("deliveryDate") !== undefined
            ? base64.encode(cookie.load("deliveryDate").split("/").join("-"))
            : "";
        var orderDateTime =
          cookie.load("orderDateTime") !== "" &&
          typeof cookie.load("orderDateTime") !== "undefined" &&
          typeof cookie.load("orderDateTime") !== undefined
            ? base64.encode(cookie.load("orderDateTime").split('"').join(""))
            : "";
        var deliveryTime =
          cookie.load("deliveryTime") !== "" &&
          typeof cookie.load("deliveryTime") !== "undefined" &&
          typeof cookie.load("deliveryTime") !== undefined
            ? base64.encode(cookie.load("deliveryTime"))
            : "";

        var orderdeliverycode =
          cookie.load("deliveryPostalCode") !== "" &&
          typeof cookie.load("deliveryPostalCode") !== "undefined" &&
          typeof cookie.load("deliveryPostalCode") !== undefined
            ? base64.encode(cookie.load("deliveryPostalCode"))
            : "";

        var orderType =
          cookie.load("defaultAvilablityId") !== "" &&
          typeof cookie.load("defaultAvilablityId") !== "undefined" &&
          typeof cookie.load("defaultAvilablityId") !== undefined
            ? cookie.load("defaultAvilablityId")
            : "";

        if (orderType === deliveryId) {
          orderType = "delivery";
          orderType = base64.encode(orderType);
        } else {
          orderType = "takeaway";
          orderType = base64.encode(orderType);
        }

        var locationId =
          cookie.load("outletLocationId") !== "" &&
          typeof cookie.load("outletLocationId") !== "undefined" &&
          typeof cookie.load("outletLocationId") !== undefined
            ? base64.encode(cookie.load("outletLocationId"))
            : "";

        this.setState({
          cartDetails: value,
          shareGroupUrl:
            siteURL +
            "grouporder/" +
            this.state.currentpage_group_url +
            "/" +
            this.state.outlet_slug +
            "/" +
            cart_id +
            "/" +
            orderOutletId +
            "/" +
            deliveryDate +
            "/" +
            orderDateTime +
            "/" +
            deliveryTime +
            "/" +
            orderdeliverycode +
            "/" +
            orderType +
            "/" +
            locationId,
        });
      }
    }
    if (field === "cartGroups" && value !== "") {
      this.setState({ cartGroups: value });
    }
  };

  productFlageChange = (field, value) => {
    this.setState(
      update(this.state, { productflage: { [field]: { $set: value } } })
    );
  };

  openProDetailPopup() {
    showLoader("comboPro-" + this.state.viewProductSlug, "Idtext");
    $("#ProductDetailMdl").modal({ keyboard: false });
  }

  openGropShare(event) {
    if (event !== "" && event !== undefined && event !== "undefined") {
      event.preventDefault();
    }
    if (
      this.state.defaultAvilTy === "" ||
      typeof this.state.defaultAvilTy === undefined ||
      typeof this.state.defaultAvilTy === "undefined"
    ) {
      $(".required_availability").show();
      $("html, body").animate(
        {
          scrollTop: $(".required_availability").offset().top - 140,
        },
        1000
      );
      setTimeout(function () {
        $(".required_availability").hide();
      }, 3000);
      return false;
    }

    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      if (
        this.state.cartDetails.totalItems > 0 ||
        this.state.cartDetails.totalItems !== undefined
      ) {
        if (
          this.state.shareGroupUrl === "" &&
          this.state.shareGroupUrl !== undefined
        ) {
          var outletLocationId = this.state.outletLocationId;
          var availabilityId = cookie.load("defaultAvilablityId");
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          var shopType =
            cookie.load("shopType") !== "" &&
            typeof cookie.load("shopType") !== undefined &&
            typeof cookie.load("shopType") !== "undefined"
              ? cookie.load("shopType")
              : "";
          var qs = require("qs");
          var postObject = {
            app_id: appId,
            outletLocationId: outletLocationId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            customer_id: customerId,
            shopType: shopType,
          };
          axios
            .post(
              apiUrl + "cart/insert_cart_without_product",
              qs.stringify(postObject)
            )
            .then((response) => {
              hideLoader("cancel_order", "class");
              if (response.data.status === "ok") {
                var cart_id = base64.encode(response.data.contents);
                var orderOutletId = base64.encode(cookie.load("orderOutletId"));

                var deliveryDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== "undefined" &&
                  typeof cookie.load("deliveryDate") !== undefined
                    ? base64.encode(
                        cookie.load("deliveryDate").split("/").join("-")
                      )
                    : "";
                var orderDateTime =
                  cookie.load("orderDateTime") !== "" &&
                  typeof cookie.load("orderDateTime") !== "undefined" &&
                  typeof cookie.load("orderDateTime") !== undefined
                    ? base64.encode(
                        cookie.load("orderDateTime").split('"').join("")
                      )
                    : "";
                var deliveryTime =
                  cookie.load("deliveryTime") !== "" &&
                  typeof cookie.load("deliveryTime") !== "undefined" &&
                  typeof cookie.load("deliveryTime") !== undefined
                    ? base64.encode(cookie.load("deliveryTime"))
                    : "";

                var orderType =
                  cookie.load("defaultAvilablityId") !== "" &&
                  typeof cookie.load("defaultAvilablityId") !== "undefined" &&
                  typeof cookie.load("defaultAvilablityId") !== undefined
                    ? cookie.load("defaultAvilablityId")
                    : "";

                if (orderType === deliveryId) {
                  orderType = "delivery";
                  orderType = base64.encode(orderType);
                } else {
                  orderType = "takeaway";
                  orderType = base64.encode(orderType);
                }

                var locationId =
                  cookie.load("outletLocationId") !== "" &&
                  typeof cookie.load("outletLocationId") !== "undefined" &&
                  typeof cookie.load("outletLocationId") !== undefined
                    ? base64.encode(cookie.load("outletLocationId"))
                    : "";

                this.setState(
                  {
                    shareGroupUrl:
                      siteURL +
                      "grouporder/" +
                      this.state.currentpage_group_url +
                      "/" +
                      this.state.outlet_slug +
                      "/" +
                      cart_id +
                      "/" +
                      orderOutletId +
                      "/" +
                      deliveryDate +
                      "/" +
                      orderDateTime +
                      "/" +
                      deliveryTime +
                      "/" +
                      orderdeliverycode +
                      "/" +
                      orderType +
                      "/" +
                      locationId,
                  },
                  function () {
                    $("#sharegroup-popup").modal({
                      keyboard: false,
                    });
                  }
                );
              } else {
              }
              return false;
            });
        } else {
          if (this.state.cartDetails.totalItems > 0) {
            $("#sharegroup-popup").modal({ keyboard: false });
          }
        }
      } else {
        showAlert("Notice", "Please add items to start group order.");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    } else {
      cookie.save("openGroupShare", "Yes", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  copyGropurl(event) {
    event.preventDefault();
    var copyText = document.getElementById("shareGroupUrl");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    $("#coppy-success-msg").fadeIn();
    setTimeout(function () {
      $("#coppy-success-msg").fadeOut();
    }, 3000);
  }

  cancelGrouporder(event) {
    event.preventDefault();
    showLoader("cancel_order", "class");
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
      cart_id: cookie.load("cart_id"),
    };
    axios
      .post(apiUrl + "cart/cancel_group", qs.stringify(postObject))
      .then((response) => {
        hideLoader("cancel_order", "class");
        if (response.data.status === "ok") {
          this.setState({ cartTriggerFlg: "yes" });
          cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("cart_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          $("#pending_time_left").remove();
          this.handleShowAlertFun("success", response.data.message);
          setTimeout(function () {
            location.href = siteURL;
          }, 1000);
        } else {
          this.handleShowAlertFun("Error", response.data.message);
        }
        return false;
      });
  }

  cancelorder() {
    event.preventDefault();
    this.setState({ cartClearTriggerFlg: "yes" });
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  displayOutletDetails() {
    if (
      this.state.outletslist.length === 1 &&
      this.state.outletslist[0] !== "" &&
      typeof this.state.outletslist[0] !== undefined &&
      typeof this.state.outletslist[0] !== "undefined"
    ) {
      const restaurantSettings = {
        dots: false,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        cssEase: "linear",
      };

      let outlet = this.state.outletslist[0];
      cookie.save("home_brand_id", outlet.brand_id);
      var restaurantbackgroundImg = "";

      if (outlet.outlet_banner_image !== "") {
        restaurantbackgroundImg =
          mediaUrl + "outlet/" + outlet.outlet_banner_image;
      } else if (outlet.outlet_extra_image !== "") {
        restaurantbackgroundImg =
          mediaUrl + "outlet/" + outlet.outlet_extra_image;
      } else {
        restaurantbackgroundImg = "";
      }

      this.setState({
        restaurantbackgroundImg: restaurantbackgroundImg,
      });

      var currentPageUrl = this.props.location.pathname.split("/");
      var current_Page_Url = "";
      var current_Page_Url2 =
        currentPageUrl[2] !== "" &&
        typeof currentPageUrl[2] !== undefined &&
        typeof currentPageUrl[2] !== "undefined"
          ? currentPageUrl[2]
          : "";

      let restaurantdetails = (
        <>
          <div className="restaurant-info-back">
            {this.state.mallname !== "" &&
              this.state.mallname !== "undefined" &&
              this.state.mallname !== undefined && (
                <div className="container">
                  <Link to={"/mall/" + current_Page_Url2} className="button">
                    Back to {this.state.mallname}
                  </Link>
                </div>
              )}
          </div>
          <div className="restaurant-info">
            <div className="restaurant-info-lhs">
              <div className="restaurant-inner-inro-lhs">
                <div className="innr-info-lhs">
                  <h3>{outlet.outlet_name}</h3>
                  {outlet.outlet_tag_id !== "" && (
                    <div className="restaurant-tag">
                      {this.loadTag(outlet.outlet_tag_id)}
                    </div>
                  )}
                  <div className="social-media">
                    {outlet.outlet_website && (
                      <span>
                        <a
                          href={outlet.outlet_website}
                          target="_blank"
                          title={outlet.outlet_website}
                        >
                          {outlet.outlet_website}
                        </a>
                      </span>
                    )}

                    {outlet.outlet_fb !== "" && (
                      <a href={outlet.outlet_fb} target="_blank">
                        <img src={facebook} alt="" />
                      </a>
                    )}

                    {/*outlet.outlet_twitter !=='' && (
                      <a href={outlet.outlet_twitter} target="_blank">
                        <i className="fa fa-twitter"></i>
                      </a>
                    )*/}

                    {outlet.outlet_instagram !== "" && (
                      <a href={outlet.outlet_instagram} target="_blank">
                        <img src={instagram} alt="" />
                      </a>
                    )}
                  </div>
                </div>

                <div className="innr-info-rhs">
                  <img
                    src={
                      outlet.outlet_image !== ""
                        ? mediaUrl + "outlet/" + outlet.outlet_image
                        : noimage
                    }
                    alt={outlet.outlet_name}
                    title={outlet.outlet_name}
                    className="restaurant-logo"
                  />
                </div>
                <div className="restaurant-min-rhs">
                  {current_Page_Url2 !== "up-catering" ? (
                    <p>
                      {outlet.outlet_delivery_timing}
                      <br />
                      Min
                    </p>
                  ) : (
                    <p>
                      3
                      <br />
                      Days
                    </p>
                  )}
                </div>
              </div>

              <div className="restaurant-info-rhs mobile_slider_brands">
                <Slider {...restaurantSettings}>
                  <div className="up-restaurant-item">
                    <img
                      src={
                        outlet.outlet_image !== ""
                          ? mediaUrl + "outlet/" + outlet.outlet_image
                          : noimage
                      }
                      alt={outlet.outlet_name}
                      title={outlet.outlet_name}
                    />
                  </div>
                  <div className="up-restaurant-item">
                    <img
                      src={
                        outlet.outlet_image !== ""
                          ? mediaUrl + "outlet/" + outlet.outlet_extra_image
                          : noimage
                      }
                      alt={outlet.outlet_name}
                      title={outlet.outlet_name}
                    />
                  </div>
                  <div className="up-restaurant-item">
                    <img
                      src={
                        outlet.outlet_image !== ""
                          ? mediaUrl + "outlet/" + outlet.outlet_image
                          : noimage
                      }
                      alt={outlet.outlet_name}
                      title={outlet.outlet_name}
                    />
                  </div>
                </Slider>
              </div>

              {outlet.outlet_open_time !== "" &&
                outlet.outlet_open_time !== null && (
                  <div className="working-hr">
                    <strong>Opening Hours :</strong>
                    <div className="outlet-timing">
                      {Parser(outlet.outlet_open_time)}
                    </div>
                  </div>
                )}
              {outlet.outlet_informations !== "" &&
                outlet.outlet_informations !== null && (
                  <div className="show-read-more">
                    {this.loaddescription(outlet)}
                  </div>
                )}
              {/* <div className="restaurant-other-info">
                <div className="restaurant-tat">
                  <span>
                    Around{" "}
                    <strong>
                      {this.state.defaultAvilTy === deliveryId
                        ? outlet.outlet_delivery_tat
                        : ""}
                      {this.state.defaultAvilTy === pickupId
                        ? outlet.outlet_pickup_tat
                        : ""}{" "}
                      MIN
                    </strong>
                  </span>
                </div>
                {this.state.res_min_date !== "" && (
                  <div className="restaurant-date">
                    Earliest Order
                    <strong>{this.state.res_min_date}</strong>
                  </div>
                )}
              </div> */}
              {this.state.promotion[outlet.oa_outlet_id] !== "" &&
                typeof this.state.promotion[outlet.oa_outlet_id] !==
                  undefined &&
                typeof this.state.promotion[outlet.oa_outlet_id] !==
                  "undefined" && (
                  <div className="restaurant-promo">
                    <strong>Promotions</strong>
                    {this.loadPromo(outlet.oa_outlet_id)}
                  </div>
                )}
            </div>

            <div className="restaurant-info-rhs desktop_slider_brands">
              <Slider {...restaurantSettings}>
                <div className="up-restaurant-item">
                  <img
                    src={
                      outlet.outlet_image !== ""
                        ? mediaUrl + "outlet/" + outlet.outlet_image
                        : noimage
                    }
                    alt={outlet.outlet_name}
                    title={outlet.outlet_name}
                  />
                </div>
                <div className="up-restaurant-item">
                  <img
                    src={
                      outlet.outlet_image !== ""
                        ? mediaUrl + "outlet/" + outlet.outlet_image
                        : noimage
                    }
                    alt={outlet.outlet_name}
                    title={outlet.outlet_name}
                  />
                </div>
                <div className="up-restaurant-item">
                  <img
                    src={
                      outlet.outlet_image !== ""
                        ? mediaUrl + "outlet/" + outlet.outlet_image
                        : noimage
                    }
                    alt={outlet.outlet_name}
                    title={outlet.outlet_name}
                  />
                </div>
              </Slider>
            </div>
          </div>
        </>
      );
      this.setState({ restaurantdetails: restaurantdetails }, function () {
        var maxLength = 500;
        $(".show-read-more").each(function () {
          var myStr = $(this).text();
          if ($.trim(myStr).length > maxLength) {
            var newStr = myStr.substring(0, maxLength);
            var removedStr = myStr.substring(maxLength, $.trim(myStr).length);
            $(this).empty().html(newStr);
            removedStr.replaceAll("see more...", "");

            $(this).append(
              ' <a href="javascript:void(0);" class="read-more">see more...</a>'
            );
            removedStr.replaceAll("see more...", "");
            $(this).append('<span class="more-text">' + removedStr + "</span>");
          }
        });
      });
    }
  }
  loaddescription(outlet) {
    var outletdescription = "<div>" + outlet.outlet_informations + "</div>";
    return Parser(outletdescription);
  }

  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}{" "}
              </span>
            );
          }
        });
      }
    }
  }

  loadPromo(outlet_id) {
    let promotion = this.state.promotion;
    if (promotion !== "") {
      if (
        promotion[outlet_id] !== "" &&
        typeof promotion[outlet_id] !== undefined &&
        typeof promotion[outlet_id] !== "undefined"
      ) {
        return promotion[outlet_id].map(function (item, index) {
          return <span key={index}>{item}</span>;
        });
      }
    }
  }
  changeAvailability(availability, event) {
    event.preventDefault();
    if (availability === deliveryId && this.state.deliveryPostalCode === "") {
      this.setState({ selectPostalCode: "Yes" });
      $.magnificPopup.open({
        items: {
          src: "#delevery-postcode-popup",
        },
        type: "inline",
      });
      return false;
    }

    var orderDateTime = cookie.load("orderDateTime");
    if (availability === pickupId) {
      if (
        orderDateTime === "" ||
        typeof orderDateTime === undefined ||
        typeof orderDateTime === "undefined"
      ) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.setState({ selectPickupTime: "Yes" });
        return false;
      }
    }

    if (this.state.defaultAvilTy !== availability) {
      axios
        .get(
          apiUrlV2 +
            "outlets/checkOutletAvailability?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&outlet_slug=" +
            this.state.outlet_slug
        )
        .then((response) => {
          if (response.data.status === "ok") {
            cookie.save("defaultAvilablityId", availability, { path: "/" });
            this.setState(
              { defaultAvilTy: availability, load_product: "No" },
              function () {
                axios
                  .get(
                    apiUrl +
                      "cart/update_availability?app_id=" +
                      appId +
                      "&reference_id=" +
                      getReferenceID() +
                      "&customer_id=" +
                      cookie.load("UserId") +
                      "&availability_id=" +
                      availability
                  )
                  .then((res) => {
                    location.reload();
                    this.setState({ cartTriggerFlg: "yes" }, function () {
                      this.setState({ cartTriggerFlg: "yes" });
                    });
                  });

                this.props.getMenuNavigationList(
                  this.props.match.params.slugRestaurant,
                  availability,
                  this.state.currentPageUrl,
                  this.state.slugEvent
                );
              }
            );
          } else {
            $.magnificPopup.open({
              items: {
                src: "#warning-not-deliver",
              },
              type: "inline",
            });
            this.props.history.push("/" + this.state.currentPageUrl);
          }
        });
    }
  }

  getSearchProductList() {
    var searchResult = [];
    return axios
      .get(
        apiUrlV2 +
          "products/search_products?app_id=" +
          appId +
          "&status=A&availability=" +
          this.state.defaultAvilTy +
          "&outletSlug=" +
          this.state.outlet_slug
      )
      .then((response) => {
        if (response.data.status === "ok") {
          var resultData = response.data.result_set;
          for (var key in resultData) {
            var subcatListArr = !("product_list" in resultData[key])
              ? Array()
              : resultData[key].product_list;

            if (Object.keys(subcatListArr).length > 0) {
              if (Object.keys(subcatListArr[0]).length > 0) {
                var subCatArr = !("subcategorie" in subcatListArr[0][0])
                  ? Array()
                  : subcatListArr[0][0].subcategorie;
                for (var sctkey in subCatArr) {
                  var productsArr = !("products" in subCatArr[sctkey])
                    ? Array()
                    : subCatArr[sctkey].products;
                  for (var prokey in productsArr) {
                    var proNameTxt = getAliasName(
                      productsArr[prokey].product_alias,
                      productsArr[prokey].product_name
                    );
                    searchResult.push({
                      cate_slug: subCatArr[sctkey].pro_cate_slug,
                      subcate_slug: subCatArr[sctkey].pro_subcate_slug,
                      value: productsArr[prokey].product_slug,
                      label: stripslashes(proNameTxt),
                    });
                  }
                }
              }
            }
          }
        }
        this.setState({ searchProResult: searchResult });
      });
  }

  searchProKeyPress = (event) => {
    $(".search_result").show();
    var value = event.target.value.toLowerCase();
    let matches = "";
    if (value !== "") {
      matches = this.state.searchProResult.filter(function (item) {
        if (item.label.toLowerCase().indexOf(value) >= 0) {
          return item.label;
        }
      });
    }
    this.setState({ selectedProResult: matches, searchKeyWord: value });
  };
  loadProSearchList() {
    if (Object.keys(this.state.selectedProResult).length > 0) {
      return this.state.selectedProResult.map((loaddata, index) => (
        <li key={index}>
          <Link
            onClick={this.openProductLink.bind(
              this,
              "/" +
                this.state.currentPageUrl +
                "/" +
                this.state.siteLocation +
                "/" +
                this.state.outlet_slug +
                "/" +
                loaddata.cate_slug +
                "/" +
                loaddata.subcate_slug +
                "/" +
                loaddata.value
            )}
            title={loaddata.label}
          >
            {loaddata.label}
          </Link>
        </li>
      ));
    } else {
      if (this.state.searchKeyWord !== "") {
        return <li className="no-pro-found">No Product found</li>;
      } else {
        return "";
      }
    }
  }

  openProductLink(plink, event) {
    event.preventDefault();
    $("#searchKeyWord").val("");
    const { history } = this.props;
    this.setState({ selectedProResult: [], searchKeyWord: "" }, function () {
      history.push(plink);
    });
  }

  render() {
    var productSlider = {
      dots: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      variableWidth: true,
      swipeToSlide: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      prevArrow: (
        <div>
          <AiFillCaretLeft />
        </div>
      ),
      nextArrow: (
        <div>
          <AiFillCaretRight />
        </div>
      ),
      responsive: [
        {
          breakpoint: 1100,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 767,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            variableWidth: false,
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };

    let completGroupOrder = "";
    if (this.state.cart_group_id !== "") {
      if (
        this.state.cartGroups[this.state.cart_group_id] !== "" &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== undefined &&
        typeof this.state.cartGroups[this.state.cart_group_id] !== "undefined"
      ) {
        if (
          this.state.cartGroups[this.state.cart_group_id].cart_group_status ===
          "C"
        ) {
          completGroupOrder = "Y";
        }
      }
    }

    var totalGroup = 0;
    if (Object.keys(this.state.cartGroups).length) {
      for (var groupO in this.state.cartGroups) {
        totalGroup++;
      }
    }
    return (
      <div className="productpage-main-div">
        {/* Header start */}
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          cartClearTriggerFlg={this.state.cartClearTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
          selectPostalCode={this.state.selectPostalCode}
          selectPickupTime={this.state.selectPickupTime}
        />
        {/* Header End */}
        {this.state.cartDetails !== "" &&
          Object.keys(this.state.cartGroups).length > 0 && (
            <div className="group-order-black">
              <div className="container">
                <div className="group-order-parent">
                  <div className="group-order-blhs">
                    <div className="group-order-blhs-top">
                      <img src={groupwhite} alt="" />
                      {this.state.cart_group_id !== "" ? (
                        <>
                          <h5>
                            {this.state.cartDetails.customer_first_name}
                            {this.state.cartDetails.customer_last_name !== ""
                              ? " " + this.state.cartDetails.customer_last_name
                              : ""}
                            ’s Group Order
                          </h5>
                          <p>
                            <span>
                              You added {this.state.cartDetails.totalItems} item
                            </span>
                            <span>
                              Total : ${" "}
                              {this.state.cartDetails.cart_grand_total.toFixed(
                                2
                              )}{" "}
                            </span>{" "}
                          </p>
                        </>
                      ) : (
                        <>
                          <h5>
                            {cookie.load("UserFname")}
                            ’s Group Order
                          </h5>
                          <p>
                            <span>
                              {totalGroup} Guest{totalGroup > 1 ? "s" : ""}
                            </span>
                            <span>
                              Total : $
                              {this.state.cartDetails.cart_grand_total.toFixed(
                                2
                              )}{" "}
                            </span>{" "}
                          </p>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="group-order-brhs">
                    {this.state.cart_group_id === "" && (
                      <a
                        href="#"
                        className="button gob-white cancel_order"
                        onClick={this.cancelorder.bind(this)}
                      >
                        cancel order
                      </a>
                    )}

                    {this.state.cart_group_id !== "" &&
                      completGroupOrder === "" && (
                        <a
                          href="#"
                          className="button gob-white cancel_order"
                          onClick={this.cancelGrouporder.bind(this)}
                        >
                          Leave order
                        </a>
                      )}

                    {this.state.cart_group_id === "" && (
                      <a
                        className="button gob-red open-popup-link"
                        data-effect="mfp-zoom-in"
                        onClick={this.openGropShare.bind(this)}
                      >
                        share link
                      </a>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

        <section
          className="restaurant-details"
          style={{
            backgroundImage: "url(" + this.state.restaurantbackgroundImg + ")",
            backgroundRepeat: "no-repeat",
          }}
        ></section>

        <div className="container restaurant-main">
          {this.state.restaurantdetails}
        </div>

        <div className="search-and-info mall-info product_group_order">
          <div className="container">
            {this.state.shopeClosed !== 0 && (
              <div className="outlet-closed-error-msg">
                Outlet is close. Please choose a different date and time
              </div>
            )}
            <div className="search-info-inner">
              <div className="search-filter mall-search">
                <div className="search-filter-lhs">
                  <input
                    type="text"
                    className="search-rest"
                    placeholder="Search restaurants, dishes & cuisines"
                    id="searchKeyWord"
                    onKeyUp={this.searchProKeyPress}
                  />
                  <img src={searchorg} alt="" />

                  <ul
                    className="search_result"
                    id="search_result"
                    style={{ display: "none" }}
                  >
                    {this.loadProSearchList()}
                  </ul>
                </div>

                <div className="search-filter-rhs restaurant_filter">
                  Filters
                  <img
                    src={closebl}
                    alt=""
                    className="close_filter_section filter-clear-image"
                    style={{ display: "none" }}
                  />
                  <img
                    className="close_filter_section filter-img"
                    src={filter}
                    alt=""
                  />
                </div>

                <div className="filter-details" style={{ display: "none" }}>
                  {this.state.filterTag.length > 0 && (
                    <div className="clear-filter">
                      <Link to="#" onClick={this.clearFilter.bind(this)}>
                        Clear
                      </Link>
                    </div>
                  )}
                  <div className="item-filter common-filter">
                    <h3 className="filter-tag">
                      {this.state.tagList_error_message == ""
                        ? "Tag"
                        : this.state.tagList_error_message}
                    </h3>
                    {this.state.tagList_error_message === "" && (
                      <ul>{this.state.tagdisplay}</ul>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="mall-search-all">
              {(cookie.load("group_order_confirm") === "" ||
                typeof cookie.load("group_order_confirm") === undefined ||
                typeof cookie.load("group_order_confirm") === "undefined") && (
                <div className="group-link">
                  <a
                    href="#"
                    data-effect="mfp-zoom-in"
                    className="open-popup-link"
                    title="Start Group Order"
                    onClick={this.openGropShare.bind(this)}
                  >
                    {" "}
                    <img className="no_mou" src={groupdark} alt="" />
                    <img className="mou_active" src={groupwhite} alt="" />
                    Start Group Order
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>

        {this.props.navigateMenu.length > 0 ? (
          <MenuNavigation {...this.props} productState={this.state} />
        ) : (
          <div className="no-product">
            <div className="container">
              <div id="dvLoading" className="dvLoadrCls"></div>
              <h2>No product found</h2>
              <p>
                Sorry, we can't find the type of products you are looking for.
                Try widening your search to see what’s available.
              </p>
            </div>
          </div>
        )}

        {this.props.navigateMenu.length > 0 && (
          <section className="product-menu-listing">
            <div className="container">
              <ProductList
                {...this.props}
                productState={this.state}
                sateValChange={this.sateValChange}
                cartOutletList={this.state.cartOutletList}
              />
            </div>
          </section>
        )}

        {/* Footer section */}
        <Footer />
        <ProductDetail
          productState={this.state}
          sateValChange={this.sateValChange}
          cartOutletList={this.state.cartOutletList}
        />

        <div
          id="sharegroup-popup"
          className="modal fade commom-modal-topcls sharegroup-popup"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="common-modal-head">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    X
                  </button>
                </div>
                <div className="sharegroup-body textcenter">
                  <div className="sharegroup-body-info">
                    <img src={groupred} alt="" />
                    <h4>Start Your Group Order</h4>
                    <p>
                      Easily order and customise meals for large groups! Share
                      the link with your guests and they can add their dishes.
                      Once they’ve finished adding, you can checkout.{" "}
                    </p>
                  </div>
                  <div className="sharegroup-form-info">
                    <span>Share this link</span>

                    <input
                      type="text"
                      className="copy-share-text"
                      id="shareGroupUrl"
                      placeholder={siteURL + "/grouporder/12345678"}
                      value={this.state.shareGroupUrl}
                    />

                    <div className="sharegroup-form-single-btn">
                      <a
                        className="button"
                        onClick={this.copyGropurl.bind(this)}
                      >
                        COPY & Share
                      </a>
                    </div>
                    <div
                      className="custom_alertcls alert-success alert-dismissible1 success_hide"
                      id="coppy-success-msg"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <p className="jquery-success-msg">
                        Join my group order! {this.state.shareGroupUrl}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  var selectedCatry = "";

  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        if (tempArr[0].result_set[0].menu_type == "main") {
          selectedCatry = tempArr[0].result_set[0].category_name;
          menu_slug = tempArr[0].result_set[0].pro_cate_slug;
          menu_type = "category";
        } else {
          selectedCatry = tempArr[0].result_set[0].subcategory_name;
          menu_slug = tempArr[0].result_set[0].pro_subcate_slug;
          menu_type = "subcategory";
        }
      }
    }
  }
  return {
    globalsettings: state.settings,
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedCatry: selectedCatry,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    alloutlets: state.alloutlets,
    outletslist: state.alloutlets,
    siteLocationList: state.sitelocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (
      availability,
      outlet_slug,
      event_slug,
      shoptype,
      zip_code,
      site_location_id
    ) => {
      dispatch({
        type: GET_ALL_OUTLETS,
        availability,
        outlet_slug,
        event_slug,
        shoptype,
        zip_code,
        site_location_id,
      });
    },
    getMenuNavigationList: (
      outlet_slug,
      availability,
      currentPageUrl,
      slugEvent
    ) => {
      dispatch({
        type: GET_MENU_NAVIGATION,
        outlet_slug,
        availability,
        currentPageUrl,
        slugEvent,
      });
    },
    getloadSiteLocation: () => {
      dispatch({ type: GET_SITE_LOCATION });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Index);

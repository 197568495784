/* Live */
/* export const appId = "59145A47-4517-41B4-9D92-F6BA15DB571A";
export const apiUrl = "https://neptune.promobuddy.asia/api/";
export const apiUrlV2 = "https://neptune.promobuddy.asia/apiv2/";
export const apiUrlPro = "https://neptune.promobuddy.asia/ninjapro/";
export const apiUrlCtrng = "https://neptune.promobuddy.asia/cateringv2/";
export const apiUrlNotify = "https://neptune.promobuddy.asia/Pushorder/";
export const timThumpUrl = "https://neptune.promobuddy.asia/timthumb.php?src=";
export const blogImageUrl =
  "https://neptune.promobuddy.asia/media/dev_team/blog/";
export const proFolderUrl =
  "https://neptune.promobuddy.asia/media/dev_team/products/";
export const mediaUrl = "https://neptune.promobuddy.asia/media/dev_team/";
export const brandUrl = "https://neptune.promobuddy.asia/media/custom_images/";
export const siteURL = "http://test.upshop.me/";
export const baseUrl = "http://test.upshop.me/"; */

// export const baseUrl = "https://upshop.promobuddy.asia/";
// export const siteURL = "https://upshop.promobuddy.asia/";

/* export const siteURL = "http://localhost:3000/";
export const baseUrl = "http://localhost:3000/"; */

export const baseUrl = "https://www.upshop.me/";
export const siteURL = "https://www.upshop.me/";

export const appId = "59145A47-4517-41B4-9D92-F6BA15DB571A";
export const apiUrl = "https://neptune.ninjaos.com/api/";
export const apiUrlV2 = "https://neptune.ninjaos.com/apiv2/";
export const apiUrlPro = "https://neptune.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://neptune.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://neptune.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://neptune.ninjaos.com/timthumb.php?src=";
export const blogImageUrl = "https://neptune.ninjaos.com/media/dev_team/blog/";
export const proFolderUrl =
  "https://neptune.ninjaos.com/media/dev_team/products/";
export const mediaUrl = "https://neptune.ninjaos.com/media/dev_team/";
export const brandUrl = "https://neptune.ninjaos.com/media/custom_images/";

export const stripeImage = "/img/stripe_logo.png";
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";

export const CountryTxt = "Singapore";
export const productNoImg = "/img/product-noimg.jpg";
export const stripeCompany = "UP";

export const fbAppId = "2217773351628980";
export const stripeKey = "pk_test_hLfht4KEShHlACDG9mBfrPkv";
export const stripeEmail = "dev@jankosoft.com";
export const stripeReference = "up";
export const stripeDesc = "My Checkout";
export const stripeCurrency = "SGD";

/* eslint-disable */
import React, { Component } from "react";
import Axios from "axios";
import cookie from "react-cookies";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Slider from "react-slick";
import moment from "moment";
import OwlCarousel from "react-owl-carousel2";
import "../../common/css/owl.carousel.css";
import {
  appId,
  apiUrl,
  apiUrlV2,
  deliveryId,
  proFolderUrl,
} from "../Helpers/Config";
import {
  getReferenceID,
  stripslashes,
  showLoader,
  hideLoader,
  showCustomAlert,
  showAlert,
  removePromoCkValue,
  addressFormat,
} from "../Helpers/SettingHelper";
import noimage from "../../common/images/no-img-product.png";

import { GET_PRODUCT_DETAIL, GET_ALL_OUTLETS } from "../../actions";
import ProductSlider1 from "../../common/images/product-slider-1.png";
import smartPhone from "../../common/images/smart-phone.png";
import dealsplace from "../../common/images/deals-place.jpg";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
var Parser = require("html-react-parser");
var qs = require("qs");

var productSlider = {
  dots: false,
  arrows: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  prevArrow: (
    <div>
      <AiFillCaretLeft />
    </div>
  ),
  nextArrow: (
    <div>
      <AiFillCaretRight />
    </div>
  ),
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var productGallery_slider = {
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  infinite: true,
  dots: true,
  autoplay: true,
};

const settings_simple = {
  dots: false,
  arrows: true,
  slidesToShow: 2,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  prevArrow: (
    <div>
      <AiFillCaretLeft />
    </div>
  ),
  nextArrow: (
    <div>
      <AiFillCaretRight />
    </div>
  ),
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

var settingsProduct = {
  items: 3,
  autoplay: false,
  margin: 18,
  loop: false,
  nav: false,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    780: {
      items: 2,
    },
    1280: {
      items: 3,
    },
  },
};

class ProductDetailPage extends Component {
  constructor(props) {
    super(props);
    var shopType =
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
        ? cookie.load("shopType")
        : "";
    var orderZoneId =
      cookie.load("orderZoneId") !== "" &&
      typeof cookie.load("orderZoneId") !== undefined &&
      typeof cookie.load("orderZoneId") !== "undefined"
        ? cookie.load("orderZoneId")
        : "";

    var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;

    var slugValue = this.props.match.params.proValue;
    this.state = {
      selectedProSlug: slugValue,
      defaultAvilTy: defaultAvilTy,
      settings: [],
      navigateMenu: [],
      navigateMenuTmp: [],
      selectedCategoryName: "",
      shopType: shopType,
      orderZoneId: orderZoneId,
      selectedSlugType: "",
      selectedSlugValue: "",
      catNavIndex: 0,
      productdetailstatus: "",
      productdetail: [],
      productcommon: [],
      selectedProId: "",
      remaining: 30,
      product_remarks: "",
      mdfinput_value: 1,
      mdfApi_call: "Yes",
      modParentProductId: "",
      modProductPrice: "",
      modProductTotalPrice: "",
      compoinput_value: 1,
      singleinput_value: 1,
      incrCompoPrice: 0,
      compoApi_call: "Yes",
      cartTriggerFlg: "No",
      outlet_id:
        typeof cookie.load("orderOutletId") === "undefined"
          ? ""
          : cookie.load("orderOutletId"),
      orderOutletName:
        typeof cookie.load("orderOutletName") === "undefined"
          ? ""
          : cookie.load("orderOutletName"),
      cartOutletList: [],
      productPrice: 0,
      popup_close: false,
      shopeClosed: 0,
      outletslist: [],
      alloutlets: [],
      outletLocationId: "",
      loadProducts: false,
    };
  }

  componentDidMount() {
    /* this.props.getAllOutlets(
      this.state.defaultAvilTy,
      this.props.match.params.slugRestaurant,
      "",
      "",
      "",
      this.props.match.params.siteLocation
    ); */
    this.loadoutletdetails();
  }
  loadoutletdetails() {
    Axios.get(
      apiUrlV2 +
        "outlets/getAllOutles?app_id=" +
        appId +
        "&availability_id=" +
        this.state.defaultAvilTy +
        "&outlet_slug=" +
        this.props.match.params.slugRestaurant +
        "&site_location_slug=" +
        this.props.match.params.siteLocation
    ).then((response) => {
      if (response.data.status === "ok") {
        this.setState({ outletslist: response.data.result_set }, function () {
          var resultSetN = response.data.result_set;
          var outlet_id = resultSetN[0].outlet_id;
          var orderOutletName = resultSetN[0].outlet_name;
          var outletLocationId = resultSetN[0].outlet_location_id;

          this.setState(
            {
              outletslist: resultSetN,
              outlet_id: outlet_id,
              outletLocationId: outletLocationId,
            },
            function () {
              this.checkTimeAvilablity();
              if (cookie.load("orderOutletId") !== outlet_id) {
                cookie.save("orderOutletId", outlet_id, { path: "/" });
                cookie.save("orderOutletName", orderOutletName, { path: "/" });
              }
            }
          );
        });
      }
    });
  }

  componentWillReceiveProps(nxtProps) {
    if (nxtProps.globalsettings !== this.state.settings) {
      if (
        nxtProps.globalsettings !== "" &&
        typeof nxtProps.globalsettings !== undefined &&
        typeof nxtProps.globalsettings !== "undefined"
      ) {
        this.setState({ settings: nxtProps.globalsettings });
      }
    }
    if (this.state.selectedProSlug !== nxtProps.match.params.proValue) {
      this.setState(
        { selectedProSlug: nxtProps.match.params.proValue },
        function () {
          this.checkTimeAvilablity();
          this.displayCategoryProducts();
          $("html, body").animate(
            {
              scrollTop: $("#ProductDetailMdl").offset().top - 100,
            },
            2000
          );
        }
      );
      /* this.setState
       */
    }

    /*  if (this.state.cartOutletList !== nxtProps.cartOutletList) {
      this.setState({ cartOutletList: nxtProps.cartOutletList });
    } */
    if (this.state.productdetailstatus !== nxtProps.productdetailstatus) {
      $("#dvLoading").fadeOut(2000);
      this.setState(
        { productdetailstatus: nxtProps.productdetailstatus },
        function () {
          if (
            nxtProps.productdetail[0] !== "" &&
            typeof nxtProps.productdetail[0] !== undefined &&
            typeof nxtProps.productdetail[0] !== "undefined"
          ) {
            this.loadCategoryProducts(nxtProps.productdetail[0].cat_slug);
          }

          if (this.state.productdetailstatus === "failure") {
            /* showAlert(
              "Error",
              "This Product not applicable for this availability"
            );
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            }); */
            /* this.props.history.push(
              "/food/" +
                this.props.match.params.siteLocation +
                "/" +
                this.props.match.params.slugRestaurant
            ); */
          }
        }
      );
    }
  }

  checkTimeAvilablity() {
    if (this.state.outlet_id !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      if (deliveryTime !== "") {
        Axios.get(
          apiUrlV2 +
            "settings/chkOutletTimesAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            this.state.defaultAvilTy +
            "&outletID=" +
            this.state.outlet_id +
            "&orderDateTime=" +
            orderDate +
            " " +
            deliveryTime
        ).then((response) => {
          var res_min_date = moment(response.data.min_date).format("D MMM YY");
          if (response.data.status === "success") {
            this.setState({
              shopeClosed: 0,
              res_min_date: res_min_date,
            });
          } else {
            this.setState({
              shopeClosed: 1,
              res_min_date: res_min_date,
            });
          }
          if (
            this.state.selectedProSlug !== "" &&
            typeof this.state.selectedProSlug !== undefined &&
            typeof this.state.selectedProSlug !== "undefined"
          ) {
            this.props.getProductDetail(
              this.state.selectedProSlug,
              this.state.outlet_id
            );
          }
        });
      } else {
        if (this.state.loadProducts === false) {
          this.setState({ loadProducts: true }, function () {
            this.props.getProductDetail(
              this.state.selectedProSlug,
              this.state.outlet_id
            );
          });
        }
      }
    } else {
    }
  }
  pad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "cartclearflg") {
      this.setState({ cartClearTriggerFlg: value });
    }

    if (field === "cartOutletList" && value !== "") {
      this.setState({ cartOutletList: value });
    }
    if (field === "loadproduct" && value !== "") {
      this.setState({ loadproduct: "No" });
    }
  };

  showProductTags(tags) {
    return tags.map((item, index) => {
      if (item.pro_tag_name !== "") {
        return <li key={index}>{stripslashes(item.pro_tag_name)}</li>;
      }
    });
  }

  productDetailsMain() {
    var proDetStatus = this.props.productdetailstatus;
    var proDetails = this.props.productdetail;
    var imageSource = this.props.productcommon.image_source;
    var product_gallery_image_source =
      this.props.productcommon.product_gallery_image_source;
    $(".error_combo_div").hide();
    if (Object.keys(proDetails).length > 0 && proDetStatus === "success") {
      if (this.state.selectedProSlug === proDetails[0].product_slug) {
        hideLoader("prodetailinner-main-div", "class");
        hideLoader("proIndex-" + proDetails[0].product_primary_id, "Idtext");
      }
      setTimeout(function () {
        $("#proIndex-" + proDetails[0].product_primary_id).removeClass(
          "active"
        );
        hideLoader("comboPro-" + proDetails[0].product_slug, "Idtext");
        hideLoader("proIndex-" + proDetails[0].product_primary_id, "Idtext");
      }, 500);

      var desc = "";

      return proDetails.map((data, index) => {
        desc =
          data.product_long_description !== "" &&
          data.product_long_description !== null
            ? stripslashes(data.product_long_description)
            : "";

        var comboLgth = data.set_menu_component
          ? data.set_menu_component.length
          : 0;
        var ModifLgth = data.modifiers ? data.modifiers.length : 0;
        var isCombo = data.product_type === "2" && comboLgth > 0 ? "Yes" : "No";
        var isModifier =
          data.product_type === "4" && ModifLgth > 0 ? "Yes" : "No";
        var isSimple =
          data.product_type === "1" && comboLgth === 0 && ModifLgth === 0
            ? "Yes"
            : "No";
        var voucher = data.product_type === "5" ? "Yes" : "No";

        return (
          <div key={index}>
            <div
              className="inn-product-popup"
              key={data.product_primary_id}
              id={"product_" + data.product_primary_id}
            >
              <section
                className={
                  data.product_type === "5"
                    ? "product-details-top-info prod-detail-voucher"
                    : "product-details-top-info"
                }
              >
                <div className="container">
                  <div className="rib-back">
                    <Link
                      to={
                        voucher === "Yes"
                          ? "/brands/" + this.props.match.params.slugRestaurant
                          : "/food/" +
                            this.props.match.params.siteLocation +
                            "/" +
                            this.props.match.params.slugRestaurant
                      }
                      className="button"
                    >
                      Go Back
                    </Link>
                  </div>

                  <div className="product-details-main">
                    <div className="product-details-lhs">
                      <Slider {...productGallery_slider}>
                        {data.image_gallery.length > 0 &&
                        data.image_gallery.length >= 1 ? (
                          data.image_gallery.map((item, imgindex) => {
                            return (
                              <div key={imgindex}>
                                <img
                                  src={
                                    product_gallery_image_source +
                                    "/" +
                                    item.pro_gallery_image
                                  }
                                />
                              </div>
                            );
                          })
                        ) : data.product_thumbnail !== "" &&
                          data.product_thumbnail !== null ? (
                          <img
                            src={
                              this.props.productcommon.image_source +
                              "/" +
                              data.product_thumbnail
                            }
                          />
                        ) : (
                          <img src={noimage} />
                        )}
                      </Slider>
                    </div>
                    <div className="product-details-rhs">
                      {/* product info div - start */}
                      <div className="prodet_baninfo">
                        <div className="inn_product_hea">
                          <div className="inn_product_review">
                            <div className="inn_product_hea_left">
                              {voucher === "Yes" ? (
                                <h3>{data.outlet_brand}</h3>
                              ) : (
                                <h3>
                                  {" "}
                                  {data.product_alias !== ""
                                    ? stripslashes(data.product_alias)
                                    : stripslashes(data.product_name)}{" "}
                                </h3>
                              )}
                            </div>
                          </div>

                          {data.product_tag.length > 0 && (
                            <div className="product-tags-list">
                              <ul>{this.showProductTags(data.product_tag)}</ul>
                            </div>
                          )}
                          {voucher === "Yes" ? (
                            <p>
                              {data.product_short_description !== "" &&
                                Parser(
                                  stripslashes(data.product_short_description)
                                )}
                            </p>
                          ) : (
                            <p>
                              {desc !== "" && desc !== null ? Parser(desc) : ""}
                            </p>
                          )}
                        </div>
                      </div>
                      {/* product info div - end */}

                      {/* combo or modifier top div - start */}
                      <div className="inn_product_row">
                        {isCombo === "Yes" ? this.comboProDetails(data) : ""}
                        {isModifier === "Yes"
                          ? this.modifierProDetails(data)
                          : ""}
                        {isSimple === "Yes" ? this.simpleProDetails(data) : ""}
                        {voucher === "Yes" ? this.voucherProDetails(data) : ""}
                      </div>
                      {/* combo or modifier top div - end */}
                    </div>
                    {voucher === "Yes" && (
                      <div className="voucher-addtional-sec">
                        <div className="vod-title">
                          <h4>Where to use?</h4>
                        </div>
                        <div className="voucher-addtional-sec-address">
                          <ul className="vod-address">
                            <li>
                              <span>
                                {addressFormat(
                                  data.outlet_unit_number1,
                                  data.outlet_unit_number2,
                                  data.outlet_address_line1,
                                  data.outlet_address_line2,
                                  data.outlet_postal_code
                                )}
                              </span>
                            </li>
                          </ul>
                          {data.outlet_open_time !== "" && (
                            <ul className="vod-time">
                              <li>{Parser(data.outlet_open_time)}</li>
                            </ul>
                          )}
                        </div>
                        <div className="google-maps">
                          <iframe
                            src={data.outlet_map_link}
                            width="600"
                            height="450"
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                          ></iframe>
                        </div>
                        <div className="deals-long-description">
                          {desc !== "" && desc !== null ? Parser(desc) : ""}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </div>
            {voucher === "Yes" && (
              <div className="deals-related-pro">{this.state.categoryPro}</div>
            )}
          </div>
        );
      });
    } else if (
      Object.keys(proDetails).length === 0 &&
      proDetStatus === "failure"
    ) {
      return (
        <div className="product-detail-empty">
          Sorry, Invalid Product Detail.{" "}
        </div>
      );
    } else {
      return "";
    }
  }

  loadCategoryProducts(cat_slug) {
    var availabilityId =
      cookie.load("defaultAvilablityId") === undefined ||
      cookie.load("defaultAvilablityId") == ""
        ? deliveryId
        : cookie.load("defaultAvilablityId");

    var orderOutletId =
      cookie.load("orderOutletId") === undefined ||
      cookie.load("orderOutletId") == ""
        ? ""
        : cookie.load("orderOutletId");

    Axios.get(
      apiUrlV2 +
        "products/getAllProducts?app_id=" +
        appId +
        "&availability=" +
        availabilityId +
        "&category_slug=" +
        cat_slug +
        "&outletId=" +
        orderOutletId
    ).then((res) => {
      var response = res.data;
      if (response.status === "ok") {
        this.setState({ categoryProducts: response.result_set }, function () {
          this.displayCategoryProducts();
        });
      }
    });
  }

  displayCategoryProducts() {
    var categoryProducts = this.state.categoryProducts;
    var products_count =
      categoryProducts !== ""
        ? categoryProducts[0].subcategorie[0].products.length
        : 0;
    var categoryPro = "";
    if (categoryProducts !== "" && categoryProducts.length > 0) {
      categoryPro = (
        <section className="product-details-category-section container">
          <div className="category-products-section">
            <div className="container-noneed">
              {products_count !== "" && products_count > 1 && (
                <h3>Related Deals</h3>
              )}
              <div className="category-products-main">
                <div className="products-list-ulmain">
                  <OwlCarousel options={settingsProduct}>
                    {categoryProducts.map((subcat, index) => {
                      if (subcat.subcategorie.length > 0) {
                        return subcat.subcategorie.map(
                          (productsList, index1) => {
                            if (productsList.products.length > 0) {
                              return productsList.products.map(
                                (products, index2) => {
                                  if (
                                    this.state.selectedProSlug !==
                                    products.product_slug
                                  ) {
                                    return (
                                      <Link
                                        to={
                                          "/food/" +
                                          this.props.match.params.siteLocation +
                                          "/" +
                                          products.outlet_slug +
                                          "/" +
                                          productsList.pro_cate_slug +
                                          "/" +
                                          productsList.pro_subcate_slug +
                                          "/" +
                                          products.product_slug
                                        }
                                      >
                                        <div
                                          className="rel-products-single-li"
                                          key={
                                            index + "-" + index1 + "-" + index2
                                          }
                                        >
                                          <div className="market-item">
                                            <div className="market-image">
                                              <a>
                                                <img
                                                  src={
                                                    products.product_thumbnail !==
                                                      "" &&
                                                    products.product_thumbnail !==
                                                      null
                                                      ? proFolderUrl +
                                                        "main-image/" +
                                                        products.product_thumbnail
                                                      : dealsplace
                                                  }
                                                />
                                              </a>
                                              {products.product_tag_info !==
                                                "" &&
                                                products.product_tag_info !==
                                                  null && (
                                                  <span className="tag-info">
                                                    {products.product_tag_info}
                                                  </span>
                                                )}
                                            </div>
                                            <div className="market-name">
                                              <h4>
                                                {products.product_alias !==
                                                  "" &&
                                                products.product_alias !== null
                                                  ? stripslashes(
                                                      products.product_alias
                                                    )
                                                  : stripslashes(
                                                      products.product_name
                                                    )}
                                              </h4>
                                              <div className="deal-name">
                                                <span>
                                                  {stripslashes(
                                                    products.brand_name
                                                  )}
                                                </span>
                                              </div>
                                              <div className="deal-outlet">
                                                <span>
                                                  {stripslashes(
                                                    products.outlet_name
                                                  )}
                                                </span>
                                              </div>

                                              <div className="price">
                                                ${products.product_price}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </Link>
                                    );
                                  }
                                }
                              );
                            }
                          }
                        );
                      }
                    })}
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    }
    this.setState({ categoryPro: categoryPro });
  }

  /* show modifier product details */
  simpleProDetails(proDetailArr) {
    var modProductPrice = proDetailArr.product_price;
    var modProductTotalPrice =
      proDetailArr.product_specialprice_applicable === "yes"
        ? proDetailArr.product_special_price
        : proDetailArr.product_price;
    var imageSource = this.props.productcommon.image_source;
    var product_gallery_image_source =
      this.props.productcommon.product_gallery_image_source;
    return (
      <div>
        <div className="prd_chosen_row compo_top_div smiple_product_div">
          <div
            id={"proDtIndex-" + proDetailArr.product_primary_id}
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div className="product_details_special_instruction modifier_instraction">
              <h3>Special Instructions </h3>
              <p>
                Let us know if you are allergic to anything or would like to
                avoid anything.{" "}
              </p>
            </div>

            <div className="product_chosen_col product_chosen_col_right">
              <div className="text-box">
                <textarea
                  placeholder="Eg: no Mayo"
                  name="special_notes"
                  id="special_notes"
                  maxLength="400"
                  value={this.state.product_remarks}
                  onChange={this.updateRemarksCompo.bind(this)}
                ></textarea>
              </div>
            </div>

            {proDetailArr.paired_products.length > 0 && (
              <div className="product_details_other_loves">
                <h3>Others love this with</h3>
                <div className="up-product-slider-modelpopup">
                  <Slider {...settings_simple}>
                    {proDetailArr.paired_products.map((item, index) => {
                      return (
                        <div
                          className="up-product-item"
                          onClick={this.openPairedProduct.bind(
                            this,
                            "/" +
                              this.props.productState.currentpage_group_url +
                              "/" +
                              this.props.productState.outlet_slug +
                              "/" +
                              item.cat_slug +
                              "/" +
                              item.subcate_slug +
                              "/" +
                              item.product_slug
                          )}
                        >
                          <div className="products-single-li">
                            <div className="product-info-div">
                              <div className="product-title-maindiv">
                                <div className="product-title">
                                  <h3>
                                    {item.product_alias !== ""
                                      ? stripslashes(item.product_alias)
                                      : stripslashes(item.product_name)}
                                  </h3>
                                </div>
                                <div className="product-tag-list"></div>
                              </div>

                              {item.product_short_description !== "" && (
                                <div className="product-short-description">
                                  <p>
                                    {item.product_short_description !== ""
                                      ? item.product_short_description
                                      : Parser("&nbsp;")}
                                  </p>
                                </div>
                              )}

                              <div className="product-price">
                                <h3>$ {item.product_price}</h3>
                              </div>
                            </div>
                            <div className="products-image-div">
                              {item.product_thumbnail !== "" ? (
                                <img
                                  src={
                                    imageSource + "/" + item.product_thumbnail
                                  }
                                />
                              ) : (
                                <img src={ProductSlider1} />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            )}

            <div className="prd_chosen_sub_row">
              <p>
                <sup>$</sup>
                <span id="id_price_final">
                  {this.state.productPrice > 0
                    ? this.state.productPrice
                    : modProductTotalPrice}
                </span>
              </p>

              <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
                <div className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        proDetailArr.product_primary_id,
                        proDetailArr.product_price,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      value={this.state.singleinput_value}
                      id="simple_product_value"
                      readOnly="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        proDetailArr.product_primary_id,
                        proDetailArr.product_price,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>

                <div className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv">
                  {this.state.shopeClosed === 0 ? (
                    <button
                      onClick={this.addToCartSimple.bind(
                        this,
                        proDetailArr,
                        "done"
                      )}
                    >
                      Add to Cart
                    </button>
                  ) : (
                    <span className="outlet-closed-error-msg">
                      Outlet is close. Please choose a different date and time
                    </span>
                  )}
                </div>

                {/* <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  voucherProDetails(proDetailArr) {
    var modProductPrice = proDetailArr.product_price;
    var modProductTotalPrice = proDetailArr.product_price;
    var imageSource = this.props.productcommon.image_source;

    return (
      <div>
        <div className="voucher-header">Select your voucher</div>
        <div className="voucher-innheader">
          {proDetailArr.product_alias !== ""
            ? stripslashes(proDetailArr.product_alias)
            : stripslashes(proDetailArr.product_name)}
        </div>
        <div className="prd_chosen_row compo_top_div smiple_product_div voucher_product_div">
          <div
            id={"proDtIndex-" + proDetailArr.product_primary_id}
            className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls"
          >
            <div className="vocu-defw">
              <div className="vocu-def">
                <div className="wqe-price">
                  <p>
                    <sup>$</sup>
                    <span id="id_price_final">
                      {this.state.productPrice > 0
                        ? this.state.productPrice
                        : modProductTotalPrice}
                    </span>
                  </p>
                </div>
              </div>
              {proDetailArr.product_tag_info !== "" &&
                proDetailArr.product_tag_info !== null && (
                  <span className="tag-info">
                    {proDetailArr.product_tag_info}
                  </span>
                )}

              <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
                <div className="addcart_row prd_chosen_sub_item_left cart_update_div addcart_done_maindiv">
                  <div className="qty_bx">
                    <span
                      className="qty_minus"
                      onClick={this.proQtyAction.bind(
                        this,
                        proDetailArr.product_primary_id,
                        proDetailArr.product_price,
                        "decr"
                      )}
                    >
                      -
                    </span>
                    <input
                      type="text"
                      value={this.state.singleinput_value}
                      id="simple_product_value"
                      readOnly="1"
                    />
                    <span
                      className="qty_plus"
                      onClick={this.proQtyAction.bind(
                        this,
                        proDetailArr.product_primary_id,
                        proDetailArr.product_price,
                        "incr"
                      )}
                    >
                      +
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="prd_chosen_sub_row">
              <div className="prd_chosen_sub_item_right cart_update_div addcart_done_maindiv">
                <button onClick={this.openVoucherPopup.bind(this)}>
                  Buy Now
                </button>
              </div>
            </div>

            {proDetailArr.paired_products.length > 0 && (
              <div className="product_details_other_loves">
                <h3>Others love this with</h3>
                <div className="up-product-slider-modelpopup">
                  <Slider {...settings_simple}>
                    {proDetailArr.paired_products.map((item, index) => {
                      return (
                        <div
                          className="up-product-item"
                          onClick={this.openPairedProduct.bind(
                            this,
                            "/" +
                              this.props.productState.currentpage_group_url +
                              "/" +
                              this.props.productState.outlet_slug +
                              "/" +
                              item.cat_slug +
                              "/" +
                              item.subcate_slug +
                              "/" +
                              item.product_slug
                          )}
                          key={index}
                        >
                          <div className="products-single-li">
                            <div className="product-info-div">
                              <div className="product-title-maindiv">
                                <div className="product-title">
                                  <h3>
                                    {item.product_alias !== ""
                                      ? stripslashes(item.product_alias)
                                      : stripslashes(item.product_name)}
                                  </h3>
                                </div>
                                <div className="product-tag-list"></div>
                              </div>

                              {item.product_short_description !== "" && (
                                <div className="product-short-description">
                                  <p>
                                    {item.product_short_description !== ""
                                      ? item.product_short_description
                                      : Parser("&nbsp;")}
                                  </p>
                                </div>
                              )}

                              <div className="product-price">
                                <h3>$ {item.product_price}</h3>
                              </div>
                            </div>
                            <div className="products-image-div">
                              {item.product_thumbnail !== "" ? (
                                <img
                                  src={
                                    imageSource + "/" + item.product_thumbnail
                                  }
                                />
                              ) : (
                                <img src={ProductSlider1} />
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              </div>
            )}
          </div>
        </div>

        <div
          id="vouchergift-popup"
          className="white-popup mfp-hide popup_sec vouchergift-popup"
        >
          <div className="voucher-popup-inner pop-whole full-login-new">
            <div className="full-login-new-header">
              <h3>IM PURCHASING THIS</h3>
            </div>
            <div className="full-login-new-body">
              <div className="popup-footer voucher-popup-body product_voucher_body">
                <ul className="nav nav-tabs text-center product_voucher vouchergift_ul">
                  <li className="active">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn5"
                      aria-expanded="true"
                    >
                      <span>For Gift</span>
                    </a>
                  </li>
                  <li className="">
                    <a
                      data-toggle="tab"
                      href="#tab-id-inn6"
                      aria-expanded="false"
                    >
                      <span>For Me</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div id="tab-id-inn5" className="tab-pane fade active in">
                    <h4 className="tab_mobtrigger inner_tab_border active"></h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="order-delivery">
                        <div className="ord-body">
                          <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                            <ul className="myacc_order_details">
                              <div className="voucher-popup-inner product-voucher-popup">
                                <h2>GIFT RECEIVER'S INFORMATION</h2>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Name</label>
                                    <input
                                      type="text"
                                      id="guest-name"
                                      name="guest-name"
                                      value={this.state.voucher_name}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_name"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_name}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Enter Mobile Number</label>
                                    <input
                                      type="tel"
                                      id="guest-number"
                                      name="guest-number"
                                      value={this.state.voucher_mobile}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_mobile"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_mobile}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Email Address</label>
                                    <input
                                      type="text"
                                      id="guest-email"
                                      name="guest-email"
                                      value={this.state.voucher_email}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_email"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_email}
                                  </div>
                                </div>
                                <div className="form-group">
                                  <div className="focus-out">
                                    <label>Message</label>
                                    <textarea
                                      type="text"
                                      id="guest-message"
                                      name="guest-message"
                                      value={this.state.voucher_message}
                                      onClick={this.addClassInput.bind(this)}
                                      onChange={this.handleChangeTxt.bind(
                                        this,
                                        "voucher_message"
                                      )}
                                      className="form-control input-focus"
                                    />
                                    {this.state.error_voucher_message}
                                  </div>
                                </div>

                                <div className="howtouse-gift-vouchar">
                                  <img src={smartPhone} />
                                  <h3>How to use</h3>
                                  <p>
                                    After purchasing, your recipient will get
                                    email notification to use the above email
                                    address to sign up. Vouchers will be
                                    available within their account.
                                  </p>
                                </div>
                                <div className="form-group gift-group">
                                  <button
                                    type="button"
                                    className="button add_to_voucher"
                                    onClick={this.addToCartVoucher.bind(
                                      this,
                                      proDetailArr
                                    )}
                                  >
                                    Add to Cart
                                  </button>
                                </div>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tab-id-inn6" className="tab-pane fade">
                    <h4 className="tab_mobtrigger inner_tab_border"></h4>
                    <div className="tab_mobrow filter_tabin">
                      <div className="cur-order-body mypromo-main-div myvouchar-main-div">
                        <ul className="myacc_order_details used-voucher">
                          <div className="voucher-popup-inner product-voucher-popup">
                            <div className="howtouse-gift-vouchar">
                              <img src={smartPhone} />
                              <h3>How to use</h3>
                              <p>
                                After purchasing, your recipient will get email
                                notification to use the above email address to
                                sign up. Vouchers will be available within their
                                account.
                              </p>
                            </div>

                            <button
                              type="button"
                              className="button add_to_voucher"
                              onClick={this.addToCartVoucherMe.bind(
                                this,
                                proDetailArr
                              )}
                            >
                              Add to Cart
                            </button>
                          </div>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  openVoucherPopup() {
    var orderDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined"
        ? cookie.load("deliveryTime")
        : "";
    if (
      this.state.defaultAvilTy === "" ||
      orderDate === "" ||
      deliveryTime === ""
    ) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;
    }
    $.magnificPopup.open({
      items: {
        src: "#vouchergift-popup",
      },
      type: "inline",
    });
  }

  addClassInput() {
    $(".input-focus").focus(function () {
      $(this).parents(".focus-out").addClass("focused");
    });
    $(".input-focus").blur(function () {
      var inputValue = $(this).val();
      if (inputValue == "") {
        $(this).removeClass("filled");
        $(this).parents(".focus-out").removeClass("focused");
      } else {
        $(this).addClass("filled");
      }
    });
  }

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    this.setState({ ["error_" + item]: "" });
  };

  proQtyAction(indxFlg, productPrice, actionFlg) {
    var proqtyInput = $("#simple_product_value").val();
    proqtyInput = parseInt(proqtyInput);

    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    $("#simple_product_value").val(proqtyInput);
    var productPrice = productPrice * proqtyInput;
    productPrice = parseFloat(productPrice).toFixed(2);
    this.setState({
      productPrice: productPrice,
      singleinput_value: proqtyInput,
    });
  }

  /* add to cart */
  addToCartSimple(productDetail, actionFlg) {
    var cartOutletList = this.state.cartOutletList;
    var orderDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined"
        ? cookie.load("deliveryTime")
        : "";
    if (
      this.state.defaultAvilTy === "" ||
      orderDate === "" ||
      deliveryTime === ""
    ) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;
    }

    if (
      cartOutletList.length >= parseInt(this.state.settings.max_order_handle)
    ) {
      if (cartOutletList.indexOf(this.state.outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var IndexFlg = productDetail.product_primary_id;

    if (actionFlg === "initial") {
      $("#proDtIndex-" + IndexFlg)
        .find(".smiple_product_lk")
        .hide();
      $("#proDtIndex-" + IndexFlg)
        .find(".addcart_done_maindiv")
        .show();
      return false;
    } else {
      showLoader("proDtIndex-" + IndexFlg, "Idtext");
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var productId = productDetail.product_id;
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");
      var proqtyQty = this.state.singleinput_value;
      var specialprice_applicable =
        productDetail.product_specialprice_applicable;
      var postObject = {
        app_id: appId,
        product_id: productId,
        outletLocationId: this.state.outletLocationId,
        outlet_id: this.state.outlet_id,
        product_qty: proqtyQty,
        product_type: 1,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        specialprice_applicable: specialprice_applicable,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        shopType: this.state.shopType,
        outletzone_id: this.state.orderZoneId,
        cart_id:
          cookie.load("cart_id") !== "" &&
          typeof cookie.load("cart_id") !== undefined &&
          typeof cookie.load("cart_id") !== "undefined"
            ? cookie.load("cart_id")
            : "",
        cart_group_id:
          cookie.load("cart_group_id") !== "" &&
          typeof cookie.load("cart_group_id") !== undefined &&
          typeof cookie.load("cart_group_id") !== "undefined"
            ? cookie.load("cart_group_id")
            : "",
        product_remarks: this.state.product_remarks,
      };

      Axios.post(
        apiUrlV2 + "cart/simpleCartInsert",
        qs.stringify(postObject)
      ).then((res) => {
        hideLoader("proDtIndex-" + IndexFlg, "Idtext");
        $("#proDtIndex-" + IndexFlg)
          .find(".smiple_product_lk")
          .show();
        if (res.data.status === "ok") {
          var productName =
            productDetail.product_alias !== ""
              ? stripslashes(productDetail.product_alias)
              : stripslashes(productDetail.product_name);
          var proPrice = productDetail.product_price;
          var outlet_name = this.state.orderOutletName;
          window.dataLayer.push({
            event: "addToCart",
            ecommerce: {
              currencyCode: "SGD",
              add: {
                products: [
                  {
                    name: stripslashes(productName),
                    id: productDetail.product_id,
                    price: proPrice,
                    brand: stripslashes(outlet_name),
                    category: stripslashes(productDetail.catgory_name),
                    variant: "",
                    quantity: proqtyQty,
                  },
                ],
              },
            },
          });

          this.sateValChange("cartflg", "yes");
          $(".common-modal-head .close").trigger("click");
          showCustomAlert(
            "success",
            "Great! Your item has been added to the cart",
            "cart-success-msg"
          );

          this.setState({
            cartTriggerFlg: "yes",
            productPrice: 0,
            singleinput_value: 1,
            product_remarks: "",
          });

          removePromoCkValue();
          var currentThis = this;
          cookie.save(
            "site_location",
            currentThis.props.match.params.siteLocation,
            { path: "/" }
          );
          cookie.save(
            "slug_restaurant",
            currentThis.props.match.params.slugRestaurant,
            { path: "/" }
          );
          setTimeout(function () {
            currentThis.props.history.push(
              "/food/" +
                currentThis.props.match.params.siteLocation +
                "/" +
                currentThis.props.match.params.slugRestaurant
            );
          }, 1000);
        } else if (res.data.status === "error") {
          var errMsgtxt =
            res.data.message !== ""
              ? res.data.message
              : "Sorry! Products can`t add your cart.";
          showCustomAlert("Notice", errMsgtxt);
          this.handleShowAlertFun("Error", errMsgtxt);
        }

        return false;
      });
    }
  }

  addToCartVoucherMe(voucherProductDetail) {
    var availabilityId = cookie.load("defaultAvilablityId");
    /*var availabilityId = deliveryId;*/

    if (!cookie.load("UserId")) {
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
      cookie.save("redirectProducts", "Yes", { path: "/" });
      return false;
    }

    var availabilityName =
      availabilityId === deliveryId ? "Delivery" : "Pickup";
    var isAddonProduct = "No";
    var customerId =
      typeof cookie.load("UserId") === "undefined" ? "" : cookie.load("UserId");

    var TotalPrice =
      voucherProductDetail.product_price * this.state.singleinput_value;
    var postObject = {};

    var ImagePath = voucherProductDetail.product_thumbnail;
    if (ImagePath !== "") {
      var postImagePath =
        this.props.productcommon.image_source +
        "/" +
        voucherProductDetail.product_thumbnail;
    } else {
      var postImagePath = "";
    }

    var outletLocationId = voucherProductDetail.outlet_location_id;
    var productName =
      voucherProductDetail.product_alias !== ""
        ? stripslashes(voucherProductDetail.product_alias)
        : stripslashes(voucherProductDetail.product_name);

    postObject = {
      app_id: appId,
      outlet_id: this.state.outlet_id,
      shopType: this.state.shopType,
      outletLocationId: outletLocationId,
      outlet_id: this.state.outlet_id,
      outletzone_id: this.state.orderZoneId,
      product_id: voucherProductDetail.product_id,
      product_qty: this.state.singleinput_value,
      product_name: productName,
      product_sku: voucherProductDetail.product_sku,
      product_total_price: TotalPrice,
      product_unit_price: voucherProductDetail.product_price,
      product_type: 5,
      product_image: postImagePath,
      availability_id: availabilityId,
      availability_name: availabilityName,
      isAddonProduct: isAddonProduct,
      reference_id: customerId === "" ? getReferenceID() : "",
      customer_id: customerId,
      cart_id:
        cookie.load("cart_id") !== "" &&
        typeof cookie.load("cart_id") !== undefined &&
        typeof cookie.load("cart_id") !== "undefined"
          ? cookie.load("cart_id")
          : "",
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      voucher_for: "Me",
      voucher_gift_name:
        typeof cookie.load("UserFname") === "undefined"
          ? ""
          : cookie.load("UserFname"),
      voucher_gift_mobile:
        typeof cookie.load("UserMobile") === "undefined"
          ? ""
          : cookie.load("UserMobile"),
      voucher_gift_email:
        typeof cookie.load("UserEmail") === "undefined"
          ? ""
          : cookie.load("UserEmail"),
      voucher_gift_message: "",
      product_voucher_expiry_date:
        voucherProductDetail.product_voucher_expiry_date !== ""
          ? voucherProductDetail.product_voucher_expiry_date
          : "",
    };

    showLoader("add_to_voucher", "class");

    Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then((res) => {
      $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
      hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
      hideLoader("add_to_voucher", "class");
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".addcart_done_maindiv")
        .hide();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".smiple_product_lk")
        .show();
      $("#proIndex-" + this.state.voucherIndexFlag)
        .find(".product-list-main-div")
        .removeClass("add-cart");
      if (res.data.status === "ok") {
        removePromoCkValue();
        showCustomAlert("success", "Great choice! Item added to your cart.");
        /*showCartLst();*/
        this.handleShowAlertFun(
          "success",
          "Great choice! Item added to your cart."
        );
        this.setState({
          singleinput_value: 1,
          cartTriggerFlg: "yes",
        });
      } else if (res.data.status === "error") {
        var errMsgtxt =
          res.data.message !== ""
            ? res.data.message
            : "Sorry! Products can`t add your cart.";
        showCustomAlert("error", errMsgtxt);
        this.handleShowAlertFun("Error", errMsgtxt);
        this.setState({
          singleinput_value: 1,
          voucherIndexFlag: "",
        });
      }

      return false;
    });
  }

  addToCartVoucher(voucherProductDetail) {
    var error = 0;
    if (this.state.voucher_name === null || this.state.voucher_name === "") {
      error++;
      this.setState({
        error_voucher_name: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_name: "" });
    }

    if (
      this.state.voucher_mobile === null ||
      this.state.voucher_mobile === ""
    ) {
      error++;
      this.setState({
        error_voucher_mobile: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_mobile: "" });
    }

    if (this.state.voucher_email === null || this.state.voucher_email === "") {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(
        this.state.voucher_email
      )
    ) {
      error++;
      this.setState({
        error_voucher_email: Parser(
          '<span class="error">This is not a valid email.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_email: "" });
    }

    if (
      this.state.voucher_message === null ||
      this.state.voucher_message === ""
    ) {
      error++;
      this.setState({
        error_voucher_message: Parser(
          '<span class="error">This field is required.</span>'
        ),
      });
    } else {
      this.setState({ error_voucher_message: "" });
    }

    if (error == 0) {
      var availabilityId = cookie.load("defaultAvilablityId");
      /*var availabilityId = deliveryId;*/
      var availabilityName =
        availabilityId === deliveryId ? "Delivery" : "Pickup";
      var isAddonProduct = "No";
      var customerId =
        typeof cookie.load("UserId") === "undefined"
          ? ""
          : cookie.load("UserId");

      var TotalPrice =
        voucherProductDetail.product_price * this.state.singleinput_value;

      var ImagePath = voucherProductDetail.product_thumbnail;
      if (ImagePath !== "") {
        var postImagePath =
          this.props.productcommon.image_source +
          "/" +
          voucherProductDetail.product_thumbnail;
      } else {
        var postImagePath = "";
      }

      var outletLocationId = voucherProductDetail.outlet_location_id;

      var productName =
        voucherProductDetail.product_alias !== ""
          ? stripslashes(voucherProductDetail.product_alias)
          : stripslashes(voucherProductDetail.product_name);

      var postObject = {};
      postObject = {
        app_id: appId,
        product_id: voucherProductDetail.product_id,
        product_qty: this.state.singleinput_value,
        product_name: productName,
        product_sku: voucherProductDetail.product_sku,
        product_total_price: TotalPrice,
        outletLocationId: outletLocationId,
        outlet_id: this.state.outlet_id,
        product_unit_price: voucherProductDetail.product_price,
        product_image: postImagePath,
        product_type: 5,
        availability_id: availabilityId,
        availability_name: availabilityName,
        isAddonProduct: isAddonProduct,
        reference_id: customerId === "" ? getReferenceID() : "",
        customer_id: customerId,
        voucher_for: "Gift",
        voucher_gift_name: this.state.voucher_name,
        voucher_gift_mobile: this.state.voucher_mobile,
        voucher_gift_email: this.state.voucher_email,
        voucher_gift_message: this.state.voucher_message,
        product_voucher_expiry_date:
          voucherProductDetail.product_voucher_expiry_date !== ""
            ? voucherProductDetail.product_voucher_expiry_date
            : "",
      };

      Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
        (res) => {
          $("#proIndex-" + this.state.voucherIndexFlag).removeClass("active");
          hideLoader("proIndex-" + this.state.voucherIndexFlag, "Idtext");
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".addcart_done_maindiv")
            .hide();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".smiple_product_lk")
            .show();
          $("#proIndex-" + this.state.voucherIndexFlag)
            .find(".product-list-main-div")
            .removeClass("add-cart");
          if (res.data.status === "ok") {
            removePromoCkValue();
            showCustomAlert(
              "success",
              "Great choice! Item added to your cart."
            );
            /*showCartLst();*/
            this.handleShowAlertFun(
              "success",
              "Great choice! Item added to your cart."
            );
            this.setState({
              cartTriggerFlg: "yes",
              singleinput_value: 1,
              voucher_name: "",
              voucher_mobile: "",
              voucher_email: "",
              voucher_message: "",
            });
          } else if (res.data.status === "error") {
            var errMsgtxt =
              res.data.message !== ""
                ? res.data.message
                : "Sorry! Products can`t add your cart.";
            showCustomAlert("error", errMsgtxt);
            this.handleShowAlertFun("Error", errMsgtxt);
            this.setState({
              singleinput_value: 1,
              voucherIndexFlag: "",
            });
          }

          return false;
        }
      );
    }
  }

  handleShowAlertFun(header, msg) {
    var magnfPopup = $.magnificPopup.instance;
    showAlert(header, msg, magnfPopup);
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show modifier product details */
  modifierProDetails(proDetailArr) {
    var modProductPrice =
      this.state.modProductPrice !== ""
        ? this.state.modProductPrice
        : proDetailArr.product_price;
    var modProductTotalPrice =
      this.state.modProductTotalPrice !== ""
        ? this.state.modProductTotalPrice
        : proDetailArr.product_price;
    var imageSource = this.props.productcommon.image_source;
    var product_gallery_image_source =
      this.props.productcommon.product_gallery_image_source;
    return (
      <div>
        <div className="prd_chosen_row mdf_common_rows compo_top_div">
          <div id="modErrorDiv" className="modfr-alert-danger single-danger">
            {" "}
            Product combination is wrong. Please check your combination{" "}
          </div>

          <div className="product_chosen_inner">
            <input
              type="hidden"
              id="modProductSlug"
              name="modProductSlug"
              value={proDetailArr.product_slug}
            />
            <input
              type="hidden"
              id="modProductId"
              name="modProductId"
              value={proDetailArr.product_id}
            />
            <input
              type="hidden"
              id="modProductPrice"
              name="modProductPrice"
              value={modProductPrice}
            />
            <input
              type="hidden"
              id="modParentProductId"
              name="modParentProductId"
              value={this.state.modParentProductId}
            />
            <input
              type="hidden"
              id="modProductTotalPrice"
              name="modProductTotalPrice"
              value={modProductTotalPrice}
            />

            <div className="product_chosen_col">
              <div className="product_chosen_col_inner">
                {this.modifierProOptions(proDetailArr)}
              </div>
            </div>

            <div className="product_details_special_instruction">
              <h3>Special Instructions </h3>
              <p>
                Let us know if you are allergic to anything or would like to
                avoid anything.{" "}
              </p>
            </div>

            <div className="product_chosen_col product_chosen_col_right">
              <div className="text-box">
                <textarea
                  placeholder="Eg: no Mayo"
                  name="special_notes"
                  id="special_notes"
                  maxLength="400"
                  value={this.state.product_remarks}
                  onChange={this.updateRemarks.bind(this)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        {proDetailArr.paired_products.length > 0 && (
          <div className="product_details_other_loves">
            <h3>Others love this with</h3>
            <div className="up-product-slider-modelpopup">
              <Slider {...productSlider}>
                {proDetailArr.paired_products.map((item, index) => {
                  return (
                    <div
                      className="up-product-item"
                      onClick={this.openPairedProduct.bind(
                        this,
                        "/" +
                          this.props.productState.currentpage_group_url +
                          "/" +
                          this.props.productState.outlet_slug +
                          "/" +
                          item.cat_slug +
                          "/" +
                          item.subcate_slug +
                          "/" +
                          item.product_slug
                      )}
                    >
                      <div className="products-single-li">
                        <div className="product-info-div">
                          <div className="product-title-maindiv">
                            <div className="product-title">
                              <h3>
                                {item.product_alias !== ""
                                  ? stripslashes(item.product_alias)
                                  : stripslashes(item.product_name)}
                              </h3>
                            </div>
                            <div className="product-tag-list"></div>
                          </div>

                          {item.product_short_description !== "" && (
                            <div className="product-short-description">
                              <p>
                                {item.product_short_description !== ""
                                  ? item.product_short_description
                                  : Parser("&nbsp;")}
                              </p>
                            </div>
                          )}

                          <div className="product-price">
                            <h3>$ {item.product_price}</h3>
                          </div>
                        </div>
                        <div className="products-image-div">
                          {item.product_thumbnail !== "" ? (
                            <img
                              src={imageSource + "/" + item.product_thumbnail}
                            />
                          ) : (
                            <img src={ProductSlider1} />
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        )}

        <div className="prd_chosen_sub_row">
          <p>
            <sup>$</sup>
            <span id="id_price_final">{modProductTotalPrice}</span>
          </p>
          <div className="prd_chosen_sub_col popup_addcart_cls modfir_addcart_cls">
            <div
              className="addcart_row prd_chosen_sub_item_left cart_update_div mdf_update_div"
              style={{ display: "none" }}
            >
              <div className="qty_bx">
                <span
                  className="qty_minus"
                  onClick={this.mfdrQtyAction.bind(this, "decr")}
                >
                  -
                </span>
                <input
                  type="text"
                  value={this.state.mdfinput_value}
                  className="modfir_proqty_input"
                  readOnly="1"
                />
                <span
                  className="qty_plus"
                  onClick={this.mfdrQtyAction.bind(this, "incr")}
                >
                  +
                </span>
              </div>
            </div>

            <div
              className="prd_chosen_sub_item_right cart_update_div mdf_update_div"
              style={{ display: "none" }}
            >
              <button
                onClick={this.addToCartModifier.bind(
                  this,
                  proDetailArr,
                  "done"
                )}
              >
                Add to Cart
              </button>
            </div>

            <div className="prd_chosen_sub_item_right prd_chosen_item_full cart_add_div mdf_add_div">
              {this.state.shopeClosed === 0 ? (
                <button
                  onClick={this.addToCartModifier.bind(
                    this,
                    proDetailArr,
                    "initial"
                  )}
                >
                  Add To Cart
                </button>
              ) : (
                <span className="outlet-closed-error-msg">
                  Outlet is close. Please choose a different date and time
                </span>
              )}
            </div>

            {/* <div className="cart-success-msg alert alert_success mdfcart_success_msg"></div>
            <div className="cart-error-msg alert alert_danger mdfcart_error_msg"></div> */}
          </div>
        </div>
      </div>
    );
  }

  /* show modifier product option */
  modifierProOptions(mdfproducts) {
    var modifiersArr =
      mdfproducts.modifiers !== null && mdfproducts.modifiers !== ""
        ? mdfproducts.modifiers
        : Array();

    if (modifiersArr.length > 0) {
      const html = modifiersArr.map((item, index) => (
        <div
          className="product_chosen_item_left product_chosen_item_left_full"
          key={index}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item.pro_modifier_name)}</h6>
              <span></span>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_" + index}>
              {this.modifierValuesOpn(item)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  modifierValuesOpn(mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index, item) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          stripslashes(item.pro_modifier_value_name) +
          "~" +
          item.pro_modifier_value_id +
          "~" +
          item.pro_modifier_value_price;

        var incPrc =
          parseFloat(item.pro_modifier_value_price) > 0
            ? " ( + $" + item.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item.pro_modifier_value_id +
          "' data-mdfvl='" +
          datamdfVl +
          "'>" +
          item.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal = this.state["modifier~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="modifier_main"
          value={mdfSelectVl}
          className="modifierList"
          onChange={this.handleChange.bind(this, mainMdfId)}
          id={"modif" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  handleChange(mdfVl, event) {
    $("#modErrorDiv").hide();
    this.setState({
      mdfApi_call: "Yes",
      ["modifier~~" + mdfVl]: event.target.value,
    });
  }

  mfdrQtyAction(actionFlg) {
    var proqtyInput = $(".modfir_proqty_input").val();
    var modProductPrice = $("#modProductPrice").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    var productTotalPrice =
      parseFloat(modProductPrice) * parseFloat(proqtyInput);
    productTotalPrice = parseFloat(productTotalPrice).toFixed(2);

    /*$('.modfir_proqty_input').val(proqtyInput);*/
    this.setState({
      mdfApi_call: "No",
      mdfinput_value: proqtyInput,
      modProductTotalPrice: productTotalPrice,
    });
  }

  updateRemarks(event) {
    this.setState({ mdfApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  addToCartModifier(mdfProdDetail, actionFlg) {
    var outlet_id =
      typeof cookie.load("orderOutletId") === "undefined"
        ? ""
        : cookie.load("orderOutletId");
    var cartOutletList = this.state.cartOutletList;
    if (
      cartOutletList.length >= parseInt(this.state.settings.max_order_handle)
    ) {
      if (cartOutletList.indexOf(outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }
    if (actionFlg === "initial") {
      $(".mdf_add_div").hide();
      $(".mdf_update_div").show();
      return false;
    } else {
      var modCount = $(".modifierList").length;
      $("#modErrorDiv").hide();
      var modifier = [];
      if (modCount > 0) {
        var errorChk = 0;
        $(".modifierList").each(function () {
          var modVal = $(this).val();
          var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
          if (modVal != "" && modSelectVal != "") {
            var modifier_sets = modSelectVal.split("~");
            var modifierVal = [];
            modifierVal.push({
              modifier_value_id: modifier_sets[3],
              modifier_value_qty: "1",
              modifier_value_name: modifier_sets[2],
              modifier_value_price: modifier_sets[4],
            });
            modifier.push({
              modifier_id: modifier_sets[0],
              modifier_name: modifier_sets[1],
              modifiers_values: modifierVal,
            });
          } else if (modSelectVal == "" || modVal == "") {
            errorChk = 1;
          }
        });

        if (errorChk == 1) {
          $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
          $(".mdfcart_error_msg").show();
          $(".mdfcart_error_msg").delay(6000).fadeOut();
          return false;
        } else if (errorChk == 0) {
          showLoader("modfir_addcart_cls", "class");

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.mdfinput_value;
          var modParentId = this.state.modParentProductId;
          var modProductId = $("#modProductId").val();

          var postObject = {};
          postObject = {
            app_id: appId,
            product_id: modProductId,
            outlet_id: outlet_id,
            outletLocationId: this.state.outletLocationId,
            product_modifier_parent_id: modParentId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            product_qty: prodcutQty,
            product_type: 4,
            modifiers: JSON.stringify(modifier),
            menu_set_component: "",
            product_remarks: productRemarks,
            reference_id: customerId === "" ? getReferenceID() : "",
            customer_id: customerId,
            shopType: this.state.shopType,
            outletzone_id: this.state.orderZoneId,
            cart_id:
              cookie.load("cart_id") !== "" &&
              typeof cookie.load("cart_id") !== undefined &&
              typeof cookie.load("cart_id") !== "undefined"
                ? cookie.load("cart_id")
                : "",
            cart_group_id:
              cookie.load("cart_group_id") !== "" &&
              typeof cookie.load("cart_group_id") !== undefined &&
              typeof cookie.load("cart_group_id") !== "undefined"
                ? cookie.load("cart_group_id")
                : "",
          };

          Axios.post(
            apiUrlV2 + "cart/simpleCartInsert",
            qs.stringify(postObject)
          ).then((res) => {
            hideLoader("modfir_addcart_cls", "class");
            if (res.data.status === "ok") {
              $(".mdf_update_div").hide();
              $(".mdf_add_div").show();
              $(".common-modal-head .close").trigger("click");
              showCustomAlert(
                "success",
                "Great! Your item has been added to the cart",
                "cart-success-msg"
              );
              $(".mdfcart_success_msg").show();
              $(".mdfcart_success_msg").delay(6000).fadeOut();
              this.sateValChange("cartflg", "yes");
              this.setState({ cartTriggerFlg: "yes", product_remarks: "" });
              removePromoCkValue();
              return false;
            } else if (res.data.status === "error") {
              var errMsgtxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Sorry!. Product Detail was not valid.";
              $(".mdfcart_error_msg").html(errMsgtxt);
              $(".mdfcart_error_msg").show();
              $(".mdfcart_error_msg").delay(6000).fadeOut();
            }
          });
        }
      } else {
        $(".mdfcart_error_msg").html("Sorry!. Product Detail was not valid.");
        $(".mdfcart_error_msg").show();
        $(".mdfcart_error_msg").delay(6000).fadeOut();
        return false;
      }
    }
  }

  openPairedProduct(plink, event) {
    event.preventDefault();
    const { history } = this.props;
    $("#ProductDetailMdl").modal("hide");
    this.props.history.push(plink);
  }

  /* show combo product Details */
  comboProDetails(proDetailArr) {
    var comboArr =
      proDetailArr.set_menu_component !== null &&
      proDetailArr.set_menu_component !== ""
        ? proDetailArr.set_menu_component
        : Array();

    var imageSource = this.props.productcommon.image_source;
    var product_gallery_image_source =
      this.props.productcommon.product_gallery_image_source;

    if (Object.keys(comboArr).length > 0) {
      var minMaxSelect = parseInt(proDetailArr.product_apply_minmax_select);
      var compoTotalPrice =
        proDetailArr.product_specialprice_applicable === "yes"
          ? proDetailArr.product_special_price
          : proDetailArr.product_price;
      return (
        <div>
          <div className="prd_chosen_row compo_top_div">
            <div className="product_chosen_inner">
              <input
                type="hidden"
                id="set_menu_component_type"
                name="set_menu_component_type"
                value={minMaxSelect}
              />
              <input
                type="hidden"
                id="product_unitprice"
                name="product_unitprice"
                value={compoTotalPrice}
              />
              <input
                type="hidden"
                id="incr_compo_price"
                name="incr_compo_price"
                value={this.state.incrCompoPrice}
              />

              <div className="product_chosen_col common_compo_div">
                {minMaxSelect === 0 && this.singleComboDet(comboArr)}
                {minMaxSelect === 1 && this.multipleComboDet(comboArr)}
              </div>

              <div className="product_details_special_instruction">
                <h3>Special Instructions </h3>
                <p>
                  Let us know if you are allergic to anything or would like to
                  avoid anything.{" "}
                </p>
              </div>

              <div className="product_chosen_col product_chosen_col_right">
                <div className="text-box">
                  <textarea
                    placeholder="Eg: no Mayo"
                    name="special_notes"
                    id="special_notes"
                    maxLength="400"
                    value={this.state.product_remarks}
                    onChange={this.updateRemarksCompo.bind(this)}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          {proDetailArr.paired_products.length > 0 && (
            <div className="product_details_other_loves">
              <h3>Others love this with</h3>
              <div className="up-product-slider-modelpopup">
                <Slider {...productSlider}>
                  {proDetailArr.paired_products.map((item, index) => {
                    return (
                      <div
                        className="up-product-item"
                        onClick={this.openPairedProduct.bind(
                          this,
                          "/" +
                            this.props.productState.currentpage_group_url +
                            "/" +
                            this.props.productState.outlet_slug +
                            "/" +
                            item.cat_slug +
                            "/" +
                            item.subcate_slug +
                            "/" +
                            item.product_slug
                        )}
                      >
                        <div className="products-single-li">
                          <div className="product-info-div">
                            <div className="product-title-maindiv">
                              <a>
                                <div className="product-title">
                                  <h3>
                                    {item.product_alias !== ""
                                      ? stripslashes(item.product_alias)
                                      : stripslashes(item.product_name)}
                                  </h3>
                                </div>
                                <div className="product-tag-list"></div>
                              </a>
                            </div>

                            {item.product_short_description !== "" && (
                              <div className="product-short-description">
                                <p>
                                  {item.product_short_description !== ""
                                    ? item.product_short_description
                                    : Parser("&nbsp;")}
                                </p>
                              </div>
                            )}

                            <div className="product-price">
                              <h3>$ {item.product_price}</h3>
                            </div>
                          </div>
                          <div className="products-image-div">
                            {item.product_thumbnail !== "" ? (
                              <img
                                src={imageSource + "/" + item.product_thumbnail}
                              />
                            ) : (
                              <img src={ProductSlider1} />
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Slider>
              </div>
            </div>
          )}

          <div className="prd_chosen_sub_row">
            <p>
              <sup>$</sup>
              <span id="compoprice_final">{compoTotalPrice}</span>
            </p>
            <div className="prd_chosen_sub_col popup_addcart_cls compo_addcart_cls">
              <div className="addcart_row prd_chosen_sub_item_left cart_update_div compo_update_div">
                <div className="qty_bx">
                  <span
                    className="qty_minus"
                    onClick={this.compoQtyAction.bind(this, "decr")}
                  >
                    -
                  </span>
                  <input
                    type="text"
                    value={this.state.compoinput_value}
                    className="compo_proqty_input"
                    readOnly="1"
                  />
                  <span
                    className="qty_plus"
                    onClick={this.compoQtyAction.bind(this, "incr")}
                  >
                    +
                  </span>
                </div>
              </div>

              <div className="prd_chosen_sub_item_right cart_update_div compo_update_div">
                {proDetailArr.product_stock > 0 ||
                proDetailArr.product_stock === null ? (
                  this.state.shopeClosed === 0 ? (
                    <button
                      onClick={this.addToCartCombo.bind(
                        this,
                        proDetailArr,
                        "done"
                      )}
                    >
                      Add to Cart
                    </button>
                  ) : (
                    <span className="outlet-closed-error-msg">
                      Outlet is close. Please choose a different date and time
                    </span>
                  )
                ) : (
                  <button
                    className="button order_nowdiv disabled disbl_href_action"
                    title="Not Available"
                  >
                    Not Available
                  </button>
                )}
              </div>

              {/* <div className="cart-success-msg alert alert_success compocart_success_msg"></div>
              <div className="cart-error-msg alert alert_danger compocart_error_msg"></div> */}
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  singleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div compo_acc_active maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~0"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          key={"compo" + index1}
        >
          <div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>

          <div className="compo_acc_innerdiv" style={{ display: "none" }}>
            <div className="form-group custom-select-bxcls">
              {this.showSingleComboOptions(item)}
            </div>

            <div
              className="mdfr_list_divlcs"
              data-mismatchpro=""
              data-invcomboprice=""
            >
              <div
                className="mdfr_list_divlcs_error"
                style={{ display: "none", color: "red" }}
              >
                Please choose valid modifiers
              </div>
              {this.showSingleComboMdfr(item)}
            </div>
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  /* show single combo product option */
  showSingleComboOptions(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);
      var compSelectBoxHtml = "";
      var mdfMainComboProId = "";
      var ismdfrProCount = 0;
      var comboPropriceAply = "";
      var comboProPrice = 0;
      var indvlComponentNm = "";
      var showPricetxt = "";
      var compomainselval = "";

      $.each(compoListArr, function (index2, item1) {
        mdfMainComboProId = item1[0].product_id;
        ismdfrProCount =
          item1[0].modifiers !== null && item1[0].modifiers !== ""
            ? Object.keys(item1[0].modifiers).length
            : 0;
        comboProPrice = item1[0].product_price;
        comboPropriceAply =
          parseFloat(comboProPrice) > 0 &&
          parseInt(menuComponentApplyPrice) === 1 &&
          parseInt(ismdfrProCount) === 0
            ? comboProPrice
            : 0;
        indvlComponentNm =
          item1[0].product_alias !== ""
            ? stripslashes(item1[0].product_alias)
            : stripslashes(item1[0].product_name);

        showPricetxt =
          parseFloat(comboPropriceAply) > 0
            ? " ( + $" + parseFloat(comboPropriceAply).toFixed(2) + " )"
            : "";

        compomainselval =
          item1[0].product_id +
          "~" +
          indvlComponentNm +
          "~" +
          item1[0].product_sku +
          "~" +
          comboProPrice;

        compSelectBoxHtml +=
          "<option value='" +
          item1[0].product_id +
          "' data-compomainselval='" +
          compomainselval +
          "' data-mdfcombopro='" +
          mdfMainComboProId +
          "' data-combopropriceaply='" +
          comboPropriceAply +
          "' data-ismdfrprochk='" +
          ismdfrProCount +
          "' >" +
          indvlComponentNm +
          showPricetxt +
          "</option>";
      });

      var compSelectDropDown = Parser(compSelectBoxHtml);
      var statMdfVal = this.state["compo~~" + menuCmpId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultSelectVl;

      return (
        <select
          name="compomain_select"
          value={mdfSelectVl}
          className="components_selct components_selctbox_cls"
          onChange={this.handleChangeCompoMain.bind(this, menuCmpId)}
          id={"cmpp" + menuCmpId}
        >
          {compSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  showSingleComboMdfr(splProducts) {
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;
      var menuComponentNm = stripslashes(splProducts.menu_component_name);

      const html = compoListArr.map((item1, index2) => (
        <div className="mdfr_list_divlcs_inv" key={"cmdf-" + index2}>
          {item1[0].modifiers !== null &&
            item1[0].modifiers !== "" &&
            this.singleComboMdfrOption(
              menuCmpId,
              item1[0].product_id,
              item1[0].modifiers
            )}
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrOption(menuCmpIdTxt, productIdTxt, cmpProMdfr) {
    if (Object.keys(cmpProMdfr).length > 0) {
      const html = cmpProMdfr.map((item2, index3) => (
        <div
          className={
            "compoMdfr_item_left individual_combo_mdf compo_mdf_" + productIdTxt
          }
          key={"cmd-" + index3}
        >
          <div className="product_chosen_addons">
            <div className="product_chosen_hea">
              <h6>{stripslashes(item2.pro_modifier_name)}</h6>
            </div>
          </div>

          <div className="form-group custom-select-bxcls">
            <div className={"re_select re_select_cmd" + index3}>
              {this.singleComboMdfrValuesOpn(menuCmpIdTxt, item2)}
            </div>
          </div>
        </div>
      ));

      return html;
    } else {
      return "";
    }
  }

  singleComboMdfrValuesOpn(menuCmpIdTxt, mdfMainArr) {
    var mdfrValueArr =
      mdfMainArr.modifiers_values !== null && mdfMainArr.modifiers_values !== ""
        ? mdfMainArr.modifiers_values
        : Array();
    if (mdfrValueArr.length > 0) {
      var mainMdfId = mdfMainArr.pro_modifier_id;
      var mainMdfNm = stripslashes(mdfMainArr.pro_modifier_name);
      var mdfrSelectBoxHtml = "";
      var defaultMdfVal = "";
      $.each(mdfrValueArr, function (index4, item3) {
        var datamdfVl =
          mainMdfId +
          "~" +
          mainMdfNm +
          "~" +
          item3.pro_modifier_value_id +
          "~" +
          stripslashes(item3.pro_modifier_value_name) +
          "~" +
          item3.pro_modifier_value_price;

        var incPrc =
          parseFloat(item3.pro_modifier_value_price) > 0
            ? " ( + $" + item3.pro_modifier_value_price + " )"
            : "";

        mdfrSelectBoxHtml +=
          "<option value='" +
          item3.pro_modifier_value_id +
          "' data-selectmdfval='" +
          datamdfVl +
          "' data-mdfrpricevaluetxt='" +
          item3.pro_modifier_value_price +
          "' >" +
          item3.pro_modifier_value_name +
          incPrc +
          "</option>";
        if (item3.pro_modifier_value_is_default === "Yes") {
          defaultMdfVal = item3.pro_modifier_value_id;
        }
      });
      var mdfrSelectDropDown = Parser(mdfrSelectBoxHtml);
      var statMdfVal =
        this.state["compoInner~~" + menuCmpIdTxt + "~~" + mainMdfId];
      var mdfSelectVl =
        statMdfVal !== undefined && statMdfVal !== ""
          ? statMdfVal
          : defaultMdfVal;

      return (
        <select
          name="compoinner_select"
          value={mdfSelectVl}
          className="components_mdf_selct"
          onChange={this.handleChangeCompoInner.bind(
            this,
            menuCmpIdTxt,
            mainMdfId
          )}
          id={"modifvl" + mainMdfId}
        >
          {mdfrSelectDropDown}
        </select>
      );
    } else {
      return "";
    }
  }

  multipleComboDet(compoProDetailArr) {
    if (Object.keys(compoProDetailArr).length > 0) {
      const html = compoProDetailArr.map((item, index1) => (
        <div
          className={
            "product_chosen_item_left product_chosen_item_left_full compo_list_div main_combo_div minmax_maincombo_div compo_acc_active compopro_acc_head maincombo-" +
            item.menu_component_id
          }
          data-maincomboidtxt={item.menu_component_id}
          data-combodata={
            item.menu_component_id + "~" + item.menu_component_name + "~1"
          }
          data-combopriceapply={
            item.menu_component_apply_price !== ""
              ? item.menu_component_apply_price
              : 0
          }
          data-minselectcombo={item.menu_component_min_select}
          data-maxselectcombo={item.menu_component_max_select}
          data-modifierapply={item.menu_component_modifier_apply}
          key={"compo" + index1}
        >
          {/*<div className="product_chosen_addons compo_pro_acc compo_acc_action">
            <div className="product_chosen_hea compopro_acc_head">
              <h6>{stripslashes(item.menu_component_name)}</h6>
            </div>
          </div>*/}

          <h6>{stripslashes(item.menu_component_name)}</h6>

          <div className="compo_acc_innerdiv">
            {item.menu_component_modifier_apply === "1" ? (
              <div className="compo_mdfselect_maindiv">
                <div className="form-group custom-select-bxcls">
                  {this.showSingleComboOptions(item)}
                </div>

                <div
                  className="mdfr_list_divlcs"
                  data-mismatchpro=""
                  data-invcomboprice=""
                >
                  <div
                    className="mdfr_list_divlcs_error"
                    style={{ display: "none", color: "red" }}
                  >
                    Please choose valid modifiers
                  </div>
                  {this.showSingleComboMdfr(item)}
                </div>
              </div>
            ) : (
              <div className="compo_minmax_maindiv">
                <div className="max-min-bar">
                  {"You've chosen"} <span className="minSelectCls">0</span>{" "}
                  (Min. {item.menu_component_min_select} & Max.{" "}
                  {item.menu_component_max_select}){" "}
                </div>

                <div className="error_combo_div">
                  {" "}
                  Please select the min number of items.{" "}
                </div>

                {this.showMultipleComboOptions(item)}
              </div>
            )}
          </div>
        </div>
      ));

      return (
        <div className="product_chosen_col_inner compo_inner_main">{html}</div>
      );
    } else {
      return "";
    }
  }

  showComboMultiSelect(mutilSlct) {
    return mutilSlct === 1 ? "none" : "";
  }

  /* show multiple combo product option */
  showMultipleComboOptions(splProducts) {
    var multiSelectApply =
      splProducts.menu_component_multipleselection_apply !== ""
        ? parseInt(splProducts.menu_component_multipleselection_apply)
        : 0;
    var compoListArr =
      splProducts.product_details !== null && splProducts.product_details !== ""
        ? splProducts.product_details
        : Array();
    var menuComponentApplyPrice =
      splProducts.menu_component_apply_price !== ""
        ? splProducts.menu_component_apply_price
        : 0;
    if (compoListArr.length > 0) {
      var defaultSelectVl = splProducts.menu_component_default_select;
      var menuSetCmpId = splProducts.menu_set_component_id;
      var menuCmpId = splProducts.menu_component_id;

      const compoMtplHtml = compoListArr.map((item1, index1) => (
        <div
          className={
            "chosen_adn mdfr_list_divlcs individual_combo_pro indvcombo-" +
            menuCmpId +
            "-" +
            item1[0].product_primary_id
          }
          data-mismatchpro=""
          data-invcomboprice="0"
          data-comboprice={item1[0].product_price}
          data-productdata={this.getCmpProData(item1)}
          key={index1}
        >
          <div className="bb-txt2 margin-15 chosen_adn_left">
            <span>
              {item1[0].product_alias !== ""
                ? stripslashes(item1[0].product_alias)
                : stripslashes(item1[0].product_name)}
            </span>{" "}
            <span className="combo_pro_price" style={{ display: "none" }}>
              {parseFloat(item1[0].product_price) > 0
                ? "(+$" + item1[0].product_price + " )"
                : ""}
            </span>
          </div>
          <div
            className="radio pull-right combo-inc-parent chosen_adn_right"
            style={{ display: this.showComboMultiSelect(multiSelectApply) }}
          >
            <div className="qty_bx">
              <span
                className="qty_minus combo_inc"
                onClick={this.decComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                -
              </span>

              <label
                type="text"
                disabled
                data-qtyval="0"
                className="combo-input-label combo-input combolst_qty_value"
              >
                0
              </label>

              <span
                className="qty_plus combo_dec"
                onClick={this.incComboQty.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
              >
                +
              </span>
            </div>
          </div>

          {multiSelectApply === 1 && (
            <div className="checkboxcls pull-right custom_checkbox">
              <input
                className="css-checkboxcls"
                type="checkbox"
                onChange={this.comboMultiSelectUpdate.bind(
                  this,
                  menuCmpId,
                  item1[0].product_primary_id
                )}
                value={item1[0].product_price}
                name={"comboMultiStVal_" + menuCmpId}
                id={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
              />{" "}
              <label
                htmlFor={
                  "comboMultiStVal_" +
                  menuCmpId +
                  "_" +
                  item1[0].product_primary_id
                }
                className="css-label-chkbox"
              ></label>
            </div>
          )}
        </div>
      ));

      return <div>{compoMtplHtml}</div>;
    } else {
      return "";
    }
  }

  getCmpProData(proData) {
    var proName =
      proData[0].product_alias !== ""
        ? stripslashes(proData[0].product_alias)
        : stripslashes(proData[0].product_name);
    var pro_datetxt =
      proData[0].product_id +
      "~" +
      proName +
      "~" +
      proData[0].product_sku +
      "~" +
      proData[0].product_price;
    return pro_datetxt;
  }

  comboMultiSelectUpdate(menuCmpId, productPryId) {
    if (
      $("#comboMultiStVal_" + menuCmpId + "_" + productPryId).prop("checked") ==
      true
    ) {
      this.incComboQty(menuCmpId, productPryId, "checkboxact");
    } else {
      this.decComboQty(menuCmpId, productPryId);
    }
  }

  incComboQty(menuCmpId, proId, actionFrm) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";
    var invCompoQty = this.getInvCompoQty($_this);

    if (
      actionFrm === "checkboxact" &&
      parseInt(invCompoQty) >= parseInt(maxselectcombo)
    ) {
      $("#comboMultiStVal_" + menuCmpId + "_" + proId).prop("checked", false);
      return false;
    }

    var chk_val = 0;
    if (!isNaN(intValInc) && parseInt(invCompoQty) < parseInt(maxselectcombo)) {
      intValInc = parseInt(intValInc + 1);
      chk_val = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);

    var comboProPrice = $_this.attr("data-invcomboprice");
    var invCompoQty = this.getInvCompoQty($_this);
    if (
      (invCompoQty > parseInt(minselectcombo) ||
        parseInt(combopriceapply) == 1) &&
      chk_val == 1
    ) {
      $_this.find(".combo_pro_price").show();
      var invComboproPrice = $_this.attr("data-invcomboprice");
      var comboprice = $_this.attr("data-comboprice");
      comboProPrice = parseFloat(invComboproPrice) + parseFloat(comboprice);
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }
    $_this.attr("data-invcomboprice", comboProPrice);
    this.updateProductPricefun();
  }

  decComboQty(menuCmpId, proId) {
    var $_this = $(".indvcombo-" + menuCmpId + "-" + proId);
    var intValInc = $_this.find(".combolst_qty_value").attr("data-qtyval");
    intValInc = intValInc !== "" ? parseInt(intValInc) : 0;
    var minselectcombo =
      $_this.closest(".main_combo_div").attr("data-minselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-minselectcombo")
        : "0";
    var maxselectcombo =
      $_this.closest(".main_combo_div").attr("data-maxselectcombo") != ""
        ? $_this.closest(".main_combo_div").attr("data-maxselectcombo")
        : "0";
    var combopriceapply =
      $_this.closest(".main_combo_div").attr("data-combopriceapply") != ""
        ? $_this.closest(".main_combo_div").attr("data-combopriceapply")
        : "0";

    var minusChkVal = 0;
    if (!isNaN(intValInc) && parseInt(intValInc) >= 1) {
      var intValInc = parseInt(intValInc - 1);
      minusChkVal = 1;
    }

    $_this.find(".combolst_qty_value").attr("data-qtyval", intValInc);
    $_this.find(".combolst_qty_value").html(intValInc);
    var invCompoQty = this.getInvCompoQty($_this);
    var comboProPrice = $_this.attr("data-invcomboprice");
    var defComboprice = $_this.attr("data-comboprice");
    if (
      (invCompoQty >= parseInt(minselectcombo) && minusChkVal == 1) ||
      (parseInt(combopriceapply) == 1 && minusChkVal == 1)
    ) {
      if (
        parseInt(combopriceapply) == 1 &&
        minusChkVal == 1 &&
        parseFloat(defComboprice) == 0 &&
        parseFloat(comboProPrice) == 0
      ) {
        var temp_price = 0;
        $_this.attr("data-invcomboprice", temp_price);
        $_this.find(".combo_pro_price").hide();
      } else if (
        parseFloat(comboProPrice) >= parseFloat(defComboprice) &&
        parseFloat(comboProPrice) > 0
      ) {
        var temp_price = parseFloat(comboProPrice) - parseFloat(defComboprice);
        $_this.attr("data-invcomboprice", temp_price);
        if (parseFloat(temp_price) == 0) {
          $_this.find(".combo_pro_price").hide();
        }
      } else {
        var rtn_val = 0;
        $_this
          .closest(".main_combo_div")
          .find(".individual_combo_pro")
          .each(function () {
            var thisInvPrc = $(this).attr("data-invcomboprice");
            if (parseFloat(thisInvPrc) > 0 && rtn_val == 0) {
              rtn_val = 1;
              var comboproprice = thisInvPrc;
              var def_combo_price = $(this).attr("data-comboprice");
              var tempPrice =
                parseFloat(comboproprice) - parseFloat(def_combo_price);
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) > 0) {
                tempPrice = tempPrice;
              } else {
                tempPrice = 0;
              }
              $(this).attr("data-invcomboprice", tempPrice);
              if (parseFloat(tempPrice) == 0) {
                $(this).find(".combo_pro_price").hide();
              }
            }
          });
      }
    }

    if (parseInt(minselectcombo) == 0 || parseInt(combopriceapply) == 1) {
      $_this.find(".combo_pro_price").show();
    }

    this.updateProductPricefun();
  }

  getInvCompoQty($_this) {
    $_this.closest(".main_combo_div").find(".error_combo_div").hide();
    var combolst_qty = 0;
    $_this
      .closest(".main_combo_div")
      .find(".combolst_qty_value")
      .each(function () {
        combolst_qty += parseInt($(this).attr("data-qtyval"));
      });
    return combolst_qty;
  }

  updateRemarksCompo(event) {
    this.setState({ compoApi_call: "No", product_remarks: event.target.value });
    this.setState({ remaining: 30 - event.target.value.length });
  }

  updateProductPricefun() {
    var minmaxMainCnt = $(".compo_minmax_maindiv").length;
    if (minmaxMainCnt > 0) {
      $(".compo_minmax_maindiv").each(function (indx) {
        var invQtyCnt = 0;
        $(this)
          .find(".individual_combo_pro")
          .each(function (indx2) {
            var qtyval = $(this)
              .find(".combolst_qty_value")
              .attr("data-qtyval");
            invQtyCnt = parseInt(invQtyCnt) + parseInt(qtyval);
          });
        $(this).find(".minSelectCls").html(invQtyCnt);
      });
    }

    var combo_pro_price = 0;
    $(".mdfr_list_divlcs").each(function () {
      var invcomboPriceVl = $(this).attr("data-invcomboprice");
      invcomboPriceVl = invcomboPriceVl !== "" ? invcomboPriceVl : 0;
      combo_pro_price += parseFloat(invcomboPriceVl);
    });

    var qty_txt =
      $(".compo_proqty_input").val() != ""
        ? parseInt($(".compo_proqty_input").val())
        : 0;
    var pro_price_val =
      $("#product_unitprice").val() != ""
        ? parseFloat($("#product_unitprice").val())
        : 0;

    var exc_price = parseFloat(pro_price_val) + parseFloat(combo_pro_price);
    exc_price = parseInt(qty_txt) * parseFloat(exc_price);

    $("#incr_compo_price").val(exc_price);
    $("#compoprice_final").html(parseFloat(exc_price).toFixed(2));
  }

  compoQtyAction(actionFlg) {
    var proqtyInput = $(".compo_proqty_input").val();
    proqtyInput = parseInt(proqtyInput);
    if (actionFlg === "decr") {
      proqtyInput = proqtyInput > 1 ? proqtyInput - 1 : proqtyInput;
    } else {
      proqtyInput = proqtyInput + 1;
    }

    this.setState({ compoApi_call: "No", compoinput_value: proqtyInput });
  }

  handleChangeCompoMain(compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compo~~" + compoVl]: event.target.value,
    });
  }

  handleChangeCompoInner(menuCmpIdTxt, compoVl, event) {
    this.setState({
      compoApi_call: "Yes",
      ["compoInner~~" + menuCmpIdTxt + "~~" + compoVl]: event.target.value,
    });
  }

  getComboproData($_this, CompoType) {
    var comboproSet = Array(),
      comboproMdf = Array(),
      aplypriceZero = 0;
    var current = this;
    if (CompoType === 1) {
      $_this.find(".individual_combo_pro").each(function () {
        var componentsProDet = $(this).attr("data-productdata");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = $(this)
          .find(".combolst_qty_value")
          .attr("data-qtyval");
        var comboProInvPrice = $(this).attr("data-invcomboprice");
        if (parseInt(combolstQtyValue) > 0) {
          comboproSet.push({
            product_id: compoProDet[0],
            outlet_id: current.state.outlet_id,
            product_name: compoProDet[1],
            product_sku: compoProDet[2],
            product_price: comboProInvPrice,
            product_qty: combolstQtyValue,
            modifiers: comboproMdf,
          });
        }
      });
    } else {
      $_this.find(".components_selct").each(function () {
        var mdfcombopro_id = $(this).find(":selected").attr("data-mdfcombopro");
        var combopriceapplychk = $(this)
          .closest(".main_combo_div")
          .attr("data-combopriceapply");
        var aplyprice_temp = $(this)
          .find(":selected")
          .attr("data-combopropriceaply");
        var comboMdfSet = Array();
        $(this)
          .closest(".main_combo_div")
          .find(".compo_mdf_" + mdfcombopro_id)
          .each(function () {
            var combopro_mdf_txt = $(this).find(".components_mdf_selct").val();
            var comboMdfValueSet = Array();
            if (combopro_mdf_txt !== "") {
              var modifierCombosets_txt = $(this)
                .find(".components_mdf_selct")
                .find("option:selected")
                .attr("data-selectmdfval");
              var mdfSetDet =
                modifierCombosets_txt !== "" &&
                modifierCombosets_txt !== undefined
                  ? modifierCombosets_txt.split("~")
                  : Array();

              if (Object.keys(mdfSetDet).length > 0) {
                comboMdfValueSet.push({
                  modifier_value_name: mdfSetDet[3],
                  modifier_value_id: mdfSetDet[2],
                  modifier_value_price: mdfSetDet[4],
                  modifier_value_qty: 1,
                });
                comboMdfSet.push({
                  modifier_name: mdfSetDet[1],
                  modifier_id: mdfSetDet[0],
                  modifiers_values: comboMdfValueSet,
                });
              }
            }
          });

        var componentsProDet = $(this)
          .find("option:selected")
          .attr("data-compomainselval");
        var compoProDet = componentsProDet.split("~");
        var combolstQtyValue = 1;
        var comboProInvPrice =
          parseFloat(combopriceapplychk) > 0
            ? parseFloat(aplyprice_temp)
            : parseFloat(aplypriceZero);
        comboproSet.push({
          product_id: compoProDet[0],
          outlet_id: current.state.outlet_id,
          product_name: compoProDet[1],
          product_sku: compoProDet[2],
          product_price: comboProInvPrice,
          product_qty: combolstQtyValue,
          modifiers: comboMdfSet,
        });
      });
    }

    return comboproSet;
  }

  checkMinValfun($_this) {
    var combo_qtycount = 0,
      min_val_error = 0;
    var min_selectcombo =
      $_this.attr("data-minselectcombo") != ""
        ? $_this.attr("data-minselectcombo")
        : "0";
    $_this.find(".combolst_qty_value").each(function () {
      var qtyval = $(this).attr("data-qtyval");
      combo_qtycount += parseInt(qtyval);
    });
    if (parseInt(min_selectcombo) > parseInt(combo_qtycount)) {
      min_val_error = 1;
    }
    return min_val_error;
  }

  addToCartCombo(compoProdDetail, actionFlg) {
    var cartOutletList = this.state.cartOutletList;
    var orderDate =
      cookie.load("deliveryDate") !== "" &&
      typeof cookie.load("deliveryDate") !== undefined &&
      typeof cookie.load("deliveryDate") !== "undefined"
        ? cookie.load("deliveryDate")
        : "";
    var deliveryTime =
      cookie.load("deliveryTime") !== "" &&
      typeof cookie.load("deliveryTime") !== undefined &&
      typeof cookie.load("deliveryTime") !== "undefined"
        ? cookie.load("deliveryTime")
        : "";
    if (
      this.state.defaultAvilTy === "" ||
      orderDate === "" ||
      deliveryTime === ""
    ) {
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
      return false;
    }
    if (
      cartOutletList.length >= parseInt(this.state.settings.max_order_handle)
    ) {
      if (cartOutletList.indexOf(this.state.outlet_id) < 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-max-orderpopup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var InvalidMdfrCompo = "No";
    $(".main_combo_div").each(function () {
      if ($(this).find(".mdfr_list_divlcs").attr("data-mismatchpro") == "1") {
        InvalidMdfrCompo = "Yes";
      }
    });

    if (InvalidMdfrCompo == "No") {
      if (actionFlg === "initial") {
        $(".compo_add_div").hide();
        $(".compo_update_div").show();
        return false;
      } else {
        var $_react_this = this;
        var menuSet = Array();
        var productDetailsMain = Array();
        var modifier = Array();
        var CompoType = $("#set_menu_component_type").val();
        CompoType = parseInt(CompoType);
        var compo_errors = "0";

        if (CompoType === 1) {
          $(".main_combo_div").each(function () {
            var modifierapply = $(this).attr("data-modifierapply");
            if (modifierapply === "1") {
              var combodata_txt = $(this).attr("data-combodata");
              var menu_component = combodata_txt.split("~");
              var productDetails = $_react_this.getComboproData($(this), 0);
              productDetailsMain.push({
                menu_component_id: menu_component[0],
                menu_component_name: menu_component[1],
                product_details: productDetails,
                min_max_flag: menu_component[2],
              });
            } else {
              var check_min_val = $_react_this.checkMinValfun($(this));
              if (check_min_val == 0) {
                var combodata_txt = $(this).attr("data-combodata");
                menu_component = combodata_txt.split("~");
                var productDetails = $_react_this.getComboproData(
                  $(this),
                  CompoType
                );
                productDetailsMain.push({
                  menu_component_id: menu_component[0],
                  menu_component_name: menu_component[1],
                  product_details: productDetails,
                  min_max_flag: menu_component[2],
                });
              } else {
                compo_errors = "1";
                $(this).find(".compo_acc_innerdiv").show();
                $(this).find(".error_combo_div").show();
                return false;
              }
            }
          });
        } else {
          $(".main_combo_div").each(function () {
            var combodata_txt = $(this).attr("data-combodata");
            var menu_component = combodata_txt.split("~");
            var productDetails = $_react_this.getComboproData(
              $(this),
              CompoType
            );
            productDetailsMain.push({
              menu_component_id: menu_component[0],
              menu_component_name: menu_component[1],
              product_details: productDetails,
              min_max_flag: menu_component[2],
            });
          });
        }
        menuSet = productDetailsMain;

        if (compo_errors == "0" && Object.keys(menuSet).length > 0) {
          showLoader("compo_addcart_cls", "class");
          var prCommon = this.props.productcommon;

          var productId = compoProdDetail.product_id;
          var productName =
            compoProdDetail.product_alias !== ""
              ? stripslashes(compoProdDetail.product_alias)
              : stripslashes(compoProdDetail.product_name);
          var productImage =
            compoProdDetail.product_thumbnail !== ""
              ? prCommon.image_source + "/" + compoProdDetail.product_thumbnail
              : "";
          var productSku = compoProdDetail.product_sku;
          var productSlug = compoProdDetail.product_slug;

          var productRemarks = this.state.product_remarks;
          var prodcutQty = this.state.compoinput_value;
          var incrCompoPrice = $("#incr_compo_price").val();

          var totalCompoPrice =
            incrCompoPrice !== "" ? parseFloat(incrCompoPrice) : 0;
          var unitProductPrice =
            parseFloat(totalCompoPrice) / parseFloat(prodcutQty);
          unitProductPrice = unitProductPrice.toFixed(2);

          var availabilityId = cookie.load("defaultAvilablityId");
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");

          if (
            parseFloat(totalCompoPrice) > 0 &&
            parseFloat(unitProductPrice) > 0
          ) {
            var postObject = {};
            postObject = {
              app_id: appId,
              product_id: productId,
              outlet_id: this.state.outlet_id,
              outletLocationId: this.state.outletLocationId,
              product_qty: prodcutQty,
              availability_id: availabilityId,
              product_name: productName,
              product_type: compoProdDetail.product_type,
              product_total_price: totalCompoPrice,
              product_unit_price: unitProductPrice,
              product_remarks: productRemarks,
              product_image: productImage,
              product_sku: productSku,
              product_slug: productSlug,
              modifiers: JSON.stringify(modifier),
              menu_set_component: JSON.stringify(menuSet),
              individual: "yes",
              customer_id: customerId,
              reference_id: customerId === "" ? getReferenceID() : "",
              product_edit_enable: "No",
              shopType: this.state.shopType,
              outletzone_id: this.state.orderZoneId,
              cart_id:
                cookie.load("cart_id") !== "" &&
                typeof cookie.load("cart_id") !== undefined &&
                typeof cookie.load("cart_id") !== "undefined"
                  ? cookie.load("cart_id")
                  : "",
              cart_group_id:
                cookie.load("cart_group_id") !== "" &&
                typeof cookie.load("cart_group_id") !== undefined &&
                typeof cookie.load("cart_group_id") !== "undefined"
                  ? cookie.load("cart_group_id")
                  : "",
            };

            Axios.post(apiUrl + "cart/insert", qs.stringify(postObject)).then(
              (res) => {
                hideLoader("compo_addcart_cls", "class");
                if (res.data.status === "ok") {
                  var outlet_name = this.state.orderOutletName;
                  window.dataLayer.push({
                    event: "addToCart",
                    ecommerce: {
                      currencyCode: "SGD",
                      add: {
                        products: [
                          {
                            name: stripslashes(productName),
                            id: productId,
                            price: parseFloat(totalCompoPrice).toFixed(2),
                            brand: stripslashes(outlet_name),
                            category: stripslashes(
                              compoProdDetail.catgory_name
                            ),
                            variant: "",
                            quantity: prodcutQty,
                          },
                        ],
                      },
                    },
                  });

                  $(".common-modal-head .close").trigger("click");
                  showCustomAlert(
                    "success",
                    "Great! Your item has been added to the cart",
                    "cart-success-msg"
                  );
                  // $(".compo_update_div").hide();
                  $(".compo_add_div").show();

                  this.sateValChange("cartflg", "yes");
                  this.setState({
                    cartTriggerFlg: "yes",
                    product_remarks: "",
                    compoinput_value: 1,
                  });
                  removePromoCkValue();
                  var currentThis = this;
                  cookie.save(
                    "site_location",
                    currentThis.props.match.params.siteLocation,
                    { path: "/" }
                  );
                  cookie.save(
                    "slug_restaurant",
                    currentThis.props.match.params.slugRestaurant,
                    { path: "/" }
                  );
                  setTimeout(function () {
                    currentThis.props.history.push(
                      "/food/" +
                        currentThis.props.match.params.siteLocation +
                        "/" +
                        currentThis.props.match.params.slugRestaurant
                    );
                  }, 1000);
                  return false;
                } else if (res.data.status === "error") {
                  var errMsgtxt =
                    res.data.message !== ""
                      ? res.data.message
                      : "Product Detail was not valid.";
                  $(".compocart_error_msg").html(errMsgtxt);
                  $(".compocart_error_msg").show();
                  if (
                    $("#ProductDetailMdl .error_combo_div:visible").length > 0
                  ) {
                    var sectionOffset = $(
                      "#ProductDetailMdl .error_combo_div:visible:first"
                    ).position();

                    $("#ProductDetailMdl").animate(
                      {
                        scrollTop: sectionOffset.top - 100,
                      },
                      "slow"
                    );
                  }
                  $(".compocart_error_msg").delay(6000).fadeOut();
                }
              }
            );
          } else {
            hideLoader("compo_addcart_cls", "class");
            $(".compocart_error_msg").html(
              "Sorry!. Product price was not valid."
            );
            $(".compocart_error_msg").show();
            if ($("#ProductDetailMdl .error_combo_div:visible").length > 0) {
              var sectionOffset = $(
                "#ProductDetailMdl .error_combo_div:visible:first"
              ).position();

              $("#ProductDetailMdl").animate(
                {
                  scrollTop: sectionOffset.top - 100,
                },
                "slow"
              );
            }
            $(".compocart_error_msg").delay(6000).fadeOut();
            return false;
          }
        } else {
          var check_length = $(".error_combo_div:visible").length;

          $(".compocart_error_msg").html(
            "Sorry!. Product Detail was not valid."
          );
          $(".compocart_error_msg").show();

          var check_length = $(
            "#ProductDetailMdl .error_combo_div:visible"
          ).length;

          if ($("#ProductDetailMdl .error_combo_div:visible").length > 0) {
            var sectionOffset_first = $(
              ".error_combo_div:visible:first"
            ).position();

            var sectionOffset = $(
              "#ProductDetailMdl .error_combo_div:visible:first"
            ).position();

            $("#ProductDetailMdl").animate(
              {
                scrollTop: sectionOffset.top + sectionOffset_first.top,
              },
              "slow"
            );
          }
          $(".compocart_error_msg").delay(6000).fadeOut();
          return false;
        }
      }
    } else {
      $(".compocart_error_msg").html("Sorry!. Invalid product combination.");
      $(".compocart_error_msg").show();
      $(".compocart_error_msg").delay(6000).fadeOut();
      return false;
    }
  }

  checkProductPrice() {
    var allModVal = "";
    var errorChk = 0;
    var productID = $("#modProductId").val();
    var inc_lp = 1;
    var TotalCnt = $(".modifierList").length;

    $(".modfir_addcart_cls").show();
    showLoader("modfir_addcart_cls", "class");

    $(".modifierList").each(function () {
      var modVal = $(this).val();
      var modSelectVal = $(this).find("option:selected").attr("data-mdfvl");
      if (modVal != "" && modSelectVal != "") {
        var modifier_sets = modSelectVal.split("~");
        allModVal +=
          inc_lp == TotalCnt ? modifier_sets[3] : modifier_sets[3] + ";";
      } else if (modSelectVal == "" || modVal == "") {
        errorChk = 1;
      }
      inc_lp++;
    });

    if (errorChk === 0 && allModVal != "") {
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          productID +
          "&modifier_value_id=" +
          allModVal
      ).then((res) => {
        var response = res.data;
        hideLoader("modfir_addcart_cls", "class");
        if (response.status === "ok") {
          var proQty = $(".modfir_proqty_input").val();
          var productPrice = response.result_set[0].product_price;
          var productTotalPrice =
            parseFloat(response.result_set[0].product_price) *
            parseFloat(proQty);
          productTotalPrice = parseFloat(productTotalPrice).toFixed(2);
          /*$("#modParentProductId").val(response.result_set[0].alias_product_primary_id);
          $("#modProductPrice").val(productPrice);
          $("#modProductTotalPrice").val(productTotalPrice);
          $('#id_price_final').html(productTotalPrice);*/
          this.setState({
            mdfApi_call: "No",
            modParentProductId: response.result_set[0].alias_product_primary_id,
            modProductPrice: productPrice,
            modProductTotalPrice: productTotalPrice,
          });
        } else {
          $(".modfir_addcart_cls").hide();
          $("#modErrorDiv").show();
          $("#modErrorDiv").delay(6000).fadeOut();
        }
      });
    }
  }

  setModifierValFun($_this) {
    var mdfcombopro = $_this.find(":selected").attr("data-mdfcombopro");
    $_this.closest(".main_combo_div").find(".individual_combo_mdf").hide();
    $_this
      .closest(".main_combo_div")
      .find(".compo_mdf_" + mdfcombopro)
      .show();
  }

  setOverallSubmdfrPrice($_this) {
    var mainmdfrid = $_this.find(":selected").attr("data-mdfcombopro");
    var ismdfrprochk = $_this.find(":selected").attr("data-ismdfrprochk");
    var combopriceapplychk = $_this
      .closest(".main_combo_div")
      .attr("data-combopriceapply");
    var maincomboidtxt = $_this
      .closest(".main_combo_div")
      .attr("data-maincomboidtxt");
    var mdfrpricevaluetxt_val = 0,
      inv_comopo_mismatch_pro = "";

    if (parseFloat(ismdfrprochk) > 0) {
      if (this.state.compoApi_call === "Yes") {
        var rtrn_msg = this.checkModifierPricefun(
          $_this,
          maincomboidtxt,
          mainmdfrid
        );
      }
    } else {
      if (parseFloat(combopriceapplychk) > 0) {
        var aplyprice_temp = $_this
          .find(":selected")
          .attr("data-combopropriceaply");
        mdfrpricevaluetxt_val = parseFloat(aplyprice_temp);
      }
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-invcomboprice", mdfrpricevaluetxt_val);
      $_this
        .closest(".main_combo_div")
        .find(".mdfr_list_divlcs")
        .attr("data-mismatchpro", inv_comopo_mismatch_pro);
      $_this.closest(".main_combo_div").find(".mdfr_list_divlcs_error").hide();
    }

    this.checkModifierErrorfun();
  }

  checkModifierPricefun($_this, maincomboidtxt, mdfcombopro_id) {
    var returntxt_msg = "";
    var sub_mdfr_ids = "";
    $(".maincombo-" + maincomboidtxt)
      .find(".compo_mdf_" + mdfcombopro_id)
      .each(function () {
        var modVal = $(this).find(".components_mdf_selct").val();
        if (modVal !== "") {
          var modifier_combosets_txt = $(this)
            .find(".components_mdf_selct")
            .find("option:selected")
            .attr("data-selectmdfval");
          var modifier_combosets =
            modifier_combosets_txt !== "" &&
            modifier_combosets_txt !== undefined
              ? modifier_combosets_txt.split("~")
              : new Array();
          if (modifier_combosets.length >= 2) {
            if (modifier_combosets[2]) {
              if (sub_mdfr_ids != "") {
                sub_mdfr_ids += ";";
              }
              sub_mdfr_ids = sub_mdfr_ids + modifier_combosets[2];
            }
          }
        }
      });

    if (sub_mdfr_ids !== "") {
      showLoader("compo_addcart_cls", "class");
      Axios.get(
        apiUrl +
          "products/validate_product?app_id=" +
          appId +
          "&product_id=" +
          mdfcombopro_id +
          "&modifier_value_id=" +
          sub_mdfr_ids
      ).then((res) => {
        var response = res.data;
        if (response.status === "ok") {
          var tempval = "";
          this.updateIndvModifrprice(
            $_this
              .closest(".main_combo_div")
              .find(".compo_mdf_" + mdfcombopro_id + ":first")
          );
        } else {
          var tempval = "1";
        }
        $_this
          .closest(".main_combo_div")
          .find(".mdfr_list_divlcs")
          .attr("data-mismatchpro", tempval);
        this.checkModifierErrorfun();
        hideLoader("compo_addcart_cls", "class");
      });
    }

    return returntxt_msg;
  }

  updateIndvModifrprice($_this) {
    var mdfrpricevaluetxt_val = 0;
    $_this
      .closest(".mdfr_list_divlcs_inv")
      .find(".components_mdf_selct")
      .each(function () {
        var mdfrpricevaluetxt = $(this)
          .find(":selected")
          .attr("data-mdfrpricevaluetxt");
        mdfrpricevaluetxt =
          mdfrpricevaluetxt !== "" && mdfrpricevaluetxt !== undefined
            ? parseFloat(mdfrpricevaluetxt)
            : 0;
        mdfrpricevaluetxt_val =
          parseFloat(mdfrpricevaluetxt_val) + parseFloat(mdfrpricevaluetxt);
      });
    $_this
      .closest(".mdfr_list_divlcs")
      .attr("data-invcomboprice", mdfrpricevaluetxt_val);
  }

  checkModifierErrorfun() {
    var over_allerror = "";
    $(".mdfr_list_divlcs").each(function () {
      if ($(this).attr("data-mismatchpro") == "1") {
        over_allerror = "1";
        $(this).find(".mdfr_list_divlcs_error").show();
      } else {
        $(this).find(".mdfr_list_divlcs_error").hide();
      }
    });

    /*$('#comopo_mismatch_pro').val(over_allerror);*/

    this.updateProductPricefun();

    if (over_allerror == "1") {
      $(".compo_addcart_cls").hide();
    } else {
      $(".compo_addcart_cls").show();
    }
  }

  componentDidUpdate() {
    var TotalCnt = $(".modifierList").length;
    var modProductSlug = $("#modProductSlug").val();
    if (
      TotalCnt > 0 &&
      this.state.mdfApi_call === "Yes" &&
      this.state.selectedProSlug === modProductSlug
    ) {
      this.checkProductPrice();
    }

    var $_reactThis = this;
    var individualComboCnt = $(".individual_combo_mdf").length;
    if (individualComboCnt > 0) {
      $(".main_combo_div").each(function () {
        $_reactThis.setModifierValFun($(this).find(".components_selct"));
      });

      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function () {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }

    var indlMinMxComboCnt = $(".individual_combo_pro").length;

    if (indlMinMxComboCnt > 0) {
      $(".main_combo_div").each(function () {
        var minselectcombo_txt =
          $(this).data("minselectcombo") != ""
            ? $(this).data("minselectcombo")
            : "0";
        var combopriceapply_txt =
          $(this).data("combopriceapply") != ""
            ? $(this).data("combopriceapply")
            : "0";
        if (
          parseInt(minselectcombo_txt) === 0 ||
          parseInt(combopriceapply_txt) === 1
        ) {
          $(this).find(".combo_pro_price").show();
        } else {
          //  $(this).find(".combo_pro_price").hide();
        }
      });
    }

    var minmaxMainCnt = $(".minmax_maincombo_div").length;
    var chkAplyModfInMinmax = 0;
    if (minmaxMainCnt > 0) {
      chkAplyModfInMinmax = $(".minmax_maincombo_div").find(
        ".components_selctbox_cls"
      ).length;
      this.updateProductPricefun();
    }

    var singleSelectCompo = $(".components_selctbox_cls").length;
    if (
      singleSelectCompo > 0 &&
      ((minmaxMainCnt === 0 &&
        indlMinMxComboCnt === 0 &&
        individualComboCnt === 0) ||
        chkAplyModfInMinmax > 0)
    ) {
      if ($(".components_selct").length > 0) {
        $(".components_selct").each(function () {
          $_reactThis.setOverallSubmdfrPrice($(this));
        });
      }
    }
  }

  render() {
    return (
      <div className="productpage-main-div product-details-page">
        <Header
          cartTriggerFlg={this.state.cartTriggerFlg}
          cartClearTriggerFlg={this.state.cartClearTriggerFlg}
          sateValChange={this.sateValChange}
          showCatryName={this.state.selectedCategoryName}
          selectPostalCode={this.state.selectPostalCode}
          selectPickupTime={this.state.selectPickupTime}
        />
        <div className="prodetailinner-main-div" id="ProductDetailMdl">
          {this.productDetailsMain()}
        </div>
        <div id="dvLoading"></div>
        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var proDateilArr = Array();
  var proCommonArr = Array();
  var prodetailstatus = "";
  if (Object.keys(state.productdetail).length > 0) {
    if (
      state.productdetail[0].status === "ok" &&
      Object.keys(state.productdetail[0].result_set).length > 0
    ) {
      proDateilArr = state.productdetail[0].result_set;
      proCommonArr = state.productdetail[0].common;
      prodetailstatus = "success";
    } else {
      prodetailstatus = "failure";
    }
  }

  return {
    productdetail: proDateilArr,
    productcommon: proCommonArr,
    productdetailstatus: prodetailstatus,
    globalsettings: state.settings,
    alloutlets: state.alloutlets,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductDetail: (proSlug, outletID) => {
      dispatch({ type: GET_PRODUCT_DETAIL, proSlug, outletID });
    },
    getAllOutlets: (
      availability,
      outlet_slug,
      event_slug,
      shoptype,
      zip_code,
      site_location_id
    ) => {
      dispatch({
        type: GET_ALL_OUTLETS,
        availability,
        outlet_slug,
        event_slug,
        shoptype,
        zip_code,
        site_location_id,
      });
    },
  };
};

export default withRouter(
  connect(mapStateTopProps, mapDispatchToProps)(ProductDetailPage)
);

/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
4;
import axios from "axios";
import Slider from "react-slick";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { createBrowserHistory as history } from "history";
import cookie from "react-cookies";
import {
  stripslashes,
  hideLoader,
  searchFilters,
  showLoader,
  showAlert,
  addressFormat,
} from "../Helpers/SettingHelper";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";

import innerbanner from "../../common/images/mall-banner.jpg";
import innerbanner_vivo from "../../common/images/UPshop_banner_marketplace_vivo.jpg";
import innerbanner_takas from "../../common/images/UPshop_banner_marketplace_takashimayasc.jpg";
import innerbanner_rangoon from "../../common/images/UPshop_banner_marketplace_rangoon.jpg";
import innerbanner_jewel from "../../common/images/UPshop_banner_marketplace_jewel.jpg";
import innerbanner_bussorah from "../../common/images/UPshop_banner_marketplace_bussorah.jpg";
import innerbanner_313 from "../../common/images/UPshop_banner_marketplace_313.jpg";

import brandImg from "../../common/images/tusta.jpg";
import brandImg1 from "../../common/images/burgs.jpg";
import brandImg2 from "../../common/images/cqc.jpg";
import brandImg3 from "../../common/images/affair.jpg";

import noimage from "../../common/images/no-img-product.png";
import filter from "../../common/images/filter.png";
import searchorg from "../../common/images/search-org.png";
import closebl from "../../common/images/close-orange.png";
import groupdark from "../../common/images/group.png";
import groupred from "../../common/images/group-red.png";
import groupwhite from "../../common/images/group-white.png";
import { AiFillCaretLeft, AiFillCaretRight } from "react-icons/ai";
import {
  apiUrl,
  apiUrlV2,
  appId,
  mediaUrl,
  deliveryId,
  pickupId,
  siteURL,
} from "../Helpers/Config";
import {
  GET_GLOBAL_SETTINGS,
  GET_REQUESTPAGEDATA,
  GET_STATIC_BLOCK,
  GET_ALL_OUTLETS,
  GET_EVENTS,
  GET_SITE_LOCATION,
} from "../../actions";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import MarketplaceImg from "../../common/images/marketplace.svg";
import MallImg from "../../common/images/mall.svg";
import PriceImg from "../../common/images/price.svg";

import moment from "moment";
var base64 = require("base-64");
var Parser = require("html-react-parser");
var productSlider = {
  dots: false,
  arrows: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  variableWidth: true,
  swipeToSlide: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  pauseOnHover: true,
  prevArrow: (
    <div>
      <AiFillCaretLeft />
    </div>
  ),
  nextArrow: (
    <div>
      <AiFillCaretRight />
    </div>
  ),
  responsive: [
    {
      breakpoint: 1100,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        variableWidth: false,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};
var monthArr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
class Mall extends Component {
  constructor(props) {
    super(props);

    let defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";
    var deliveryDate = cookie.load("deliveryDate");
    var currentPageUrl = this.props.location.pathname.split("/");
    let currentPageUrll = "";
    if (this.props.location.pathname === "/") {
      currentPageUrll = "food";
    } else {
      currentPageUrll =
        currentPageUrl[1] !== "" &&
        typeof currentPageUrl[1] !== undefined &&
        typeof currentPageUrl[1] !== "undefined"
          ? currentPageUrl[1]
          : "";
      currentPageUrll = "food";
    }
    if (currentPageUrll === "retail") {
      if (
        defaultAvilTy === "" ||
        typeof defaultAvilTy === undefined ||
        typeof defaultAvilTy === "undefined"
      ) {
        var deliveryDate1 = new Date();

        cookie.save("defaultAvilablityId", pickupId, { path: "/" });

        cookie.save(
          "deliveryDate",
          deliveryDate1.getDate() +
            "/" +
            deliveryDate1.getMonth() +
            "/" +
            deliveryDate1.getFullYear(),
          { path: "/" }
        );
        cookie.save(
          "deliveryTime",
          deliveryDate1.getHours() +
            ":" +
            deliveryDate1.getMinutes() +
            ":" +
            deliveryDate1.getSeconds(),
          { path: "/" }
        );
        var orderDateTime = new Date().toISOString();
        cookie.save("orderDateTime", orderDateTime, { path: "/" });

        defaultAvilTy = pickupId;
        this.loadPickupOutlet();
      }
    }

    const orderPostalCode =
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";
    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    let uriparms_one = "";
    let url_mall_name = "";
    let url_mall_name2 = "";
    let pathname = props.location.pathname;
    pathname = pathname.split("/");
    uriparms_one = pathname[2];
    url_mall_name = pathname[3];
    url_mall_name2 = pathname[4];
    this.state = {
      defaultAvilTy: defaultAvilTy,
      orderPostalCode: orderPostalCode,
      deliveryPostalCode: deliveryPostalCode,
      outletslist: [],
      searchoutletslist: [],
      outletsMainlist: [],
      displayOutlets: "",
      totalRes: "",
      settings: [],
      tag: [],
      tagdisplay: "",
      promotion: [],
      order_date: new Date(),
      selectPostalCode: "No",
      filterTag: [],
      eventList: [],
      eventImagePath: "",
      event_List: "",
      urltype: this.props.match.path,
      eventSlug:
        this.props.match.params.slugEvent !== "" &&
        typeof this.props.match.params.slugEvent !== undefined &&
        typeof this.props.match.params.slugEvent !== "undefined"
          ? this.props.match.params.slugEvent
          : "",
      locationSlug:
        this.props.match.params.sluglocation !== "" &&
        typeof this.props.match.params.sluglocation !== undefined &&
        typeof this.props.match.params.sluglocation !== "undefined"
          ? this.props.match.params.sluglocation
          : "",
      staticblack: [],
      restaurantcontent: [],
      retailcontent: [],
      siteLocationList: [],
      mallslug: uriparms_one,
      mallname: "",
      cartDetails: "",
      guestname: "",
      guestname_error: "",
      cart_group_id:
        cookie.load("cart_group_id") !== "" &&
        typeof cookie.load("cart_group_id") !== undefined &&
        typeof cookie.load("cart_group_id") !== "undefined"
          ? cookie.load("cart_group_id")
          : "",
      outletLocationId:
        cookie.load("outletLocationId") !== "" &&
        typeof cookie.load("outletLocationId") !== undefined &&
        typeof cookie.load("outletLocationId") !== "undefined"
          ? cookie.load("outletLocationId")
          : "",
      shareGroupUrl: "",
      url_group_mall_name: url_mall_name,
      url_group_mall_name2: url_mall_name2,
      orderoutlet_name: "",
      mall_address: "",
      mall_image: "",
      mall_imgsrc: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.changeSiteLocation = this.changeSiteLocation.bind(this);
    this.props.getSettings();
    this.props.getStaticBlock();
    var shop_type = "";

    if (
      this.props.match.path === "/mall/:sluglocation" ||
      this.props.match.path === "/"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else if (
      this.props.match.path ===
      "/grouporder/:slugType/:slugMallname/:outletSlug/:orderID/:outletID/:deliveryDate/:orderDateTime/:deliveryTime/:orderdeliverycode/:orderType/:locationId"
    ) {
      shop_type = 1;
    } else {
      shop_type = 3;
    }

    this.props.getAllOutlets(
      defaultAvilTy,
      this.state.eventSlug,
      shop_type,
      deliveryPostalCode
    );

    this.props.getEvents();
    this.props.getloadSiteLocation();
  }

  componentDidMount() {
    $(".dvLoadrCls").show();
    setTimeout(function () {
      $(".test-popup-link").magnificPopup({ type: "image" });
    }, 2000);

    $("#dvLoading").fadeOut(2000);

    if (
      this.props.match.path ===
      "/grouporder/:slugType/:slugMallname/:outletSlug/:orderID/:outletID/:deliveryDate/:orderDateTime/:deliveryTime/:orderdeliverycode/:orderType/:locationId"
    ) {
      this.loadGruopShare();
    }

    if (
      cookie.load("openGroupShare") !== "" &&
      typeof cookie.load("openGroupShare") !== undefined &&
      typeof cookie.load("openGroupShare") !== "undefined"
    ) {
      this.openGropShare();
      cookie.remove("openGroupShare", { path: "/" });
    }
  }

  loadGruopShare() {
    showLoader("sharegroup-body", "class");
    $("#sharegroup-popup").modal({ keyboard: false });
    var current = this;
    var outlet_slugs = "";

    if (this.props.match.params.outletSlug !== "mall") {
      outlet_slugs = "&outlet_slug=" + this.props.match.params.outletSlug;
    }

    var decode_postal_code = base64.decode(
      this.props.match.params.orderdeliverycode
    );
    var decode_order_type = base64.decode(this.props.match.params.orderType);
    var locationId = base64.decode(this.props.match.params.locationId);
    this.props.getloadSiteLocation();

    if (decode_order_type === "delivery") {
      this.grouporder_location(decode_postal_code, decode_order_type);
    } else {
      this.grouporder_location(locationId, decode_order_type);
    }

    axios
      .get(
        apiUrl +
          "cart/contents?app_id=" +
          appId +
          "&status=A&cart_id=" +
          base64.decode(this.props.match.params.orderID) +
          outlet_slugs
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (
            res.data.result_set !== "" &&
            typeof res.data.result_set !== undefined &&
            typeof res.data.result_set !== "undefined"
          ) {
            this.setState(
              { cartDetails: res.data.result_set.cart_details },
              function () {
                this.props.getloadSiteLocation();
                hideLoader("sharegroup-body", "class");
              }
            );
          } else {
            $("#sharegroup-popup").modal("hide");
          }
        } else {
          $("#sharegroup-popup").modal("hide");
        }
      });
  }

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.alloutlets !== this.props.alloutlets) {
      if (PropsDt.alloutlets[0].status === "ok") {
        this.setState(
          {
            outletslist: PropsDt.alloutlets[0].result_set,
            eventOutletsList: PropsDt.alloutlets[0].result_set,
            searchoutletslist: PropsDt.alloutlets[0].result_set,
            outletsMainlist: PropsDt.alloutlets[0].result_set,
            tag: PropsDt.alloutlets[0].tag,
            promotion: PropsDt.alloutlets[0].promotion,
          },
          function () {
            this.displayOutlets();
            this.displaytag();
            this.getOrderOutletname();
          }
        );
      } else {
        this.setState(
          {
            outletslist: [],
            eventOutletsList: [],
            searchoutletslist: [],
            outletsMainlist: [],
            tag: [],
            promotion: [],
          },
          function () {
            this.displayOutlets();
            this.displaytag();
          }
        );
      }
    }
    if (PropsDt.eventList !== this.props.eventList) {
      if (PropsDt.eventList[0].status === "ok") {
        this.setState(
          {
            eventList: PropsDt.eventList[0].result_set,
            eventImagePath: PropsDt.eventList[0].imagepath,
          },
          function () {
            this.displayEvents();
          }
        );
      } else {
        this.setState(
          {
            eventList: [],
            eventImagePath: "",
          },
          function () {
            this.displayEvents();
          }
        );
      }
    }

    if (PropsDt.match.url !== this.props.match.url) {
      var pageslug = PropsDt.match.path;

      var eventSlug =
        PropsDt.match.params.slugEvent !== "" &&
        typeof PropsDt.match.params.slugEvent !== undefined &&
        typeof PropsDt.match.params.slugEvent !== "undefined"
          ? PropsDt.match.params.slugEvent
          : "";
      this.setState({ urltype: pageslug, eventSlug: eventSlug }, function () {
        this.displayEvents();
        var shop_type = "";
        if (pageslug === "/mall/:sluglocation" || pageslug === "/") {
          shop_type = 1;
        } else if (pageslug === "/retail/:sluglocation") {
          shop_type = 2;
        } else {
          shop_type = 3;
        }
        this.props.getAllOutlets(
          this.state.defaultAvilTy,
          eventSlug,
          shop_type,
          this.state.deliveryPostalCode
        );
      });
    }
    if (PropsDt.staticblack !== this.state.staticblack) {
      this.setState({ staticblack: PropsDt.staticblack });
      var resPas = "";
      var retailPas = "";
      if (Object.keys(PropsDt.staticblack).length > 0) {
        PropsDt.staticblack.map((data) => {
          if (data.staticblocks_slug === "restaurants-content") {
            resPas = data;
          }
          if (data.staticblocks_slug === "retail-content") {
            retailPas = data;
          }
        });
      }
      this.setState({ restaurantcontent: resPas, retailcontent: retailPas });
    }

    if (PropsDt.globalsettings !== this.state.settings) {
      if (PropsDt.globalsettings.length > 0) {
        if (PropsDt.globalsettings[0].status === "ok") {
          this.setState({ settings: PropsDt.globalsettings[0].result_set });
        }
      }
    }

    if (PropsDt.siteLocationList !== this.state.siteLocationList) {
      if (Object.keys(PropsDt.siteLocationList).length > 0) {
        if (PropsDt.siteLocationList[0].status === "ok") {
          this.setState(
            {
              siteLocationList: PropsDt.siteLocationList[0].result_set,
              locationImage: PropsDt.siteLocationList[0].common.image_source,
            },
            function () {
              this.displaymallname();
            }
          );
        }
      }
    }
  }

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "" + unitNo : "";
  }

  grouporder_location(decode_postal_code, type) {
    if (decode_postal_code !== "" && type == "delivery") {
      axios
        .get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&availability_id=" +
            deliveryId +
            "&postal_code=" +
            decode_postal_code
        )
        .then((res) => {
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname +
            " Singapore " +
            decode_postal_code;

          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
        });
    }
  }

  getOrderOutletname() {
    let getOrderOutletname = "";
    let outlet = "";
    if (
      this.state.outletslist !== "" &&
      typeof this.state.outletslist !== undefined &&
      typeof this.state.outletslist !== "undefined" &&
      this.state.outletslist.length > 0
    ) {
      outlet = this.state.outletslist.map((item, index) => {
        if (
          this.props.match.params.outletID !== "" &&
          this.props.match.params.outletID !== undefined
        ) {
          if (
            base64.decode(this.props.match.params.outletID) ===
            item.oa_outlet_id
          ) {
            getOrderOutletname = item.outlet_name;
          }
        }
      });
    }

    this.setState({
      orderoutlet_name: getOrderOutletname,
    });
  }

  displayOutlets() {
    /*  no-res-pro */
    let outlet = "";
    let current = this;
    let totalRes = 0;
    var captolOutletTotal = 0;
    var tempOutletLocationId =
      cookie.load("tempOutletLocationId") !== "" &&
      typeof cookie.load("tempOutletLocationId") !== undefined &&
      typeof cookie.load("tempOutletLocationId") !== "undefined"
        ? cookie.load("tempOutletLocationId")
        : "";
    var home_brand_id =
      cookie.load("home_brand_id") !== "" &&
      typeof cookie.load("home_brand_id") !== undefined &&
      typeof cookie.load("home_brand_id") !== "undefined"
        ? cookie.load("home_brand_id")
        : "";
    console.log(this.state.outletslist, "this.state.outletslist");
    if (
      this.state.outletslist !== "" &&
      typeof this.state.outletslist !== undefined &&
      typeof this.state.outletslist !== "undefined" &&
      this.state.outletslist.length > 0
    ) {
      outlet = this.state.outletslist.map((item, index) => {
        if (
          item.sl_slug === this.state.mallslug &&
          /*  item.brand_id === home_brand_id && */
          item.outlet_location_id === tempOutletLocationId
        ) {
          totalRes++;
          captolOutletTotal++;

          if (item.oa_outlet_id !== "163") {
            current.checkTimeAvilablity(item.oa_outlet_id);

            return (
              <li
                key={index}
                id={"res_" + item.oa_outlet_id}
                onClick={current.viewProducts.bind(
                  current,
                  item.oa_outlet_id,
                  item.outlet_slug,
                  item.outlet_location_id,
                  item.sl_location_id,
                  item.sl_name
                )}
              >
                <a id="shop_manual_close">
                  <div className="market-image">
                    <img
                      src={
                        item.outlet_image !== ""
                          ? mediaUrl + "outlet/" + item.outlet_image
                          : noimage
                      }
                      alt={item.outlet_name}
                      title={item.outlet_name}
                    />

                    {/* <div className="offer-tag">
                  <span>15 <br /> min </span> 
                  <strong>20% <br /> off </strong>
                </div> */}
                  </div>

                  <div className="market-name">
                    <h4>{item.outlet_name}</h4>
                    <p>
                      <span>
                        {item.outlet_tag_id !== "" &&
                          current.loadTag(item.outlet_tag_id)}
                      </span>
                    </p>
                    <div className="shop_closed"></div>
                  </div>
                </a>
              </li>
            );
          }
        } else if (
          item.sl_slug === this.state.mallslug &&
          tempOutletLocationId === ""
        ) {
          totalRes++;
          captolOutletTotal++;

          if (item.oa_outlet_id !== "163") {
            current.checkTimeAvilablity(item.oa_outlet_id);

            return (
              <li
                key={index}
                id={"res_" + item.oa_outlet_id}
                onClick={current.viewProducts.bind(
                  current,
                  item.oa_outlet_id,
                  item.outlet_slug,
                  item.outlet_location_id,
                  item.sl_location_id,
                  item.sl_name
                )}
              >
                <a id="shop_manual_close">
                  <div className="market-image">
                    <img
                      src={
                        item.outlet_image !== ""
                          ? mediaUrl + "outlet/" + item.outlet_image
                          : noimage
                      }
                      alt={item.outlet_name}
                      title={item.outlet_name}
                    />

                    {/* <div className="offer-tag">
                  <span>15 <br /> min </span> 
                  <strong>20% <br /> off </strong>
                </div> */}
                  </div>

                  <div className="market-name">
                    <h4>{item.outlet_name}</h4>
                    <p>
                      <span>
                        {item.outlet_tag_id !== "" &&
                          current.loadTag(item.outlet_tag_id)}
                      </span>
                    </p>
                    <div className="shop_closed"></div>
                  </div>
                </a>
              </li>
            );
          }
        }
      });
      if (captolOutletTotal === 0) {
        outlet = "";
      }
    }

    hideLoader("innerproduct", "class");
    this.setState({
      displayOutlets: outlet,
      totalRes: totalRes,
    });
  }

  displaytag() {
    var tagdisplay = "";
    var tags = this.state.tag;
    if (tags !== "" && tags !== null && tags !== undefined) {
      var current1 = this;
      tagdisplay = Object.keys(tags).map(function (item, index) {
        return (
          <li className="custom_checkbox" key={index}>
            <input
              type="checkbox"
              className="restaurants_tag"
              onChange={current1.handleChange}
              value={item}
            />
            <span>{tags[item]}</span>
          </li>
        );
      });
    }
    this.setState({ tagdisplay: tagdisplay });
  }

  loadTag(tag_id) {
    if (tag_id !== "" && tag_id !== null && tag_id !== undefined) {
      let tagID = tag_id.split(",");
      let tagList = this.state.tag;
      if (tagID.length > 0) {
        return tagID.map(function (item, index) {
          if (
            tagList[item] !== "" &&
            typeof tagList[item] !== undefined &&
            typeof tagList[item] !== "undefined"
          ) {
            return (
              <span key={index}>
                {tagList[item]}
                {tagID.length - 1 != index ? ", " : ""}
              </span>
            );
          }
        });
      }
    }
  }

  loadPromo(outlet_id) {
    let promotion = this.state.promotion;
    if (promotion !== "") {
      if (
        promotion[outlet_id] !== "" &&
        typeof promotion[outlet_id] !== undefined &&
        typeof promotion[outlet_id] !== "undefined"
      ) {
        return promotion[outlet_id].map(function (item, index) {
          return <span key={index}>{item}</span>;
        });
      }
    }
  }

  displayEvents() {
    var event_List = "";
    var ecurrent = this;
    if (this.state.eventList.length > 0) {
      event_List = this.state.eventList.map(function (item, index) {
        return (
          <li
            key={index}
            className={
              ecurrent.state.urltype === "/events/:slugEvent/" &&
              ecurrent.state.eventSlug === item.event_slug
                ? "active"
                : ""
            }
          >
            <Link to={"/events/" + item.event_slug}>{item.event_name}</Link>
          </li>
        );
      });
    }
    this.setState({ event_List: event_List });
  }

  viewProducts(
    outlet_id,
    outlet_slug,
    outlet_location_id,
    sl_location_id,
    sl_name
  ) {
    console.log(outlet_id, "outlet_id");

    // cookie.remove("orderOutletId", { path: "/" });
    // cookie.remove("tempOutletLocationId", { path: "/" });

    cookie.save("orderOutletId", outlet_id, { path: "/" });
    cookie.save("tempOutletLocationId", outlet_location_id, { path: "/" });

    var shopType = "/mall/:sluglocation";
    if (
      this.state.urltype === "/mall/:sluglocation" ||
      this.state.urltype === "/"
    ) {
      shopType = "food";
      cookie.save("shopType", "1", { path: "/" });
    } else if (this.state.urltype === "/retail/:sluglocation") {
      cookie.save("shopType", "2", { path: "/" });
      shopType = "retail";
    }

    location.href =
      siteURL + shopType + "/" + this.state.locationSlug + "/" + outlet_slug;
  }

  checkTimeAvilablity(outletID) {
    if (outletID !== "") {
      var orderDate =
        cookie.load("deliveryDate") !== "" &&
        typeof cookie.load("deliveryDate") !== undefined &&
        typeof cookie.load("deliveryDate") !== "undefined"
          ? cookie.load("deliveryDate")
          : "";
      var deliveryTime =
        cookie.load("deliveryTime") !== "" &&
        typeof cookie.load("deliveryTime") !== undefined &&
        typeof cookie.load("deliveryTime") !== "undefined"
          ? cookie.load("deliveryTime")
          : "";
      var shop_close = "";
      if (deliveryTime !== "") {
        axios
          .get(
            apiUrlV2 +
              "settings/chkOutletTimesAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              this.state.defaultAvilTy +
              "&outletID=" +
              outletID +
              "&orderDateTime=" +
              orderDate +
              " " +
              deliveryTime
          )
          .then((response) => {
            var res_min_date = "";
            if (response.data.min_date !== "") {
              res_min_date = moment(response.data.min_date).format("D MMM YY");
            }
            //$("#res_" + outletID + " .restaurant-date").html(res_min_date);
            if (response.data.status === "success") {
              $("#res_" + outletID).removeClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .hide()
                .html("");
              shop_close = "1";
            } else {
              $("#res_" + outletID).addClass("no-res-pro");
              $("#res_" + outletID + " .shop_closed")
                .show()
                .html("Temporarily closed");
              shop_close = "1";
            }
          });
      }
    }
  }
  sateValChange = (field, value) => {
    if (field === "cartflg") {
      this.setState({ cartTriggerFlg: value });
    }
    if (field === "selectPostalCode") {
      if (value == "Yes") {
        cookie.save("defaultAvilablityId", deliveryId, { path: "/" });
        this.setState(
          { defaultAvilTy: deliveryId, selectPostalCode: "No" },
          function () {
            showLoader("innerproduct", "class");
            var shop_type = "";
            if (
              this.props.match.path === "/mall/:sluglocation" ||
              this.props.match.path === "/"
            ) {
              shop_type = 1;
            } else if (this.props.match.path === "/retail/:sluglocation") {
              shop_type = 2;
            } else {
              shop_type = 3;
            }
            this.props.getAllOutlets(
              deliveryId,
              this.state.eventSlug,
              shop_type,
              this.state.deliveryPostalCode
            );
          }
        );
      }
    }

    if (field === "postalcode") {
      this.setState({ orderPostalCode: value });
      if (this.state.defaultAvilTy === deliveryId) {
        this.setState({ deliveryPostalCode: value });
      }
    }

    if (field === "loadOutlet" && value !== "") {
      var outletdata = value.split("_");
      this.setState({ defaultAvilTy: outletdata[0] });
      this.props.getAllOutlets(outletdata[0], "", 1, outletdata[1]);
    }

    if (field === "cartDetails" && value !== "") {
      if (
        value.cart_id !== "" &&
        typeof value.cart_id !== undefined &&
        typeof value.cart_id !== "undefined"
      ) {
        var cart_id = base64.encode(value.cart_id);
        var orderOutletId = base64.encode(cookie.load("orderOutletId"));

        var deliveryDate =
          cookie.load("deliveryDate") !== "" &&
          typeof cookie.load("deliveryDate") !== "undefined" &&
          typeof cookie.load("deliveryDate") !== undefined
            ? base64.encode(cookie.load("deliveryDate").split("/").join("-"))
            : "";
        var orderDateTime =
          cookie.load("orderDateTime") !== "" &&
          typeof cookie.load("orderDateTime") !== "undefined" &&
          typeof cookie.load("orderDateTime") !== undefined
            ? base64.encode(cookie.load("orderDateTime").split('"').join(""))
            : "";

        var deliveryTime =
          cookie.load("deliveryTime") !== "" &&
          typeof cookie.load("deliveryTime") !== "undefined" &&
          typeof cookie.load("deliveryTime") !== undefined
            ? base64.encode(cookie.load("deliveryTime"))
            : "";

        var orderdeliverycode =
          cookie.load("deliveryPostalCode") !== "" &&
          typeof cookie.load("deliveryPostalCode") !== "undefined" &&
          typeof cookie.load("deliveryPostalCode") !== undefined
            ? base64.encode(cookie.load("deliveryPostalCode"))
            : "";

        var orderType =
          cookie.load("defaultAvilablityId") !== "" &&
          typeof cookie.load("defaultAvilablityId") !== "undefined" &&
          typeof cookie.load("defaultAvilablityId") !== undefined
            ? cookie.load("defaultAvilablityId")
            : "";

        if (orderType === deliveryId) {
          orderType = "delivery";
          orderType = base64.encode(orderType);
        } else {
          orderType = "takeaway";
          orderType = base64.encode(orderType);
        }

        var locationId =
          cookie.load("sl_location_id") !== "" &&
          typeof cookie.load("sl_location_id") !== "undefined" &&
          typeof cookie.load("sl_location_id") !== undefined
            ? base64.encode(cookie.load("sl_location_id"))
            : "";

        var shareGroupUrl =
          siteURL +
          "grouporder/mall/" +
          this.state.mallslug +
          "/" +
          "mall" +
          "/" +
          cart_id +
          "/" +
          orderOutletId +
          "/" +
          deliveryDate +
          "/" +
          orderDateTime +
          "/" +
          deliveryTime +
          "/" +
          orderdeliverycode +
          "/" +
          orderType +
          "/" +
          locationId;

        this.setState({
          cartDetails: value,
          shareGroupUrl:
            siteURL +
            "grouporder/mall/" +
            this.state.mallslug +
            "/" +
            "mall" +
            "/" +
            cart_id +
            "/" +
            orderOutletId +
            "/" +
            deliveryDate +
            "/" +
            orderDateTime +
            "/" +
            deliveryTime +
            "/" +
            orderdeliverycode +
            "/" +
            orderType +
            "/" +
            locationId,
        });
      }
    }
  };

  handleChange(event) {
    let filterTag = this.state.filterTag;
    if (event.target.checked === true) {
      filterTag.push(event.target.value);
    } else {
      var index = filterTag.indexOf(event.target.value);
      filterTag.splice(index, 1);
    }
    this.setState({ filterTag: filterTag }, function () {
      this.applyFilter();
    });
  }

  displaymallname() {
    if (
      this.state.siteLocationList !== "" &&
      this.state.siteLocationList !== undefined &&
      this.state.siteLocationList !== null
    ) {
      var mallname = "";
      var mallname2 = "";
      var mall_address = "";
      var mall_images = "";
      var malllist = this.state.siteLocationList.map((item, index) => {
        if (item.sl_slug === this.state.mallslug) {
          mallname = item.sl_name;
          mall_images = item.sl_image;
        }
        if (this.props.match.params.slugMallname == item.sl_slug) {
          mallname2 = item.sl_name;

          mall_address =
            item.sl_name +
            addressFormat(
              item.sl_pickup_unit_number1,
              item.sl_pickup_unit_number2,
              item.sl_pickup_address_line1,
              item.sl_pickup_address_line2,
              item.sl_pickup_postal_code
            );
        }
      });

      this.setState({
        mallname: mallname,
        url_group_mall_name2: mallname2,
        mall_address: mall_address,
        mall_image: mall_images,
      });
    }
  }

  searchProKeyPress = (event) => {
    var value = event.target.value.toLowerCase();
    var matches = this.state.outletsMainlist.filter(function (item) {
      if (item.outlet_name.toLowerCase().indexOf(value) >= 0) {
        return item;
      }
    });
    this.setState(
      { outletslist: matches, searchoutletslist: matches },
      function () {
        this.displayOutlets();
      }
    );
  };

  applyFilter() {
    var current2 = this;
    var matches = [];
    if (current2.state.filterTag.length > 0) {
      matches = this.state.searchoutletslist.filter(function (item) {
        if (item.outlet_tag_id !== "" && item.outlet_tag_id !== null) {
          let outlet_tag_id = item.outlet_tag_id.split(",");
          let result = searchFilters(outlet_tag_id, current2.state.filterTag);
          if (result === true) {
            return item;
          }
        }
      });
    } else {
      matches = this.state.searchoutletslist;
    }
    this.setState({ outletslist: matches }, function () {
      this.displayOutlets();
    });
  }

  clearFilter(event) {
    event.preventDefault();
    $(".restaurants_tag").prop("checked", false);
    this.setState(
      { outletslist: this.state.searchoutletslist, filterTag: [] },
      function () {
        this.displayOutlets();
      }
    );
  }

  openGropShare(event) {
    if (event !== "" && event !== undefined && event !== "undefined") {
      event.preventDefault();
    }

    if (
      this.state.defaultAvilTy === "" ||
      typeof this.state.defaultAvilTy === undefined ||
      typeof this.state.defaultAvilTy === "undefined"
    ) {
      //$(".required_availability").show();

      // $("html, body").animate(
      //   {
      //     scrollTop: $("#availability_div").offset().top - 100,
      //   },
      //   1000
      // );
      setTimeout(function () {
        $(".required_availability").hide();
      }, 3000);
      return false;
    }

    if (
      cookie.load("UserId") !== "" &&
      typeof cookie.load("UserId") !== undefined &&
      typeof cookie.load("UserId") !== "undefined"
    ) {
      if (
        this.state.cartDetails.totalItems > 0 ||
        this.state.cartDetails.totalItems !== undefined
      ) {
        if (
          this.state.shareGroupUrl === "" &&
          typeof this.state.shareGroupUrl === undefined
        ) {
          var outletLocationId = this.state.outletLocationId;
          var availabilityId = cookie.load("defaultAvilablityId");
          /*var availabilityId = deliveryId;*/
          var availabilityName =
            availabilityId === deliveryId ? "Delivery" : "Pickup";
          var customerId =
            typeof cookie.load("UserId") === "undefined"
              ? ""
              : cookie.load("UserId");
          var shopType =
            cookie.load("shopType") !== "" &&
            typeof cookie.load("shopType") !== undefined &&
            typeof cookie.load("shopType") !== "undefined"
              ? cookie.load("shopType")
              : "";
          var qs = require("qs");
          var postObject = {
            app_id: appId,
            outletLocationId: outletLocationId,
            availability_id: availabilityId,
            availability_name: availabilityName,
            customer_id: customerId,
            shopType: shopType,
          };
          axios
            .post(
              apiUrl + "cart/insert_cart_without_product",
              qs.stringify(postObject)
            )
            .then((response) => {
              hideLoader("cancel_order", "class");
              if (response.data.status === "ok") {
                var cart_id = base64.encode(response.data.contents);
                var orderOutletId = base64.encode(cookie.load("orderOutletId"));
                var deliveryDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== "undefined" &&
                  typeof cookie.load("deliveryDate") !== undefined
                    ? base64.encode(
                        cookie.load("deliveryDate").split("/").join("-")
                      )
                    : "";
                var orderDateTime =
                  cookie.load("orderDateTime") !== "" &&
                  typeof cookie.load("orderDateTime") !== "undefined" &&
                  typeof cookie.load("orderDateTime") !== undefined
                    ? base64.encode(
                        cookie.load("orderDateTime").split('"').join("")
                      )
                    : "";

                var deliveryTime =
                  cookie.load("deliveryTime") !== "" &&
                  typeof cookie.load("deliveryTime") !== "undefined" &&
                  typeof cookie.load("deliveryTime") !== undefined
                    ? base64.encode(cookie.load("deliveryTime"))
                    : "";

                var orderType =
                  cookie.load("defaultAvilablityId") !== "" &&
                  typeof cookie.load("defaultAvilablityId") !== "undefined" &&
                  typeof cookie.load("defaultAvilablityId") !== undefined
                    ? cookie.load("defaultAvilablityId")
                    : "";

                if (orderType === deliveryId) {
                  orderType = "delivery";
                  orderType = base64.encode(orderType);
                } else {
                  orderType = "takeaway";
                  orderType = base64.encode(orderType);
                }

                var locationId =
                  cookie.load("sl_location_id") !== "" &&
                  typeof cookie.load("sl_location_id") !== "undefined" &&
                  typeof cookie.load("sl_location_id") !== undefined
                    ? base64.encode(cookie.load("sl_location_id"))
                    : "";

                this.setState(
                  {
                    shareGroupUrl:
                      siteURL +
                      "grouporder/mall/" +
                      this.state.mallslug +
                      "/" +
                      "mall" +
                      "/" +
                      cart_id +
                      "/" +
                      orderOutletId +
                      "/" +
                      deliveryDate +
                      "/" +
                      orderDateTime +
                      "/" +
                      deliveryTime +
                      "/" +
                      orderdeliverycode +
                      "/" +
                      orderType +
                      "/" +
                      locationId,
                  },
                  function () {
                    $("#copygroup-popup").modal({
                      keyboard: false,
                    });
                  }
                );
              } else {
              }
              return false;
            });
        } else {
          var totalCartCount = $("#totalitmcount").val();
          if (totalCartCount > 0) {
            $("#copygroup-popup").modal({ keyboard: false });
          }
        }
      } else {
        showAlert("Notice", "Please add items to start group order.");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    } else {
      cookie.save("openGroupShare", "Yes", { path: "/" });
      $.magnificPopup.open({
        items: {
          src: "#login-popup",
        },
        type: "inline",
      });
    }
  }

  changeSiteLocation = (item, event) => {
    var slug_value = event.target.value;

    var shop_type = "";

    if (
      this.props.match.path === "/mall/:sluglocation" ||
      this.props.match.path === "/"
    ) {
      shop_type = 1;
    } else if (this.props.match.path === "/retail/:sluglocation") {
      shop_type = 2;
    } else {
      shop_type = 3;
    }

    if (slug_value !== "") {
      const { history } = this.props;
      this.setState(
        {
          [item]: event.target.value,
          eventSlug: slug_value,
          mallslug: slug_value,
          locationSlug: slug_value,
        },
        function () {
          this.props.getAllOutlets(
            this.state.defaultAvilTy,
            slug_value,
            shop_type,
            this.state.deliveryPostalCode
          );
          this.props.getEvents();
          this.props.getloadSiteLocation();
          history.push("/mall/" + slug_value);
        }
      );
    } else {
      //window.location.reload();
    }
  };

  handleChangeTxt = (item, event) => {
    this.setState({ [item]: event.target.value });
    if (event.target.value !== "") {
      this.setState({ [item + "_error"]: "" });
    }
  };

  viewMenu(event) {
    event.preventDefault();
    var error = 0;
    if (this.state.guestname === "") {
      this.setState({ guestname_error: 1 });
      error++;
    }
    if (error === 0) {
      var qs = require("qs");
      var postObject = {
        app_id: appId,
        cart_id: this.props.match.params.orderID,
        cart_member_name: this.state.guestname,
      };
      showLoader("view_menu", "class");

      const { history } = this.props;

      axios
        .post(apiUrl + "cart/create_group", qs.stringify(postObject))
        .then((response) => {
          hideLoader("view_menu", "class");
          $.magnificPopup.close();
          if (response.data.status === "ok") {
            $("#sharegroup-popup").modal("hide");

            if (
              this.props.match.params.deliveryDate !== "" &&
              typeof this.props.match.params.deliveryDate !== undefined &&
              typeof this.props.match.params.deliveryDate !== "undefined"
            ) {
              var deliveryDate = base64
                .decode(this.props.match.params.deliveryDate)
                .split("-")
                .join("/");
              cookie.save("deliveryDate", deliveryDate, {
                path: "/",
              });
            }

            if (
              this.props.match.params.orderDateTime !== "" &&
              typeof this.props.match.params.orderDateTime !== undefined &&
              typeof this.props.match.params.orderDateTime !== "undefined"
            ) {
              var orderDateTime =
                '"' +
                base64.decode(this.props.match.params.orderDateTime) +
                '"';
              cookie.save("orderDateTime", orderDateTime, {
                path: "/",
              });
            }

            var minutesToAdd = 15;
            var currentDate = new Date();
            var futureDate = new Date(
              currentDate.getTime() + minutesToAdd * 60000
            );
            cookie.save("group_start_time", futureDate, {
              path: "/",
            });

            if (
              this.props.match.params.deliveryTime !== "" &&
              typeof this.props.match.params.deliveryTime !== undefined &&
              typeof this.props.match.params.deliveryTime !== "undefined"
            ) {
              var deliveryTime = base64.decode(
                this.props.match.params.deliveryTime
              );
              cookie.save("deliveryTime", deliveryTime, {
                path: "/",
              });
            }

            if (
              this.props.match.params.orderdeliverycode !== "" &&
              typeof this.props.match.params.orderdeliverycode !== undefined &&
              typeof this.props.match.params.orderdeliverycode !== "undefined"
            ) {
              var orderdeliverycode = base64.decode(
                this.props.match.params.orderdeliverycode
              );
              cookie.save("deliveryPostalCode", orderdeliverycode, {
                path: "/",
              });
              cookie.save("orderPostalCode", orderdeliverycode, {
                path: "/",
              });
            }

            cookie.save("cart_id", response.data.cart_details.cart_id, {
              path: "/",
            });
            cookie.save("cart_group_id", response.data.cart_group_id, {
              path: "/",
            });
            cookie.save(
              "orderOutletId",
              base64.decode(this.props.match.params.outletID),
              {
                path: "/",
              }
            );
            cookie.save(
              "defaultAvilablityId",
              response.data.cart_details.cart_availability_id,
              {
                path: "/",
              }
            );
            cookie.save(
              "outletLocationId",
              response.data.cart_details.cart_location_id,
              {
                path: "/",
              }
            );

            // window.location = siteURL;

            history.push("/mall/" + this.state.url_group_mall_name);

            location.reload();
          } else {
            $("#create-group-error").show();
            $("#create-group-error p").html(response.data.message);
            setTimeout(function () {
              $("#create-group-error").hide();
            }, 3000);
          }
          return false;
        });
    }
  }

  copyGropurl(event) {
    event.preventDefault();
    var copyText = document.getElementById("shareGroupUrl");
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    document.execCommand("copy");
    $("#coppy-success-msg").fadeIn();
    setTimeout(function () {
      $("#coppy-success-msg").fadeOut();
    }, 3000);
  }

  closeModalpopup() {
    $("#sharegroup-popup").modal("toggle");
    this.props.history.push("/");
  }

  render() {
    var mall_name = this.state.mallslug;

    var banner_name = "";

    /*if(mall_name === "vivocity"){
      banner_name = innerbanner_vivo;
    }else if(mall_name === "rangoon"){
       banner_name = innerbanner_rangoon;
    }else if(mall_name === "313-somerset"){
       banner_name = innerbanner_313;
    }else if(mall_name === "takashimaya-s-c"){
      banner_name = innerbanner_takas;
    }else if(mall_name === "jewel"){
       banner_name = innerbanner_jewel;
    }else if(mall_name === "bussorah"){
       banner_name = innerbanner_bussorah;
    }else{
       banner_name = innerbanner;
    }*/

    banner_name = this.state.locationImage + this.state.mall_image;

    if (this.state.mall_image !== "") {
      banner_name = this.state.locationImage + this.state.mall_image;
    } else {
      banner_name = "";
    }

    return (
      <div className="pagesList-main-div">
        {/* Header start */}
        <Header sateValChange={this.sateValChange} />

        <div className="innerbanner">
          <img src={banner_name} alt="" />
        </div>
        <div className="mall-add-name">
          <div className="container">
            <Link to="/marketplace" className="button">
              Back to <span>Search</span>
            </Link>
            <h3>{this.state.mallname}</h3>
          </div>
        </div>
        <div className="search-and-info mall-info">
          <div className="container">
            <div className="search-info-inner">
              <div className="search-filter mall-search">
                <div className="search-filter-lhs">
                  <input
                    type="text"
                    className="search-rest"
                    placeholder="Search restaurants, dishes & cuisines"
                    onKeyUp={this.searchProKeyPress}
                  />
                  <img src={searchorg} alt="" />
                </div>
                <div className="search-filter-rhs restaurant_filter">
                  Filters
                  <img
                    src={closebl}
                    alt=""
                    className="close_filter_section filter-clear-image"
                    style={{ display: "none" }}
                  />
                  <img
                    className="close_filter_section filter-img"
                    src={filter}
                    alt=""
                  />
                </div>

                <div className="filter-details" style={{ display: "none" }}>
                  {this.state.filterTag.length > 0 && (
                    <div className="clear-filter">
                      <Link to="#" onClick={this.clearFilter.bind(this)}>
                        Clear
                      </Link>
                    </div>
                  )}
                  <div className="item-filter common-filter">
                    <h3 className="filter-tag">Tag</h3>
                    <ul>{this.state.tagdisplay}</ul>
                  </div>
                </div>
              </div>

              {this.state.siteLocationList.length > 0 && (
                <div className="search-ii destop-li-search">
                  <select
                    className="up-inner-select form-control"
                    defaultValue={this.state.mallname}
                    onChange={this.changeSiteLocation.bind(this, "mallname")}
                  >
                    <option value="">All Malls</option>
                    {this.state.siteLocationList.map((item, index) => {
                      if (item.sl_slug !== "" && item.sl_slug !== null) {
                        return (
                          <option
                            value={item.sl_slug}
                            key={index}
                            selected={
                              item.sl_name == this.state.mallname
                                ? "selected"
                                : ""
                            }
                          >
                            {item.sl_name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              )}
            </div>

            <div className="mall-search-all">
              {this.state.cart_group_id === "" &&
                (cookie.load("group_order_confirm") === "" ||
                  typeof cookie.load("group_order_confirm") === undefined ||
                  typeof cookie.load("group_order_confirm") ===
                    "undefined") && (
                  <div className="group-link">
                    <a
                      href="#"
                      data-effect="mfp-zoom-in"
                      className="open-popup-link"
                      title="Start Group Order"
                      onClick={this.openGropShare.bind(this)}
                    >
                      <img className="no_mou" src={groupdark} alt="" />
                      <img className="mou_active" src={groupwhite} alt="" />
                      Start Group Order
                    </a>
                  </div>
                )}

              {this.state.siteLocationList.length > 0 && (
                <div className="search-ii mobile-li-search">
                  <select
                    className="up-inner-select form-control"
                    value={this.state.mallname}
                    onChange={this.changeSiteLocation.bind(this, "mallname")}
                  >
                    <option value="">All Malls</option>
                    {this.state.siteLocationList.map((item, index) => {
                      if (item.sl_slug !== "" && item.sl_slug !== null) {
                        return (
                          <option
                            value={item.sl_slug}
                            key={index}
                            selected={
                              item.sl_name == this.state.mallname
                                ? "selected"
                                : ""
                            }
                          >
                            {item.sl_name}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              )}
            </div>

            <div className="rest-switch">
              <div className="rest-switch-inner">
                <Link
                  className={
                    this.state.urltype === "/mall" ||
                    this.state.urltype === "/mall/:sluglocation" ||
                    this.state.urltype === "/"
                      ? "active"
                      : ""
                  }
                  to={"/mall/" + this.props.match.params.sluglocation}
                >
                  RESTAURANTS
                </Link>
                {this.state.settings.enable_retail == 1 && (
                  <Link
                    className={
                      this.state.urltype === "/retail/:sluglocation"
                        ? "active"
                        : ""
                    }
                    to={"/retail/" + this.props.match.params.sluglocation}
                  >
                    Retail
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <section className="markets mall-inner">
          <div className="container">
            {this.state.displayOutlets !== "" ? (
              <ul className="products-list-ulmain">
                {this.state.displayOutlets}
              </ul>
            ) : (
              <div>No Outlets Found.</div>
            )}
          </div>
        </section>

        <div id="dvLoading"></div>

        <div
          id="sharegroup-popup"
          className="modal fade commom-modal-topcls sharegroup-popup"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="common-modal-head">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    X
                  </button>
                </div>

                <div className="sharegroup-body textcenter">
                  <div className="sharegroup-body-info">
                    <img src={groupred} alt="" />
                    {this.state.cartDetails !== "" && (
                      <h4>
                        Join {this.state.cartDetails.customer_first_name}
                        {this.state.cartDetails.customer_last_name !== ""
                          ? " " + this.state.cartDetails.customer_last_name
                          : ""}
                        ’s Group Order
                      </h4>
                    )}
                    <p>
                      Add your order to{" "}
                      {this.state.cartDetails !== "" && (
                        <span>
                          {this.state.cartDetails.customer_first_name}
                          {this.state.cartDetails.customer_last_name !== ""
                            ? " " + this.state.cartDetails.customer_last_name
                            : ""}
                          ’s cart!
                        </span>
                      )}{" "}
                    </p>
                  </div>
                  <div className="sharegroup-order-info">
                    <span>Order Details</span>
                    {this.state.cartDetails !== "" && (
                      <ul>
                        <li>
                          <span>
                            <img src={MarketplaceImg} alt="" />
                          </span>{" "}
                          <p>{this.state.url_group_mall_name2}</p>
                        </li>

                        <li>
                          <span>
                            <img src={MallImg} alt="" />
                          </span>{" "}
                          <p>
                            {cookie.load("orderDeliveryAddress") !== "" &&
                            cookie.load("orderDeliveryAddress") !== undefined
                              ? cookie.load("orderDeliveryAddress")
                              : this.state.mall_address}
                          </p>
                        </li>
                        <li>
                          <div className="group_price">
                            <span>
                              <img src={PriceImg} alt="" />
                            </span>
                            <p>
                              Paid by{" "}
                              {this.state.cartDetails !== "" && (
                                <strong>
                                  {this.state.cartDetails.customer_first_name}
                                  {this.state.cartDetails.customer_last_name !==
                                  ""
                                    ? " " +
                                      this.state.cartDetails.customer_last_name
                                    : ""}
                                </strong>
                              )}
                            </p>
                          </div>
                        </li>
                      </ul>
                    )}
                  </div>
                  <div className="sharegroup-form-info">
                    <span>Your Name</span>
                    <input
                      type="text"
                      className="copy-share-text"
                      value={this.state.guestname}
                      onChange={this.handleChangeTxt.bind(this, "guestname")}
                      maxLength={50}
                    />
                    {this.state.guestname_error !== "" && (
                      <span class="error">This field is required.</span>
                    )}
                    <div className="group-order-message">
                      Please add your items to the cart within 15 minutes. A new
                      link will be required afterwards. Please note that once
                      you have added your items to{" "}
                      {this.state.cartDetails.customer_first_name}
                      {this.state.cartDetails.customer_last_name !== ""
                        ? " " + this.state.cartDetails.customer_last_name
                        : ""}
                      ’s cart, only {this.state.cartDetails.customer_first_name}
                      {this.state.cartDetails.customer_last_name !== ""
                        ? " " + this.state.cartDetails.customer_last_name
                        : ""}{" "}
                      can amend the order.{" "}
                    </div>

                    <div className="group-order-link">
                      <ul>
                        <li>
                          <a href="/faq" target="_blank">
                            FAQ
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="sharegroup-form-single-btn sharegroup-form-multiple-btn">
                      <a
                        onClick={this.closeModalpopup.bind(this)}
                        className="button"
                      >
                        Cancel
                      </a>
                      <a
                        className="button view_menu"
                        onClick={this.viewMenu.bind(this)}
                      >
                        Join Group Order
                      </a>
                    </div>
                    <div
                      className="custom_alertcls alert-danger alert-dismissible1 success_hide"
                      id="create-group-error"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <p className="jquery-success-msg">
                        Join my group order! {this.state.shareGroupUrl}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          id="copygroup-popup"
          className="modal fade commom-modal-topcls sharegroup-popup"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body">
                <div className="common-modal-head">
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    X
                  </button>
                </div>
                <div className="sharegroup-body textcenter">
                  <div className="sharegroup-body-info">
                    <img src={groupred} alt="" />
                    <h4>Start Your Group Order</h4>
                    <p>
                      Easily order and customise meals for large groups! Share
                      the link with your guests and they can add their dishes.
                      Once they’ve finished adding, you can checkout.{" "}
                    </p>
                  </div>
                  <div className="sharegroup-form-info">
                    <span>Share this link</span>
                    <input
                      type="text"
                      className="copy-share-text"
                      id="shareGroupUrl"
                      placeholder={siteURL + "grouporder/12345678"}
                      value={this.state.shareGroupUrl}
                    />
                    <div className="sharegroup-form-single-btn">
                      <a
                        href="#"
                        className="button"
                        onClick={this.copyGropurl.bind(this)}
                      >
                        Copy & Share
                      </a>
                    </div>
                    <div
                      className="custom_alertcls alert-success alert-dismissible1 success_hide"
                      id="coppy-success-msg"
                      style={{ display: "none" }}
                    >
                      {" "}
                      <p className="jquery-success-msg">
                        Join my group order! {this.state.shareGroupUrl}
                      </p>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var blacksArr = Array();
  if (Object.keys(state.staticblack).length > 0) {
    if (state.staticblack[0].status === "ok") {
      blacksArr = state.staticblack[0].result_set;
    }
  }
  return {
    globalsettings: state.settings,
    alloutlets: state.alloutlets,
    eventList: state.events,
    staticblack: blacksArr,
    siteLocationList: state.sitelocation,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getAllOutlets: (availability, eventslug, shop_type, zip_code) => {
      dispatch({
        type: GET_ALL_OUTLETS,
        availability,
        eventslug,
        shop_type,
        zip_code,
      });
    },
    getEvents: () => {
      dispatch({ type: GET_EVENTS });
    },
    getStaticBlock: () => {
      dispatch({ type: GET_STATIC_BLOCK });
    },
    getloadSiteLocation: () => {
      dispatch({ type: GET_SITE_LOCATION });
    },
  };
};

Mall.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

export default withRouter(connect(mapStateTopProps, mapDispatchToProps)(Mall));

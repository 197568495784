/* eslint-disable */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import cookie from "react-cookies";
import axios from "axios";
import { validated } from "react-custom-validation";
import update from "immutability-helper";
import { setMinutes, setHours, getDay, format } from "date-fns";
var dateFormat = require("dateformat");

import {
  appId,
  apiUrl,
  apiUrlV2,
  siteURL,
  apiUrlPro,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
  baseUrl,
  fbAppId,
} from "../Helpers/Config";

import {
  GET_GLOBAL_SETTINGS,
  GET_ZONE_DETAIL,
  GET_PICKUP_OUTLETS,
  GET_ALL_OUTLETS,
  DESTROY_CART_DETAIL,
  GET_LOGINDATA,
  GET_FORGET_PASSWORD,
  GET_REGISTRATION,
  GET_MENUDATA,
  GET_ALLUSERSECADDRDATA,
  GET_CART_DETAIL,
  GET_SITE_LOCATION,
  GET_CHANGEPASSWORD,
} from "../../actions";
import {
  getReferenceID,
  showAlert,
  showLoader,
  hideLoader,
  stripslashes,
  removeOrderDateTime,
  removePromoCkValue,
  addressFormat,
  loadHeaderTime,
} from "../Helpers/SettingHelper";

import MenuNavigationmob from "./MenuNavigation/MenuNavigationmob";
import OrderdatetimeSlot from "./OrderdatetimeSlot";
import {
  Login,
  Changepassword,
  Forgotpassword,
  Signup,
  Guestcheckout,
  Subscriber,
} from "../../components/Myaccount/Index";

/* import images */
import mainLogo from "../../common/images/logo.png";
import footerLogo from "../../common/images/logo.png";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win.svg";
import warningImg from "../../common/images/warning.svg";

import deliveryWtImg from "../../common/images/food-delivery.svg";
import takeawayImg from "../../common/images/lunch.svg";
import takeawayWtImg from "../../common/images/takeaway-w.svg";
import greentick from "../../common/images/grenn-tick.png";
import loginav from "../../common/images/login-av.png";
import mainav from "../../common/images/av-icon.png";
import subscribtion from "../../common/images/subscribtion.png";

import CartSideBar from "./CartSideBar";

class Header extends Component {
  constructor(props) {
    super(props);
    var availability =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : deliveryId;

    var shopType = "";
    if (
      cookie.load("shopType") !== "" &&
      typeof cookie.load("shopType") !== undefined &&
      typeof cookie.load("shopType") !== "undefined"
    ) {
      shopType = cookie.load("shopType");
    } else {
      /* shopType = cookie.save("shopType", 1); */
      shopType = 1;
    }
    const deliveryPostalCode =
      cookie.load("deliveryPostalCode") !== "" &&
      typeof cookie.load("deliveryPostalCode") !== undefined &&
      typeof cookie.load("deliveryPostalCode") !== "undefined"
        ? cookie.load("deliveryPostalCode")
        : "";

    const orderPostalCode_postal =
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
        ? cookie.load("orderPostalCode")
        : "";

    this.state = {
      currentPageUrl: this.props.location.pathname,
      slugRestaurant:
        this.props.match.params.slugRestaurant !== "" &&
        typeof this.props.match.params.slugRestaurant !== undefined &&
        typeof this.props.match.params.slugRestaurant !== "undefined"
          ? this.props.match.params.slugRestaurant
          : "",
      defaultAvilTy: availability,
      shopType: shopType,
      seletedAvilablityId: "",
      seletedOutletId: "",
      order_tat_time: 0,
      globalsettings: [],
      secondaryaddresslist: [],
      deliveryOutlets: [],
      deliveryOutletsList: [],
      pickupOutlets: [],
      pickupOutletsList: [],
      delivery_outlet_id: "",
      orderHandled: "",
      orderDeliveryAddress: "",
      nextavail: "",
      logindata: "",
      getDateTimeFlg: "",
      deliveryInfo: [],
      pickupInfo: [],
      seleted_ord_date: "",
      seleted_ord_time: "",
      fields: {
        email: "",
        password: "",
      },
      fieldsfgtpassword: {
        email: "",
      },
      fieldscheckout: {
        check_email: "",
        check_firstname: "",
        check_phone: "",
      },
      fpstatus: "initiating",
      regstatus: "initiating",
      fieldssignup: {
        firstname: "",
        email: "",
        password: "",
        repassword: "",
        mobile: "",
        //pdpa_consent: "",
        terms: "",
      },
      fieldschpassword: {
        oldpin: "",
        newpin: "",
        confirmpin: "",
      },
      subscripstatus: "initiating",
      fieldsubscripe: {
        firstname: "",
        lastname: "",
        email: "",
      },
      menuData: [],
      selectedAvilHome: "No",
      cartOutletList: [],
      selectPostalCode: "No",
      selectPickupTime: "No",
      deliveryPostalCode: deliveryPostalCode,
      cartTriggerFlg: "no",
      opentavilablity: false,
      group_order_confirm:
        cookie.load("group_order_confirm") !== "" &&
        typeof cookie.load("group_order_confirm") !== undefined &&
        typeof cookie.load("group_order_confirm") !== "undefined"
          ? cookie.load("group_order_confirm")
          : "",
      seletedAvilablityId_delivery: availability,
      pickup_postal_code: orderPostalCode_postal,
      deliveryPostalCode_home: "",
      cartDetails: [],
      cartlistdetail: [],
      siteLocationList: [],
      site_location_list_value: false,
      pickup_option_true: false,
      delivery_option_true: true,
      deliveryaddresshow: false,
      addactiveclass: "",
    };

    this.props.getGlobalSettings();
    this.props.getPickupOutlets();
    this.props.getCartDetail();
    this.props.getloadSiteLocation();

    var zoneIdTxt =
      typeof cookie.load("orderZoneId") === "undefined"
        ? ""
        : cookie.load("orderZoneId");

    if (availability === deliveryId && zoneIdTxt !== "") {
      this.props.getZoneDetail("", zoneIdTxt, 1);
    } else if (availability === deliveryId) {
      this.findOutletBasedEmptyZone(availability);
    }
    this.props.getAllOutlets(deliveryId);
    this.props.getSecondaryAddress();
  }

  /*forget password  - start*/
  fieldforgot = (field, value) => {
    this.setState(
      update(this.state, { fieldsfgtpassword: { [field]: { $set: value } } })
    );
  };

  forgotpassword = () => {
    this.setState({ fpstatus: "loading" });
    const formPayload = this.state.fieldsfgtpassword;

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      email_address: formPayload.email,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("forgotpassword-cls", "class");
    this.props.getForgetPassword(qs.stringify(postObject));
  };
  /*forget password  - end*/

  /* signin - start*/
  fieldChange = (field, value) => {
    this.setState(update(this.state, { fields: { [field]: { $set: value } } }));
  };

  handleSignin_Submit(email, password) {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: email,
      login_password: password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  }

  handleSignin = () => {
    const formPayload = this.state.fields;
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      login_username: formPayload.email,
      login_password: formPayload.password,
    };
    showLoader("login_submit", "class");
    this.props.getLoginData(qs.stringify(postObject));
  };
  /* signin - end*/

  /* for signup - start*/
  fieldChangeSignup = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    // if (field == "pdpa_consent") {
    //   value = $("#pdpa_consent").prop("checked");
    // }
    this.setState(
      update(this.state, { fieldssignup: { [field]: { $set: value } } })
    );
  };

  handleSignup = () => {
    const formPayload = this.state.fieldssignup;
    this.setState({ regstatus: "loading" });

    var pdpaConsent = "yes";

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.firstname,
      customer_email: formPayload.email,
      customer_password: formPayload.password,
      customer_phone: formPayload.mobile,
      customer_pdpa_consent: pdpaConsent,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    showLoader("signup_submit", "class");
    this.props.getRegistration(qs.stringify(postObject));
  };
  /* for signup - end*/

  /* Change Password */
  fieldChangepassword = (field, value) => {
    this.setState(
      update(this.state, { fieldschpassword: { [field]: { $set: value } } })
    );
  };

  changepassword = () => {
    var old_pin = $(".old_password").val();
    var new_pin = $(".new_password").val();
    var confirm_pin = $(".confirm_password").val();
    var postObject = {
      app_id: appId,
      type: "web",
      oldpassword: old_pin,
      password: new_pin,
      confirmpassword: confirm_pin,
      refrence: cookie.load("UserId"),
      passwordtype: "PIN",
    };
    showLoader("change-pass-sbmt", "class");
    var qs = require("qs");
    this.props.getChangePassword(qs.stringify(postObject));
  };

  /* for subscripe - start*/
  fieldChangeSubscripe = (field, value) => {
    if (field == "terms") {
      value = $("#terms").prop("checked");
    }
    if (field == "pdpa_consent") {
      value = $("#pdpa_consent").prop("checked");
    }

    this.setState(
      update(this.state, { fieldsubscripe: { [field]: { $set: value } } })
    );
  };

  handleSubscripe = () => {
    const formPayload = this.state.fieldsubscripe;
    this.setState({ subscripstatus: "loading" });
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      customer_first_name: formPayload.firstname,
      customer_last_name: formPayload.lastname,
      customer_email: formPayload.email,
      status: "A",
      unsubcriber_status: "A",
    };

    showLoader("subscribe_submit", "class");

    axios
      .post(apiUrl + "cms/newsletter", qs.stringify(postObject))
      .then((res) => {
        hideLoader("subscribe_submit", "class");

        if (res.data.status === "ok") {
          showAlert("Success", res.data.message);
          /* this.setState({
            regstatus: "ok",
          }); */
        } else {
          showAlert("Alert", res.data.message);
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      });
  };

  /* for subscripe - end*/

  componentWillReceiveProps(PropsDt) {
    if (PropsDt.hometakeawaypopup !== "" && PropsDt.hometakeawaypopup === 1) {
      if (
        PropsDt.deliveryPostalCode_home !== "" &&
        PropsDt.deliveryPostalCode_home !== undefined
      ) {
        this.setState(
          {
            deliveryPostalCode_home: PropsDt.deliveryPostalCode_home,
            pickup_option_true: true,
          },
          function () {
            if (parseInt(PropsDt.deliveryPostalCode_home.length) >= 6) {
              $(".postal_error_home").hide();
              this.setState({ addactiveclass: "" });
              this.findOutletBasedZone_home(1, deliveryId);
            }
          }
        );
      }
    }

    if (PropsDt.menudata !== this.props.menudata) {
      this.setState({ menudata: PropsDt.menudata[0].result_set });
    }

    if (PropsDt.selectedAvilHome !== this.props.selectedAvilHome) {
      this.setState(
        {
          seletedAvilablityId: PropsDt.selectedAvilHome,
          seletedAvilablityId_delivery: PropsDt.selectedAvilHome,
          selectedAvil: PropsDt.selectedAvilHome,
          seletedAvilablityId_delivery: PropsDt.selectedAvilHome,
        },
        function () {
          this.pickOutlet(this.state.pickupOutlets[0]);
        }
      );
    }

    if (PropsDt.outletslist !== this.state.pickupOutletsList) {
      this.setState({
        pickupOutlets: PropsDt.outletslist,
        pickupOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.alloutletslist !== this.state.deliveryOutletsList) {
      this.setState({
        deliveryOutlets: PropsDt.alloutletslist,
        deliveryOutletsList: PropsDt.outletslist,
      });
    }

    if (PropsDt.secondaryaddresslist !== this.state.secondaryaddresslist) {
      this.setState({ secondaryaddresslist: PropsDt.secondaryaddresslist });
    }

    if ("homePageState" in PropsDt) {
      if (
        PropsDt.homePageState.nextavail !== undefined &&
        PropsDt.homePageState.nextavail !== "" &&
        PropsDt.homePageState.nextavail !== this.state.nextavail
      ) {
        this.setState({ nextavail: PropsDt.homePageState.nextavail });
      }
    }

    if (PropsDt.fbloginData !== this.props.fbloginData) {
      this.doLogin(PropsDt.fbloginData);
    }

    if (PropsDt.logindata !== this.props.logindata) {
      this.doLogin(PropsDt.logindata[0]);
    }

    if (this.state.fpstatus === "loading") {
      if (PropsDt.forgetpassword !== undefined) {
        this.setState({ fpstatus: "ok" });
        this.showMessage(PropsDt.forgetpassword[0]);
      }
    }

    if (this.state.regstatus === "loading") {
      if (
        PropsDt.registration !== undefined &&
        PropsDt.registration.length > 0
      ) {
        this.setState({
          regstatus: "ok",
        });
        const formPayloads = this.state.fieldssignup;

        if (PropsDt.registration.length > 0) {
          this.showMessage_registration(PropsDt.registration[0]);
        }
      }
    }

    if (this.state.defaultAvilTy !== PropsDt.changeavail) {
      if (PropsDt.changeavail !== "" && PropsDt.changeavail !== undefined) {
        this.setState({ defaultAvilTy: PropsDt.changeavail });
      }
    }
    if (this.state.selectPostalCode !== PropsDt.selectPostalCode) {
      if (
        PropsDt.selectPostalCode !== "" &&
        PropsDt.selectPostalCode !== undefined
      ) {
        this.setState({ selectPostalCode: PropsDt.selectPostalCode });
      }
    }
    if (this.state.selectPickupTime !== PropsDt.selectPickupTime) {
      if (
        PropsDt.selectPickupTime !== "" &&
        PropsDt.selectPickupTime !== undefined
      ) {
        this.findOutletBasedZone("", pickupId);
        this.setState({
          selectPickupTime: PropsDt.selectPickupTime,
          seletedAvilablityId: pickupId,
        });
      }
    }

    if (PropsDt.globalsettings !== this.state.globalsettings) {
      if (
        PropsDt.globalsettings[0] !== "" &&
        PropsDt.globalsettings[0] !== undefined
      ) {
        this.setState({ globalsettings: PropsDt.globalsettings[0].result_set });
      }
    }

    if (PropsDt.changepassword !== this.props.changepassword) {
      $(".old_password").val("");
      $(".new_password").val("");
      $(".confirm_password").val("");
      hideLoader("change-pass-sbmt", "class");
      if (PropsDt.changepassword[0].status === "ok") {
        showAlert("Success", "Password changed successfully!");
      } else {
        if (PropsDt.changepassword[0].form_error) {
          var string_replace_password = PropsDt.changepassword[0].form_error;
          showAlert(
            "Notice",
            string_replace_password.replaceAll("PIN", "Password")
          );
        } else {
          var string_replace_message = PropsDt.changepassword[0].message;
          showAlert(
            "Notice",
            string_replace_message.replaceAll("PIN", "Password")
          );
        }
      }
      window.$.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }

    if (PropsDt.siteLocationList !== this.state.siteLocationList) {
      if (Object.keys(PropsDt.siteLocationList).length > 0) {
        if (PropsDt.siteLocationList[0].status === "ok") {
          this.setState(
            { siteLocationList: PropsDt.siteLocationList[0].result_set },
            function () {
              this.siteLocation_address_list();
            }
          );
        }
      }
    }
  }

  /* main - menu navigation -start*/

  createLink(menu) {
    if (menu.nav_type === "0" && menu.nav_parent_title == "") {
      return (
        <Link to={"/page/" + menu.nav_title_slug} title={menu.nav_title}>
          <span>{menu.nav_title}</span>
        </Link>
      );
    } else if (menu.nav_type === "3" && menu.nav_parent_title == "") {
      var pageUrlTxt = menu.nav_pages;
      if (pageUrlTxt.includes("http")) {
        return (
          <a
            href={menu.nav_pages}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </a>
        );
      } else {
        return (
          <Link
            to={menu.nav_pages != "#" ? "/" + menu.nav_pages : ""}
            title={menu.nav_title}
            target={menu.nav_link_type == "blank" ? "_blank" : ""}
          >
            <span>{menu.nav_title}</span>
          </Link>
        );
      }
    }
  }

  createSubmenu(menu, type) {
    if (menu.nav_parent_title === "") {
      if (this.state.menudata) {
        var checkIngVal = 0;
        var liTxt = this.state.menudata.map(function (menuparent, i) {
          if (menu.nav_id == menuparent.nav_parent_title) {
            checkIngVal = 1;
            if (menuparent.nav_type === "0") {
              return (
                <li key={i + 100}>
                  <Link
                    to={"/page/" + menuparent.nav_title_slug}
                    title={menuparent.nav_title}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            } else {
              return (
                <li key={i + 100}>
                  <Link
                    to={
                      menuparent.nav_pages != "#"
                        ? "/" + menuparent.nav_pages
                        : ""
                    }
                    title={menuparent.nav_title}
                    target={menuparent.nav_link_type == "blank" ? "_blank" : ""}
                  >
                    <span>{menuparent.nav_title}</span>
                  </Link>
                </li>
              );
            }
          }
        }, this);

        if (type === "span" && checkIngVal === 1) {
          return <a href="/" className="submenu-arow disbl_href_action"></a>;
        } else if (type === "ul" && checkIngVal === 1) {
          return <ul className="submenu_list">{liTxt}</ul>;
        } else {
          return "";
        }
      }
    }
  }

  menuActiveCls(nav_pages) {
    var currenturl = window.location.href;
    var returnClsTx = "";
    if (
      nav_pages === "home" ||
      nav_pages === "#" ||
      nav_pages === "brandlist"
    ) {
      returnClsTx =
        this.props.match.path === "/home" ||
        this.props.match.path === "/" ||
        this.props.match.path === "/brandlist"
          ? "active"
          : "";
    } else if (nav_pages === "products") {
      returnClsTx =
        this.props.match.path === "/products" ||
        this.props.match.path === "/checkout" ||
        this.props.match.path === "/thankyou"
          ? "active"
          : "";
    } else {
      returnClsTx = currenturl.includes(nav_pages) ? "active" : "";
    }
    return returnClsTx;
  }

  listMainNavigation() {
    if (this.state.menudata) {
      return this.state.menudata.map(function (menu, i) {
        return (
          <li key={i + 100} className={this.menuActiveCls(menu.nav_pages)}>
            {this.createLink(menu)}
            {this.createSubmenu(menu, "span")}
            {this.createSubmenu(menu, "ul")}
          </li>
        );
      }, this);
    }
  }

  /* menu navigation -end */

  /* show message */
  showMessage_registration(response) {
    hideLoader("signup_submit", "class");
    if (response.status === "ok") {
      this.showAlert_manual(
        "ACCOUNT CREATED",
        "Almost there! Check your email for an activation <br/> link to activate your account."
      );
    } else {
      if (response.form_error) {
        showAlert("Notice", response.form_error);
      } else {
        showAlert("Notice", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  /* show Alert */
  showAlert_manual(header, message, autoClose = "No") {
    $(".alert_popup").remove();
    $("body").append(
      '<div class="white-popup mfp-hide popup_sec alert_popup custom-alrt-popupcls" ><div class="custom_alert"><div class="custom_alertin"><div class="alert_height"><div class="alert_header">' +
        header +
        '</div><div class="alert_body"><p>' +
        message +
        '</p><div class="alt_btns"><a href="javascript:;" class="popup-modal-dismiss button">OK</a></div></div></div></div></div></div>'
    );
  }

  /* show message */
  showMessage(response) {
    hideLoader("signup_submit", "class");
    hideLoader("forgotpassword-cls", "class");
    if (response.status === "ok") {
      showAlert("Success", response.message);
    } else {
      if (response.form_error) {
        showAlert("Notice", response.form_error);
      } else {
        showAlert("Notice", response.message);
      }
    }
    $.magnificPopup.open({
      items: {
        src: ".alert_popup",
      },
      type: "inline",
    });
  }

  chooseAvailabilityMbl() {
    var availability = this.state.defaultAvilTy;
    var showtext = "Order Now";
    if (availability === deliveryId) {
      showtext = "Delivery";
    } else if (availability === pickupId) {
      showtext = "Takeaway";
    }
    return showtext;
  }

  /* for login and facebook login*/
  doLogin(fbloginData) {
    hideLoader("login_submit", "class");
    if (fbloginData.status === "ok") {
      $.magnificPopup.close();
      var mobileno = "",
        cust_birthdate = "";
      if (
        typeof fbloginData.result_set.customer_phone === "undefined" ||
        fbloginData.result_set.customer_phone === "null" ||
        fbloginData.result_set.customer_phone === ""
      ) {
        mobileno = "";
      } else {
        mobileno = fbloginData.result_set.customer_phone;
      }

      if (
        typeof fbloginData.result_set.customer_birthdate !== "undefined" &&
        fbloginData.result_set.customer_birthdate !== "null" &&
        fbloginData.result_set.customer_birthdate !== null &&
        fbloginData.result_set.customer_birthdate !== "" &&
        fbloginData.result_set.customer_birthdate !== "0000-00-00"
      ) {
        cust_birthdate = fbloginData.result_set.customer_birthdate;
      }

      cookie.save("UserId", fbloginData.result_set.customer_id, { path: "/" });
      cookie.save("UserEmail", fbloginData.result_set.customer_email, {
        path: "/",
      });
      cookie.save(
        "UserFname",
        fbloginData.result_set.customer_first_name !== ""
          ? fbloginData.result_set.customer_first_name
          : "",
        { path: "/" }
      );
      cookie.save(
        "UserLname",
        fbloginData.result_set.customer_last_name !== ""
          ? fbloginData.result_set.customer_last_name
          : "",
        { path: "/" }
      );
      cookie.save("UserMobile", mobileno, { path: "/" });
      cookie.save("UserBirthdate", cust_birthdate, { path: "/" });

      cookie.save(
        "UserDefaultAddress",
        fbloginData.result_set.customer_address_name,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitOne",
        fbloginData.result_set.customer_address_line1,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultUnitTwo",
        fbloginData.result_set.customer_address_line2,
        { path: "/" }
      );
      cookie.save(
        "UserDefaultPostalCode",
        fbloginData.result_set.customer_postal_code,
        { path: "/" }
      );
      cookie.save("userAccountType", 2, { path: "/" });

      const { history } = this.props;

      if (cookie.load("cateringPay") === "start") {
        showAlert("Success", "Logged in Successfully!");
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
        location.href =
          siteURL +
          "payment/" +
          cookie.load("orderIdTxt") +
          "/" +
          cookie.load("paymentIdTxt");
        /*  history.push(
          "/payment/" +
            cookie.load("orderIdTxt") +
            "/" +
            cookie.load("paymentIdTxt")
        ); */
        /*history.push("/payment");*/
      } else {
        var qs = require("qs");
        var postObject = {
          app_id: appId,
          reference_id: getReferenceID(),
          customer_id: fbloginData.result_set.customer_id,
          availability_id: this.state.defaultAvilTy,
        };

        axios
          .post(apiUrl + "cart/update_customer_info", qs.stringify(postObject))
          .then((res) => {
            showAlert("Success", "Logged in successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            if (
              res.data.contents !== "" &&
              typeof res.data.contents !== undefined &&
              typeof res.data.contents !== "undefined" &&
              res.data.contents !== null
            ) {
              var cartDetails = res.data.contents.cart_details;
              cookie.save("shopType", cartDetails.cart_shop_type, {
                path: "/",
              });
              cookie.save("orderOutletId", cartDetails.outlet_id, {
                path: "/",
              });
              cookie.save("outletLocationId", cartDetails.cart_location_id, {
                path: "/",
              });
              cookie.save(
                "defaultAvilablityId",
                cartDetails.cart_availability_id,
                {
                  path: "/",
                }
              );
              cookie.save("orderZoneId", cartDetails.outletzone_id, {
                path: "/",
              });
            }
            if (res.data.status === "ok") {
              if (this.props.match.path === "/brands/:brandSlug/reservations") {
                this.props.sateValChange("proceed-reservation", "Yes");
              } else if (cookie.load("checkoutredirect") === "yes") {
                cookie.remove("loginpopupTrigger", { path: "/" });
                location.href = siteURL + "checkout";
                //history.push("/checkout");
              } else if (
                cookie.load("loginpopupTrigger") === "fromchecatering"
              ) {
                cookie.remove("loginpopupTrigger", { path: "/" });
                history.push("/catering");
              } else if (cookie.load("redirectReservatin") === "Yes") {
                cookie.remove("redirectReservatin", { path: "/" });
                history.push("/reservation");
              } else {
                if (this.props.match.path === "/food/:slugRestaurant/") {
                  location.reload();
                } else if (
                  this.props.match.path ===
                  "/food/:siteLocation/:slugRestaurant/"
                ) {
                  location.reload();
                } else if (
                  cookie.load("redirectProducts") === "Yes" &&
                  this.props.match.path ===
                    "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
                ) {
                  location.reload();
                } else {
                  history.push("/myaccount");
                }
              }
            } else {
              if (this.props.match.path === "/brands/:brandSlug/reservations") {
                this.props.sateValChange("proceed-reservation", "Yes");
              } else if (
                this.props.match.path === "/food/:siteLocation/:slugRestaurant/"
              ) {
                location.reload();
              } else if (
                cookie.load("redirectProducts") === "Yes" &&
                this.props.match.path ===
                  "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
              ) {
                location.reload();
              } else {
                if (
                  cookie.load("openGroupShare") !== "" &&
                  typeof cookie.load("openGroupShare") !== undefined &&
                  typeof cookie.load("openGroupShare") !== "undefined"
                ) {
                  location.reload();
                } else {
                  history.push("/myaccount");
                }
              }
            }
          });
      }
    } else {
      cookie.remove("loginpopupTrigger", { path: "/" });
      /*showAlert('Error', 'Invalid Login Credentials','trigger_login','#login-popup');*/

      if (fbloginData.message == "Invalid email") {
        showAlert("Notice", "Username/password not recognised");
      } else {
        showAlert("Notice", "Invalid Login Credentials");
      }

      $.magnificPopup.open({
        items: {
          src: ".alert_popup",
        },
        type: "inline",
      });
    }
  }

  /*  Onchange  for Guest Checkout */
  fieldChangecheckout = (field, value) => {
    this.setState(
      update(this.state, { fieldscheckout: { [field]: { $set: value } } })
    );
  };

  handleCheckout = () => {
    const formPayload = this.state.fieldscheckout;
    if (
      document.getElementById("spn-email-error").innerHTML ===
      '<span class="error">This email already exists</span>'
    ) {
      return false;
    }
    if (
      document.getElementById("spn-mobile-error").innerHTML ===
      '<span class="error">This mobile number already exists</span>'
    ) {
      return false;
    }

    showLoader("login_in_guest", "class");

    var qs = require("qs");
    var postObject = {
      app_id: appId,
      type: "web",
      customer_first_name: formPayload.check_firstname,
      customer_email: formPayload.check_email,
      customer_phone: formPayload.check_phone,
      site_url: this.props.globalsettings[0].result_set.client_site_url,
    };

    axios
      .post(apiUrl + "guestaccount/create", qs.stringify(postObject))
      .then((response) => {
        hideLoader("login_in_guest", "class");
        if (response.data.status === "ok") {
          window.scrollTo(0, 0);
          $.magnificPopup.close();

          var cust_birthdate = "";
          if (
            typeof response.data.result_set.customer_birthdate !==
              "undefined" &&
            response.data.result_set.customer_birthdate !== "null" &&
            response.data.result_set.customer_birthdate !== null &&
            response.data.result_set.customer_birthdate !== "" &&
            response.data.result_set.customer_birthdate !== "0000-00-00"
          ) {
            cust_birthdate = response.data.result_set.customer_birthdate;
          }

          /* set User cookie values - Start */
          cookie.save("UserId", response.data.result_set.customer_id, {
            path: "/",
          });
          cookie.save(
            "UserFname",
            response.data.result_set.customer_first_name !== ""
              ? response.data.result_set.customer_first_name
              : "",
            { path: "/" }
          );
          cookie.save(
            "UserLname",
            response.data.result_set.customer_last_name != ""
              ? response.data.result_set.customer_last_name
              : "",
            { path: "/" }
          );
          cookie.save("UserMobile", response.data.result_set.customer_phone, {
            path: "/",
          });
          cookie.save("UserEmail", response.data.result_set.customer_email, {
            path: "/",
          });
          cookie.save(
            "UserDefaultAddress",
            response.data.result_set.customer_address_name,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitOne",
            response.data.result_set.customer_address_line1,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultUnitTwo",
            response.data.result_set.customer_address_line2,
            { path: "/" }
          );
          cookie.save(
            "UserDefaultPostalCode",
            response.data.result_set.customer_postal_code,
            { path: "/" }
          );
          cookie.save("UserBirthdate", cust_birthdate, { path: "/" });
          cookie.save("userAccountType", 1, { path: "/" });
          const { history } = this.props;

          if (cookie.load("cateringPay") === "start") {
            showAlert("Success", "Logged in Successfully!");
            $.magnificPopup.open({
              items: {
                src: ".alert_popup",
              },
              type: "inline",
            });
            history.push(
              "/payment/" +
                cookie.load("orderIdTxt") +
                "/" +
                cookie.load("paymentIdTxt")
            );
            /*history.push("/payment");*/
          } else {
            var qs = require("qs");
            var postObject = {
              app_id: appId,
              reference_id: getReferenceID(),
              customer_id: response.data.result_set.customer_id,
              availability_id: this.state.defaultAvilTy,
            };

            axios
              .post(
                apiUrl + "cart/update_customer_info",
                qs.stringify(postObject)
              )
              .then((res) => {
                showAlert("Success", "Logged in Successfully!");
                $.magnificPopup.open({
                  items: {
                    src: ".alert_popup",
                  },
                  type: "inline",
                });
                if (res.data.status === "ok") {
                  if (cookie.load("checkoutredirect") === "yes") {
                    cookie.remove("loginpopupTrigger", { path: "/" });
                    location.href = siteURL + "checkout";
                  } else if (
                    cookie.load("loginpopupTrigger") === "fromchecatering"
                  ) {
                    cookie.remove("loginpopupTrigger", { path: "/" });
                    history.push("/catering");
                  } else if (cookie.load("redirectReservatin") === "Yes") {
                    cookie.remove("redirectReservatin", { path: "/" });
                    history.push("/reservation");
                  } else if (
                    cookie.load("redirectProducts") === "Yes" &&
                    this.props.match.path ===
                      "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
                  ) {
                    location.reload();
                  } else {
                    history.push("/myaccount");
                  }
                } else {
                  if (cookie.load("redirectReservatin") === "Yes") {
                    cookie.remove("redirectReservatin", { path: "/" });
                    history.push("/reservation");
                  } else if (
                    cookie.load("redirectProducts") === "Yes" &&
                    this.props.match.path ===
                      "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue"
                  ) {
                    location.reload();
                  } else {
                    history.push("/myaccount");
                  }
                }
              });
          }
        } else {
          if (response.data.form_error) {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.form_error +
                "</div>"
            );
          } else {
            $(".guest-chk-error").html(
              '<div class="alert alert_danger" style="display:block">' +
                response.data.message +
                "</div>"
            );
          }
        }
        return false;
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  closepopup(event) {
    event.preventDefault();

    $.magnificPopup.open({
      items: {
        src: "#order-popup",
      },
      type: "inline",
    });
  }

  openShopmall(event) {
    event.preventDefault();
    this.props.history.push("/shop");
  }

  close_popup(event) {
    event.preventDefault();
    $.magnificPopup.close();
  }

  gobckOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#awesome-popup",
      },
      type: "inline",
    });
  }

  gobckPkupOutletpopup() {
    $.magnificPopup.open({
      items: {
        src: "#awesome-popup",
      },
      type: "inline",
    });
  }

  changeAvailability() {
    var tempArr = [],
      tempVl = "";

    this.setState({
      seletedOutletId: tempVl,
      deliveryInfo: tempArr,
      pickupInfo: tempArr,
      seleted_ord_date: tempVl,
      seleted_ord_time: tempVl,
      opentavilablity: false,
    });

    var change_shop_type = $("#change_shop_type").val();
    if ($("#changeaddress").length > 0 && $("#changeaddress").val() !== "") {
      cookie.save("changeaddress", "yes", { path: "/" });
    }

    if (
      $("#change_site_location").length > 0 &&
      $("#change_site_location").val() !== ""
    ) {
      cookie.save("outletLocationId", $("#change_site_location").val(), {
        path: "/",
      });
      this.destroyCartShopeType();
      return false;
    }

    if (change_shop_type !== "") {
      this.destroyCartShopeType("");
      cookie.save("shopType", change_shop_type, { path: "/" });

      return false;
    } else {
      this.destroyCart("Yes");
    }

    var popupIdtxt = "";
    if (this.state.nextavail === deliveryId) {
      popupIdtxt = "#awesome-popup";
    } else if (this.state.nextavail === pickupId) {
      popupIdtxt = "#awesome-popup";
    }
    if (
      $("#changeaddressheader").length > 0 &&
      $("#changeaddressheader").val() !== ""
    ) {
      popupIdtxt = "#awesome-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  changeAvailabilityheader(availability, event) {
    event.preventDefault();
    if (availability === deliveryId) {
      console.log("remove postal");
      $("#popuppostalcode").val("");
      cookie.remove("deliveryPostalCode", { path: "/" });
      cookie.remove("nearest_postal_code", { path: "/" });
      cookie.remove("orderPostalCode", { path: "/" });
      this.setState({ pickup_postal_code: "" });
    }

    if (this.props.match.path === "/marketplace") {
      if (availability === deliveryId && this.state.deliveryPostalCode === "") {
        this.setState({
          selectPostalCode: "Yes",
          defaultAvilTy: deliveryId,
          delivery_option_true: true,
        });

        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        });
        return false;
      } else {
        if (availability === deliveryId) {
          this.setState({
            seletedAvilablityId_delivery: availability,
            defaultAvilTy: availability,
            seletedAvilablityId: availability,
            delivery_option_true: true,
          });

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          this.findOutletBasedZone("1", deliveryId);
        }
      }

      var orderDateTime = cookie.load("orderDateTime");

      if (availability === pickupId) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.loadPickupTimePopup(pickupId);
      }

      if (availability === pickupId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: pickupId,
          seletedAvilablityId: pickupId,
          delivery_option_true: false,
        });
      } else if (availability === deliveryId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          seletedAvilablityId: deliveryId,
          delivery_option_true: true,
        });
      }

      if (this.state.defaultAvilTy !== availability) {
        cookie.remove("defaultAvilablityId", { path: "/" });
        cookie.save("defaultAvilablityId", availability, { path: "/" });
        this.setState(
          {
            defaultAvilTy: availability,
            cartTriggerFlg: "yes",
            seletedAvilablityId: availability,
            seletedAvilablityId_delivery: availability,
          },
          function () {
            showLoader("innerproduct", "class");
            axios
              .get(
                apiUrl +
                  "cart/update_availability?app_id=" +
                  appId +
                  "&reference_id=" +
                  getReferenceID() +
                  "&customer_id=" +
                  cookie.load("UserId") +
                  "&availability_id=" +
                  availability
              )
              .then((res) => {
                cookie.save("defaultAvilablityId", availability, { path: "/" });
                this.props.sateValChange(
                  "loadOutlet",
                  availability + "_" + this.state.deliveryPostalCode
                );
              });
          }
        );
      }
    } else if (
      this.props.match.path === "/mall/:sluglocation" ||
      this.props.match.path === "/retail/:sluglocation" ||
      this.props.match.path === "/brands/:brandSlug" ||
      this.props.match.path === "/brandlist"
    ) {
      if (availability === deliveryId && this.state.deliveryPostalCode === "") {
        $("#postalcode").val("");
        this.setState({
          selectPostalCode: "Yes",
          defaultAvilTy: deliveryId,
          seletedAvilablityId: deliveryId,
          delivery_option_true: true,
        });
        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        });
        return false;
      } else {
        if (availability === deliveryId) {
          this.setState({
            seletedAvilablityId_delivery: availability,
            defaultAvilTy: availability,
            seletedAvilablityId: availability,
            delivery_option_true: false,
          });

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          // this.findOutletBasedZone("1", deliveryId);
        }
      }

      var orderDateTime = cookie.load("orderDateTime");

      if (availability === pickupId) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.loadPickupTimePopup(pickupId);
      }

      if (availability === pickupId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: pickupId,
          seletedAvilablityId: pickupId,
          delivery_option_true: false,
        });
      } else if (availability === deliveryId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          availability: deliveryId,
          delivery_option_true: true,
        });
      }

      if (this.state.defaultAvilTy !== availability) {
        cookie.remove("defaultAvilablityId", { path: "/" });
        cookie.save("defaultAvilablityId", availability, { path: "/" });
        this.setState(
          {
            defaultAvilTy: availability,
            cartTriggerFlg: "yes",
            seletedAvilablityId: availability,
            seletedAvilablityId_delivery: availability,
          },
          function () {
            showLoader("innerproduct", "class");
            axios
              .get(
                apiUrl +
                  "cart/update_availability?app_id=" +
                  appId +
                  "&reference_id=" +
                  getReferenceID() +
                  "&customer_id=" +
                  cookie.load("UserId") +
                  "&availability_id=" +
                  availability
              )
              .then((res) => {
                cookie.save("defaultAvilablityId", availability, { path: "/" });
                this.props.sateValChange(
                  "loadOutlet",
                  availability + "_" + this.state.deliveryPostalCode
                );
              });
          }
        );
      }
    } else if (
      this.props.match.path === "/food/:siteLocation/:slugRestaurant/" ||
      this.props.match.path === "/retail/:siteLocation/:slugRestaurant/"
    ) {
      if (availability === deliveryId && this.state.deliveryPostalCode === "") {
        this.setState({
          selectPostalCode: "Yes",
          seletedAvilablityId: availability,
          delivery_option_true: true,
        });
        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        });
        return false;
      } else {
        if (availability === deliveryId) {
          this.setState({
            seletedAvilablityId_delivery: availability,
            defaultAvilTy: availability,
            seletedAvilablityId: availability,
            delivery_option_true: true,
          });

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          // this.findOutletBasedZone("1", deliveryId);
        }
      }

      var orderDateTime = cookie.load("orderDateTime");

      if (availability === pickupId) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.loadPickupTimePopup(pickupId);
      }

      if (availability === pickupId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: pickupId,
          seletedAvilablityId: pickupId,
          delivery_option_true: false,
        });
      } else if (availability === deliveryId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          availability: deliveryId,
          delivery_option_true: true,
        });
      }

      if (this.state.defaultAvilTy !== availability) {
        axios
          .get(
            apiUrlV2 +
              "outlets/checkOutletAvailability?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&outlet_slug=" +
              this.props.match.params.slugRestaurant
          )
          .then((response) => {
            if (response.data.status === "ok") {
              cookie.save("defaultAvilablityId", availability, { path: "/" });
              this.setState(
                {
                  defaultAvilTy: availability,
                  load_product: "No",
                  seletedAvilablityId: availability,
                  seletedAvilablityId_delivery: availability,
                },
                function () {
                  axios
                    .get(
                      apiUrl +
                        "cart/update_availability?app_id=" +
                        appId +
                        "&reference_id=" +
                        getReferenceID() +
                        "&customer_id=" +
                        cookie.load("UserId") +
                        "&availability_id=" +
                        availability
                    )
                    .then((res) => {
                      cookie.save("defaultAvilablityId", availability, {
                        path: "/",
                      });
                      this.setState({ cartTriggerFlg: "yes" }, function () {
                        this.setState({ cartTriggerFlg: "yes" });
                      });
                    });
                }
              );
            } else {
              $.magnificPopup.open({
                items: {
                  src: "#warning-not-deliver",
                },
                type: "inline",
              });
            }
          });
      }
    } else {
      if (availability === deliveryId && this.state.deliveryPostalCode === "") {
        this.setState({
          selectPostalCode: "Yes",
          defaultAvilTy: deliveryId,
          seletedAvilablityId: availability,
          delivery_option_true: true,
        });
        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        });
        return false;
      } else {
        if (availability === deliveryId) {
          this.setState({
            seletedAvilablityId_delivery: availability,
            defaultAvilTy: availability,
            seletedAvilablityId: availability,
            delivery_option_true: true,
          });

          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          this.findOutletBasedZone("1", deliveryId);
        }
      }

      if (availability === pickupId) {
        cookie.save("defaultAvilablityId", pickupId, { path: "/" });
        this.setState({ defaultAvilTy: pickupId });
        this.loadPickupTimePopup(pickupId);
      }

      if (availability === pickupId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: availability,
          delivery_option_true: false,
        });
        this.setState({ defaultAvilTy: pickupId });
      } else if (availability === deliveryId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: availability,
          delivery_option_true: true,
        });
      }

      if (this.state.defaultAvilTy !== availability) {
        cookie.remove("defaultAvilablityId", { path: "/" });
        cookie.save("defaultAvilablityId", availability, { path: "/" });
        this.setState(
          { defaultAvilTy: availability, cartTriggerFlg: "yes" },
          function () {
            showLoader("innerproduct", "class");
            axios
              .get(
                apiUrl +
                  "cart/update_availability?app_id=" +
                  appId +
                  "&reference_id=" +
                  getReferenceID() +
                  "&customer_id=" +
                  cookie.load("UserId") +
                  "&availability_id=" +
                  availability
              )
              .then((res) => {
                cookie.save("defaultAvilablityId", availability, { path: "/" });
                this.props.sateValChange(
                  "loadOutlet",
                  availability + "_" + this.state.deliveryPostalCode
                );
              });
          }
        );
      }
    }
  }

  loadPickupTimePopup(availability) {
    var default_outlet_id =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== "undefined" &&
      typeof cookie.load("orderOutletId") !== undefined
        ? cookie.load("orderOutletId")
        : "";

    axios
      .all([
        axios.get(
          apiUrl +
            "outlets/pickup_outlets?app_id=" +
            appId +
            "&availability=" +
            availability +
            "&outlet_id=" +
            default_outlet_id
        ),
        axios.get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            availability
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var pickupInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            //$.magnificPopup.close();
            var resultSet = res.data.result_set[0];
            var orderDeliveryAddress =
              resultSet.outlet_unit_number1 +
              " " +
              resultSet.outlet_unit_number2;
            var orderHandled =
              stripslashes(resultSet.outlet_name) +
              ", Crew will be seeing you in " +
              resultSet.outlet_pickup_tat +
              " Minutes";

            pickupInfo["orderOutletId"] = resultSet.outlet_id;
            pickupInfo["orderOutletName"] = stripslashes(resultSet.outlet_name);
            pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
            pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

            pickupInfo["orderHandled"] = orderHandled;
            pickupInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              resultSet.outlet_unit_number1,
              resultSet.outlet_unit_number2
            );

            var orderHandledTextAddress = addressFormat(
              resultSet.outlet_unit_number1,
              resultSet.outlet_unit_number2,
              resultSet.outlet_address_line1,
              resultSet.outlet_address_line2,
              resultSet.outlet_postal_code
            );

            var orderHandledText =
              unitNum +
              ", " +
              resultSet.outlet_address_line1 +
              ", " +
              resultSet.outlet_address_line2 +
              ", " +
              " Singapore " +
              resultSet.outlet_postal_code;

            pickupInfo["orderHandledByText"] = orderHandledTextAddress;

            this.setState({
              pickupInfo: pickupInfo,
              seletedAvilablityId: availability,
              defaultAvilTy: availability,
              seletedAvilablityId_delivery: availability,
              seletedOutletId: resultSet.oa_outlet_id,
              order_tat_time: resultSet.outlet_pickup_tat,
              orderHandled: orderHandled,
              orderDeliveryAddress:
                orderDeliveryAddress +
                " Singapore " +
                resultSet.outlet_postal_code,
            });

            /* Success time slot response */
            if (timeslt.data.status === "success") {
              this.setState({
                getDateTimeFlg: "yes",
                delivery_option_true: true,
              });
              $.magnificPopup.open({
                items: {
                  src: "#awesome-popup",
                },
                type: "inline",
              });
            } else {
              this.setState({ delivery_option_true: false });
              $.magnificPopup.open({
                items: {
                  src: "#awesome-popup",
                },
                type: "inline",
              });
            }
          }
        })
      );
  }

  changeAvailabilityheader_home(availability, event) {
    event.preventDefault();

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#awesome-popup_home";
      this.setState({ addactiveclass: "" });
    } else if (availability === pickupId) {
      popupIdtxt = "#awesome-popup_home";
      this.setState({ addactiveclass: "active" });
    }

    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }

    if (
      availability == deliveryId &&
      this.state.deliveryPostalCode_home == ""
    ) {
      this.setState({
        selectPostalCode: "Yes",
        defaultAvilTy: deliveryId,
        seletedAvilablityId: availability,
        pickup_option_true: false,
        addactiveclass: "",
      });
      $("#popuppostalcode").val("");
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup_home",
        },
        type: "inline",
      });
      return false;
    } else {
      if (availability == deliveryId) {
        this.setState({
          seletedAvilablityId_delivery: availability,
          defaultAvilTy: availability,
          seletedAvilablityId: availability,
          pickup_option_true: false,
          addactiveclass: "",
        });
        $("#popuppostalcode").val("");
        $.magnificPopup.open({
          items: {
            src: "#awesome-popup_home",
          },
          type: "inline",
        });
      }

      this.findOutletBasedZone_home("1", deliveryId);
    }

    if (availability === pickupId) {
      this.setState({
        seletedAvilablityId_delivery: availability,
        defaultAvilTy: pickupId,
        seletedAvilablityId: pickupId,
        pickup_option_true: false,
        addactiveclass: "active",
      });

      this.findOutletBasedZone_home("1", pickupId);
      //cookie.save("defaultAvilablityId", pickupId, { path: "/" });
      ///var orderDateTime = cookie.load("orderDateTime");
      //  this.loadPickupTimePopup_home(pickupId);
    }

    if (availability === deliveryId) {
      $("#popuppostalcode").val("");
      this.setState({
        seletedAvilablityId_delivery: availability,
        seletedAvilablityId: deliveryId,
        pickup_option_true: false,
      });
    }

    if (this.state.defaultAvilTy !== availability) {
      console.log("fdffsffsfsdfsf");
      $("#popuppostalcode").val("");
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState(
        {
          defaultAvilTy: availability,
          seletedAvilablityId: availability,
          seletedAvilablityId_delivery: availability,
        },
        function () {
          showLoader("innerproduct", "class");
          axios
            .get(
              apiUrl +
                "cart/update_availability?app_id=" +
                appId +
                "&reference_id=" +
                getReferenceID() +
                "&customer_id=" +
                cookie.load("UserId") +
                "&availability_id=" +
                availability
            )
            .then((res) => {
              cookie.save("defaultAvilablityId", availability, { path: "/" });
              this.props.sateValChange(
                "loadOutlet",
                availability + "_" + this.state.deliveryPostalCode_home
              );
            });
        }
      );
    }
  }

  loadPickupTimePopup_home(availability) {
    var default_outlet_id =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== "undefined" &&
      typeof cookie.load("orderOutletId") !== undefined
        ? cookie.load("orderOutletId")
        : "";

    axios
      .all([
        axios.get(
          apiUrl +
            "outlets/pickup_outlets?app_id=" +
            appId +
            "&availability=" +
            availability +
            "&outlet_id=" +
            default_outlet_id
        ),
        axios.get(
          apiUrlV2 +
            "settings/chkTimeslotIsAvaiable?app_id=" +
            appId +
            "&availability_id=" +
            availability
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          var pickupInfo = [];
          /* Success response */
          if (res.data.status === "ok") {
            // $.magnificPopup.close();
            var resultSet = res.data.result_set[0];
            var orderDeliveryAddress =
              resultSet.outlet_unit_number1 +
              " " +
              resultSet.outlet_unit_number2;
            var orderHandled =
              stripslashes(resultSet.outlet_name) +
              ", Crew will be seeing you in " +
              resultSet.outlet_pickup_tat +
              " Minutes";

            pickupInfo["orderOutletId"] = resultSet.outlet_id;
            pickupInfo["orderOutletName"] = stripslashes(resultSet.outlet_name);
            pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
            pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

            pickupInfo["orderHandled"] = orderHandled;
            pickupInfo["defaultAvilablityId"] = availability;

            var unitNum = this.showUnitNum(
              resultSet.outlet_unit_number1,
              resultSet.outlet_unit_number2
            );

            var orderHandledText =
              resultSet.outlet_address_line1 +
              " " +
              resultSet.outlet_address_line2 +
              ", " +
              unitNum +
              " Singapore " +
              resultSet.outlet_postal_code;

            pickupInfo["orderHandledByText"] = orderHandledText;

            this.setState({
              pickupInfo: pickupInfo,
              seletedAvilablityId: availability,
              defaultAvilTy: availability,
              seletedAvilablityId_delivery: availability,
              seletedOutletId: resultSet.oa_outlet_id,
              order_tat_time: resultSet.outlet_pickup_tat,
              orderHandled: orderHandled,
              orderDeliveryAddress:
                orderDeliveryAddress +
                " Singapore " +
                resultSet.outlet_postal_code,
            });

            /* Success time slot response */
            if (timeslt.data.status === "success") {
              this.setState({
                getDateTimeFlg: "yes",
                pickup_option_true: false,
              });
            } else {
              this.setState({ pickup_option_true: false });
            }
          }
          /* Error response */
          if (res.data.status === "error") {
            this.setState({ deliveryInfo: deliveryInfo });
            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Please enter valid postal code.";
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  destroyCart(clear = "No") {
    this.props.destroyCartDetail();
    this.deleteOrderCookie(clear);
  }

  destroyCartShopeType() {
    this.props.destroyCartDetail();
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });

    /* Delivery avilablity */
    /* cookie.remove("orderDateTime");
    cookie.remove("deliveryDate");
    cookie.remove("deliveryTime"); */
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    cookie.remove("cart_location_id_site", { path: "/" });
    location.reload();
  }

  deleteOrderCookie(clear = "Yes") {
    if (clear == "Yes") {
      cookie.remove("orderZoneId", { path: "/" });
      cookie.remove("orderOutletId", { path: "/" });
    }

    removeOrderDateTime();
    removePromoCkValue();
    cookie.remove("orderPaymentMode", { path: "/" });
    cookie.remove("orderTableNo", { path: "/" });
    cookie.remove("product_remarks", { path: "/" });
    cookie.remove("orderOutletName", { path: "/" });
    cookie.remove("carttotalitems", { path: "/" });
    cookie.remove("cartsubtotal", { path: "/" });
    cookie.remove("cartid", { path: "/" });
    cookie.remove("firstNavigation", { path: "/" });

    /* Delivery avilablity */
    cookie.remove("orderDateTime", { path: "/" });
    cookie.remove("deliveryDate", { path: "/" });
    cookie.remove("deliveryTime", { path: "/" });
    cookie.remove("unitNoOne", { path: "/" });
    cookie.remove("unitNoTwo", { path: "/" });

    cookie.remove("promotion_id", { path: "/" });
    cookie.remove("promotion_applied", { path: "/" });
    cookie.remove("promotion_code", { path: "/" });
    cookie.remove("promotion_delivery_charge_applied", { path: "/" });
    cookie.remove("promotion_amount", { path: "/" });
    cookie.remove("promotion_category", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });

    /*Remove voucher*/
    cookie.remove("voucher_applied", { path: "/" });
    cookie.remove("voucher_code", { path: "/" });
    cookie.remove("voucher_amount", { path: "/" });

    cookie.remove("points_redeemed", { path: "/" });
    cookie.remove("points_used", { path: "/" });
    cookie.remove("points_amount", { path: "/" });
    cookie.remove("prmo_type", { path: "/" });
    cookie.remove("cart_location_id_site", { path: "/" });
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    var postalcode = "";
    if (first) {
      if (first == 2) {
        if (availability === deliveryId) {
          postalcode = cookie.load("deliveryPostalCode");
        } else {
          postalcode = cookie.load("orderPostalCode");
        }
      } else {
        postalcode = this.state.pickup_postal_code; //$("#postalcode").val();
      }
    } else {
      postalcode = this.state.pickup_postal_code; //$("#postalcode1").val();
    }

    var default_outlet_id =
      cookie.load("orderOutletId") !== "" &&
      typeof cookie.load("orderOutletId") !== "undefined" &&
      typeof cookie.load("orderOutletId") !== undefined
        ? cookie.load("orderOutletId")
        : "";

    if (
      postalcode !== "" &&
      typeof postalcode !== undefined &&
      typeof postalcode !== "undefined" &&
      typeof postalcode !== null
    ) {
      if (postalcode.length < 5) {
        $(".postal_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }
    } else {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("datetime_selt_sec", "class");

    if (availability === deliveryId) {
      // var default_outlet_id = cookie.load("orderOutletId") !== "" &&
      // typeof cookie.load("orderOutletId") !== "undefined" &&
      // typeof cookie.load("orderOutletId") !== undefined ?   cookie.load("orderOutletId"): '';

      var default_outlet_id = "163";

      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              postalcode +
              "&shop_type=1" +
              "&outlet_id=" +
              default_outlet_id
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("datetime_selt_sec", "class");

            var deliveryInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              ///$.magnificPopup.close();
              $(".postal_error").hide();
              $("#postalcode_error").hide();

              var postalcode_value = postalcode;

              if (
                postalcode ===
                res.data.result_set.postal_code_information.zip_code
              ) {
                postalcode_value =
                  res.data.result_set.postal_code_information.zip_code;
              } else {
                postalcode_value = postalcode;
              }

              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              var orderHandled =
                stripslashes(res.data.result_set.outlet_name) +
                ", Crew will be seeing you in " +
                res.data.result_set.outlet_delivery_timing +
                " Minutes";

              deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
              deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
              deliveryInfo["orderOutletName"] = stripslashes(
                res.data.result_set.outlet_name
              );
              deliveryInfo["orderPostalCode"] = postalcode_value;
              deliveryInfo["orderTAT"] =
                res.data.result_set.outlet_delivery_timing;
              deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
              deliveryInfo["orderHandled"] = orderHandled;
              deliveryInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                res.data.result_set.outlet_unit_number1,
                res.data.result_set.outlet_unit_number2
              );

              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode_value;

              deliveryInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedAvilablityId_delivery: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: res.data.result_set.outlet_delivery_timing,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress + " Singapore " + postalcode_value,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode_value, { path: "/" });
                if (availability === deliveryId) {
                  cookie.save("deliveryPostalCode", postalcode_value, {
                    path: "/",
                  });
                  cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
                    path: "/",
                  });
                  cookie.save("defaultAvilablityId", deliveryId, {
                    path: "/",
                  });
                } else {
                  cookie.save("defaultAvilablityId", pickupId, {
                    path: "/",
                  });
                }
                cookie.save("orderZoneId", deliveryInfo["orderZoneId"], {
                  path: "/",
                });

                cookie.save("orderOutletId", deliveryInfo["orderOutletId"], {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode_value);
                var orderDate =
                  cookie.load("deliveryDate") !== "" &&
                  typeof cookie.load("deliveryDate") !== undefined &&
                  typeof cookie.load("deliveryDate") !== "undefined"
                    ? cookie.load("deliveryDate")
                    : "";
                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !==
                    "undefined" &&
                  orderDate !== ""
                ) {
                  location.reload();
                }
                if (orderDate !== "") {
                  // return false;
                }
              }

              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({
                  getDateTimeFlg: "yes",
                  deliveryaddresshow: true,
                });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({
                deliveryInfo: deliveryInfo,
                deliveryaddresshow: false,
              });

              if (first === 0) {
                $("#postalcode_error").show();
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $("#postalcode_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }

              if (first === 1) {
                $(".postal_error").show();
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    } else {
      axios
        .all([
          axios.get(
            apiUrl +
              "outlets/pickup_outlets?app_id=" +
              appId +
              "&availability=" +
              availability +
              "&outlet_id=" +
              default_outlet_id
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability /*  +
          "&outletId=" +
          outletIdTxt */
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("datetime_selt_sec", "class");

            var pickupInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              // $.magnificPopup.close();
              var resultSet = res.data.result_set[0];
              var orderDeliveryAddress =
                resultSet.outlet_unit_number1 +
                " " +
                resultSet.outlet_unit_number2;
              var orderHandled =
                stripslashes(resultSet.outlet_name) +
                ", Crew will be seeing you in " +
                resultSet.outlet_pickup_tat +
                " Minutes";

              pickupInfo["orderOutletId"] = resultSet.outlet_id;
              pickupInfo["orderOutletName"] = stripslashes(
                resultSet.outlet_name
              );
              pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
              pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

              pickupInfo["orderHandled"] = orderHandled;
              pickupInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                resultSet.outlet_unit_number1,
                resultSet.outlet_unit_number2
              );

              var orderHandledText =
                resultSet.outlet_address_line1 +
                " " +
                resultSet.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              pickupInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                pickupInfo: pickupInfo,
                seletedAvilablityId: availability,
                seletedAvilablityId_delivery: availability,
                defaultAvilTy: availability,
                seletedOutletId: resultSet.oa_outlet_id,
                order_tat_time: resultSet.outlet_pickup_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  resultSet.outlet_postal_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });
                cookie.save("orderOutletId", pickupInfo["orderOutletId"], {
                  path: "/",
                });
                cookie.save("defaultAvilablityId", pickupId, {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);

                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !== "undefined"
                ) {
                  location.reload();
                }
                // return false;
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({
                  getDateTimeFlg: "yes",
                  deliveryaddresshow: true,
                });

                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup",
                  },
                  type: "inline",
                });
              } else {
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({
                deliveryInfo: deliveryInfo,
                deliveryaddresshow: false,
              });
              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    }
  }

  findOutletBasedEmptyZone(availability) {
    var default_outlet_id = "163";
    if (
      availability === deliveryId &&
      cookie.load("orderPostalCode") !== "" &&
      typeof cookie.load("orderPostalCode") !== undefined &&
      typeof cookie.load("orderPostalCode") !== "undefined"
    ) {
      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              cookie.load("orderPostalCode") +
              "&shop_type=1" +
              "&outlet_id=" +
              default_outlet_id
          ),
        ])
        .then(
          axios.spread((res) => {
            if (res.data.status === "ok") {
              cookie.save("orderZoneId", res.data.result_set.zone_id, {
                path: "/",
              });
              this.props.getZoneDetail("", res.data.result_set.zone_id, 1);
              location.reload();
            }
          })
        );
    }
  }

  findOutletBasedZone_home(first, availability) {
    var postalcode = this.state.deliveryPostalCode_home;
    showLoader("datetime_selt_sec", "class");

    var selected_availability = this.state.seletedAvilablityId_delivery;

    // var default_outlet_id = cookie.load("orderOutletId") !== "" &&
    //   typeof cookie.load("orderOutletId") !== "undefined" &&
    //   typeof cookie.load("orderOutletId") !== undefined ?   cookie.load("orderOutletId"): '';

    var default_outlet_id = "163";

    if (availability === deliveryId) {
      axios
        .all([
          axios.get(
            apiUrlV2 +
              "outlets/findOutletZone?app_id=" +
              appId +
              "&availability_id=" +
              availability +
              "&postal_code=" +
              postalcode +
              "&shop_type=1" +
              "&outlet_id=" +
              default_outlet_id
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("delivery_submit_cls", "class");
            hideLoader("datetime_selt_sec", "class");

            var deliveryInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              // $.magnificPopup.close();
              $(".postal_error").hide();
              $("#postalcode_error").hide();

              var postalcode_value = postalcode;

              if (
                postalcode ===
                res.data.result_set.postal_code_information.zip_code
              ) {
                postalcode_value =
                  res.data.result_set.postal_code_information.zip_code;
              } else {
                postalcode_value = postalcode;
              }

              var orderDeliveryAddress =
                res.data.result_set.postal_code_information.zip_buno +
                " " +
                res.data.result_set.postal_code_information.zip_sname;
              var orderHandled =
                stripslashes(res.data.result_set.outlet_name) +
                ", Crew will be seeing you in " +
                res.data.result_set.outlet_delivery_timing +
                " Minutes";

              deliveryInfo["orderZoneId"] = res.data.result_set.zone_id;
              deliveryInfo["orderOutletId"] = res.data.result_set.outlet_id;
              deliveryInfo["orderOutletName"] = stripslashes(
                res.data.result_set.outlet_name
              );
              deliveryInfo["orderPostalCode"] = postalcode_value;
              deliveryInfo["orderTAT"] =
                res.data.result_set.outlet_delivery_timing;
              deliveryInfo["orderDeliveryAddress"] = orderDeliveryAddress;
              deliveryInfo["orderHandled"] = orderHandled;
              deliveryInfo["defaultAvilablityId"] = availability;

              var unitNum = this.showUnitNum(
                res.data.result_set.outlet_unit_number1,
                res.data.result_set.outlet_unit_number2
              );

              var orderHandledText =
                res.data.result_set.outlet_address_line1 +
                " " +
                res.data.result_set.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode_value;

              deliveryInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                deliveryInfo: deliveryInfo,
                seletedAvilablityId: availability,
                seletedAvilablityId_delivery: availability,
                defaultAvilTy: availability,
                seletedOutletId: res.data.result_set.outlet_id,
                order_tat_time: res.data.result_set.outlet_delivery_timing,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress + " Singapore " + postalcode_value,
              });

              cookie.save("orderPostalCode", postalcode_value, { path: "/" });
              if (availability === deliveryId) {
                cookie.save("deliveryPostalCode", postalcode_value, {
                  path: "/",
                });
                cookie.save("nearest_postal_code", postalcode_value, {
                  path: "/",
                });
                cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
                  path: "/",
                });
                cookie.save("defaultAvilablityId", deliveryId, {
                  path: "/",
                });
              } else {
                cookie.save("defaultAvilablityId", pickupId, {
                  path: "/",
                });
              }
              cookie.save("orderZoneId", deliveryInfo["orderZoneId"], {
                path: "/",
              });

              cookie.save("orderOutletId", res.data.result_set.outlet_id, {
                path: "/",
              });

              this.props.sateValChange("selectPostalCode", "Yes");
              this.props.sateValChange("postalcode", postalcode_value);

              var orderDate =
                cookie.load("deliveryDate") !== "" &&
                typeof cookie.load("deliveryDate") !== undefined &&
                typeof cookie.load("deliveryDate") !== "undefined"
                  ? cookie.load("deliveryDate")
                  : "";
              if (
                this.props.match.params.slugRestaurant !== "" &&
                typeof this.props.match.params.slugRestaurant !== undefined &&
                typeof this.props.match.params.slugRestaurant !== "undefined" &&
                orderDate !== ""
              ) {
                location.reload();
              }
              if (orderDate !== "") {
                //return false;
              }

              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({
                  getDateTimeFlg: "yes",
                  deliveryaddresshow: true,
                });
                //removeOrderDateTime();
                removePromoCkValue();

                $.magnificPopup.open({
                  items: {
                    src: "#awesome-popup_home",
                  },
                  type: "inline",
                });
              } else {
                if (first === 1) {
                  $(".postal_error").show();
                  var mgsTxt =
                    res.data.message !== ""
                      ? res.data.message
                      : "Please enter valid postal code.";
                  $(".postal_error").html(
                    '<span class="error">' + mgsTxt + "</span>"
                  );
                }

                this.setState({ deliveryaddresshow: false });
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({
                deliveryInfo: deliveryInfo,
                deliveryaddresshow: false,
              });
              if (first === 0) {
                $("#postalcode_error").show();
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $("#postalcode_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }

              if (first === 1) {
                $(".postal_error_home").show();
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
                $(".postal_error_home").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    } else {
      axios
        .all([
          axios.get(
            apiUrl +
              "outlets/pickup_outlets?app_id=" +
              appId +
              "&availability=" +
              availability +
              "&outlet_id=" +
              default_outlet_id
          ),
          axios.get(
            apiUrlV2 +
              "settings/chkTimeslotIsAvaiable?app_id=" +
              appId +
              "&availability_id=" +
              availability
          ),
        ])
        .then(
          axios.spread((res, timeslt) => {
            hideLoader("datetime_selt_sec", "class");

            var pickupInfo = [];
            /* Success response */
            if (res.data.status === "ok") {
              //$.magnificPopup.close();
              var resultSet = res.data.result_set[0];
              var orderDeliveryAddress =
                resultSet.outlet_unit_number1 +
                " " +
                resultSet.outlet_unit_number2;
              var orderHandled =
                stripslashes(resultSet.outlet_name) +
                ", Crew will be seeing you in " +
                resultSet.outlet_pickup_tat +
                " Minutes";

              pickupInfo["orderOutletId"] = resultSet.outlet_id;
              pickupInfo["orderOutletName"] = stripslashes(
                resultSet.outlet_name
              );
              pickupInfo["orderPostalCode"] = resultSet.outlet_postal_code;
              pickupInfo["orderTAT"] = resultSet.outlet_pickup_tat;

              pickupInfo["orderHandled"] = orderHandled;
              pickupInfo["defaultAvilablityId"] = availability;

              cookie.save("orderOutletId", resultSet.outlet_id, { path: "/" });

              var unitNum = this.showUnitNum(
                resultSet.outlet_unit_number1,
                resultSet.outlet_unit_number2
              );

              var orderHandledText =
                resultSet.outlet_address_line1 +
                " " +
                resultSet.outlet_address_line2 +
                ", " +
                unitNum +
                " Singapore " +
                postalcode;

              pickupInfo["orderHandledByText"] = orderHandledText;

              this.setState({
                pickupInfo: pickupInfo,
                seletedAvilablityId: availability,
                seletedAvilablityId_delivery: availability,
                defaultAvilTy: availability,
                seletedOutletId: resultSet.oa_outlet_id,
                order_tat_time: resultSet.outlet_pickup_tat,
                orderHandled: orderHandled,
                orderDeliveryAddress:
                  orderDeliveryAddress +
                  " Singapore " +
                  resultSet.outlet_postal_code,
              });

              if (this.state.selectPostalCode === "Yes") {
                cookie.save("orderPostalCode", postalcode, { path: "/" });

                cookie.save("defaultAvilablityId", pickupId, {
                  path: "/",
                });

                this.props.sateValChange("selectPostalCode", "Yes");
                this.props.sateValChange("postalcode", postalcode);

                if (
                  this.props.match.params.slugRestaurant !== "" &&
                  typeof this.props.match.params.slugRestaurant !== undefined &&
                  typeof this.props.match.params.slugRestaurant !== "undefined"
                ) {
                  location.reload();
                }
              }
              /* Success time slot response */
              if (timeslt.data.status === "success") {
                this.setState({
                  getDateTimeFlg: "yes",
                  deliveryaddresshow: true,
                });
                removePromoCkValue();
              }
            }

            /* Error response */
            if (res.data.status === "error") {
              this.setState({
                deliveryInfo: deliveryInfo,
                deliveryaddresshow: false,
              });
              if (first === 0) {
                var mgsTxt =
                  res.data.message !== ""
                    ? res.data.message
                    : "Please enter valid postal code.";
                $(".postal_error").html(
                  '<span class="error">' + mgsTxt + "</span>"
                );
              }
            }
          })
        );
    }
  }

  /* Select outlet */
  selectOutlet(first, availability) {
    if (first) {
      var postalcode = $("#postalcode").val();
    } else {
      var postalcode = $("#postalcode1").val();
    }

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");

    axios
      .get(
        apiUrl +
          "outlets/find_outlet?skip_timing=Yes&app_id=" +
          appId +
          "&availability_id=" +
          availability +
          "&postal_code=" +
          postalcode
      )
      .then((res) => {
        hideLoader("delivery_submit_cls", "class");

        /* Success response */
        if (res.data.status === "ok") {
          // $.magnificPopup.close();
          var orderDeliveryAddress =
            res.data.result_set.postal_code_information.zip_buno +
            " " +
            res.data.result_set.postal_code_information.zip_sname;
          var orderHandled =
            stripslashes(res.data.result_set.outlet_name) +
            ", Crew will be seeing you in " +
            res.data.result_set.outlet_delivery_timing +
            " Minutes";

          var postalcode_value = postalcode;

          if (
            postalcode === res.data.result_set.postal_code_information.zip_code
          ) {
            postalcode_value =
              res.data.result_set.postal_code_information.zip_code;
          } else {
            postalcode_value = postalcode;
          }

          this.setState({
            orderDeliveryAddress:
              orderDeliveryAddress + " Singapore " + postalcode_value,
          });
          this.setState({ orderHandled: orderHandled });

          cookie.save("orderOutletId", res.data.result_set.outlet_id, {
            path: "/",
          });
          cookie.save(
            "orderOutletName",
            stripslashes(res.data.result_set.outlet_name),
            { path: "/" }
          );
          cookie.save("orderPostalCode", postalcode_value, { path: "/" });
          cookie.save("orderTAT", res.data.result_set.outlet_delivery_timing, {
            path: "/",
          });
          cookie.save("orderDeliveryAddress", orderDeliveryAddress, {
            path: "/",
          });
          cookie.save("orderHandled", orderHandled, { path: "/" });
          cookie.save("defaultAvilablityId", availability, { path: "/" });
          this.setState({ defaultAvilTy: availability });

          var orderHandledText =
            res.data.result_set.outlet_address_line1 +
            " " +
            res.data.result_set.outlet_address_line2 +
            ", Singapore " +
            postalcode;
          cookie.save("orderHandledByText", orderHandledText, { path: "/" });

          removeOrderDateTime();
          removePromoCkValue();

          // $.magnificPopup.open({
          //   items: {
          //     src: "#awesome-popup",
          //   },
          //   type: "inline",
          // });
        }

        /* Error response */
        if (res.data.status === "error") {
          // $.magnificPopup.close();
          // $.magnificPopup.open({
          //   items: {
          //     src: "#error-postal-popup",
          //   },
          //   type: "inline",
          // });

          if (first === 0) {
            var mgsTxt =
              res.data.message !== ""
                ? res.data.message
                : "Please enter valid postal code.";
            $(".postal_error").html(
              '<span class="error">' + mgsTxt + "</span>"
            );
          }
        }
      });
  }

  gotoProducts() {
    if (
      cookie.load("orderOutletId") == undefined ||
      cookie.load("orderOutletId") == ""
    ) {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } else {
      $.magnificPopup.close();
      if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
        cookie.remove("popuptriggerFrom", { path: "/" });
        this.props.history.push("/");
      } else {
        //this.props.history.push("events/xmas");
        this.props.history.push("/food");
      }
    }
  }

  selectDatetm() {
    /*  var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    if (seletedOutletId !== "" && Object.keys(pickupInfo).length > 0) { */
    showLoader("takeaway-btn-act", "class");

    axios
      .get(
        apiUrlV2 +
          "settings/chkTimeslotIsAvaiable?app_id=" +
          appId +
          "&availability_id=" +
          pickupId /*+
             "&outletId=" +
            seletedOutletId */
      )
      .then((res) => {
        hideLoader("takeaway-btn-act", "class");

        /* Success response */
        if (res.data.status === "success") {
          this.setState({ getDateTimeFlg: "yes" });

          removeOrderDateTime();
          removePromoCkValue();

          // $.magnificPopup.open({
          //   items: {
          //     src: "#awesome-popup",
          //   },
          //   type: "inline",
          // });
        } else {
          $.magnificPopup.open({
            items: {
              src: "#outlet-error-popup",
            },
            type: "inline",
          });
        }
      });
    /*  } else {
      $(".outlet_error").html(
        '<span class="error"> Please choose one outlet.</span>'
      );
    } */
  }

  chooseAvailabilityFun(availability) {
    var defaultAvilTy =
      cookie.load("defaultAvilablityId") !== "" &&
      typeof cookie.load("defaultAvilablityId") !== undefined &&
      typeof cookie.load("defaultAvilablityId") !== "undefined"
        ? cookie.load("defaultAvilablityId")
        : "";

    var orderPostalCode = cookie.load("orderPostalCode");
    var deliveryPostalCode = cookie.load("deliveryPostalCode");

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined"
    ) {
      if (availability === deliveryId) {
        if (
          deliveryPostalCode !== "" &&
          typeof deliveryPostalCode !== undefined &&
          typeof deliveryPostalCode !== "undefined"
        ) {
        } else {
          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          return false;
        }
      }
      cookie.remove("defaultAvilablityId", { path: "/" });
      cookie.save("defaultAvilablityId", availability, { path: "/" });
      this.setState({ defaultAvilTy: availability }, function () {
        showLoader("innerproduct", "class");
        axios
          .get(
            apiUrl +
              "cart/update_availability?app_id=" +
              appId +
              "&reference_id=" +
              getReferenceID() +
              "&customer_id=" +
              cookie.load("UserId") +
              "&availability_id=" +
              availability
          )
          .then((res) => {
            cookie.save("defaultAvilablityId", availability, { path: "/" });
          });
      });
      this.props.history.push("/food");
      return false;
    }

    if (
      defaultAvilTy !== "" &&
      typeof defaultAvilTy !== undefined &&
      typeof defaultAvilTy !== "undefined" &&
      defaultAvilTy === availability
    ) {
      if (availability === deliveryId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          $.magnificPopup.open({
            items: {
              src: "#awesome-popup",
            },
            type: "inline",
          });
          return false;
        }
      } else if (availability === pickupId) {
        if (
          orderPostalCode === "" ||
          typeof orderPostalCode === undefined ||
          typeof orderPostalCode === "undefined"
        ) {
          this.setState({ seletedAvilablityId: pickupId });
          return false;
        }
      }
      //this.props.history.push("events/xmas");
      this.props.history.push("/food");
      return false;
    } else {
      if (this.state.cartitemcount > 0) {
        $.magnificPopup.open({
          items: {
            src: "#warning-popup",
          },
          type: "inline",
        });
        return false;
      }
    }

    var popupIdtxt = "";
    if (availability === deliveryId) {
      $(".delivery_outletpoup").find(".outlet_error").html("");
      popupIdtxt = "#awesome-popup";
    } else if (availability === pickupId) {
      this.setState({ seletedAvilablityId: pickupId });
      //popupIdtxt = "#takeaway-popup";
    }
    if (popupIdtxt !== "") {
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  selectDlyOutlet() {
    if (this.state.delivery_outlet_id === "") {
      $(".delivery_outletpoup")
        .find(".outlet_error")
        .html('<span class="error"> Please choose one outlet.</span>');
    } else {
      $.magnificPopup.close();
      $.magnificPopup.open({
        items: {
          src: "#awesome-popup",
        },
        type: "inline",
      });
    }
  }

  handleKeyPress = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.pickupOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ pickupOutlets: matches });
  };

  /* load outlets  */
  loadOutletsList() {
    if (Object.keys(this.state.pickupOutlets).length > 0) {
      return this.state.pickupOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.pickOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeOutlet(outletID) {
    var seletedOutletId = this.state.seletedOutletId;
    var pickupInfo = this.state.pickupInfo;
    var actTxt =
      parseInt(seletedOutletId) === parseInt(outletID) &&
      Object.keys(pickupInfo).length > 0
        ? "active"
        : "";
    return actTxt;
  }

  handleKeyPressDly = (event) => {
    var value = event.target.value.toLowerCase(),
      matches = this.state.deliveryOutletsList.filter(function (item) {
        return (
          item.outlet_address_line1.substring(0, value.length).toLowerCase() ===
            value ||
          item.outlet_postal_code.substring(0, value.length).toLowerCase() ===
            value ||
          stripslashes(item.outlet_name)
            .substring(0, value.length)
            .toLowerCase() === value
        );
      });

    this.setState({ deliveryOutlets: matches });
  };

  showUnitNum(unit1, unit2) {
    unit1 = typeof unit1 !== "undefined" ? unit1 : "";
    unit2 = typeof unit2 !== "undefined" ? unit2 : "";

    if (unit1 !== "") {
      var unitNo = unit2 !== "" ? unit1 + " - " + unit2 : unit1;
    } else {
      var unitNo = unit2;
    }

    return unitNo !== "" ? "" + unitNo : "";
  }

  /* load delivery outlets  */
  loadDeliveryOutletsList() {
    if (Object.keys(this.state.deliveryOutlets).length > 0) {
      return this.state.deliveryOutlets.map((loaddata, index) => (
        <li key={index} className={this.activeDlyOutlet(loaddata.outlet_id)}>
          <a href="/" onClick={this.deliveryOutlet.bind(this, loaddata)}>
            {stripslashes(loaddata.outlet_name)},{" "}
            {loaddata.outlet_address_line1},{" "}
            {this.showUnitNum(
              loaddata.outlet_unit_number1,
              loaddata.outlet_unit_number2
            )}
            , Singapore {loaddata.outlet_postal_code}
          </a>
        </li>
      ));
    } else {
      return (
        <li>
          <a>No Outlet found</a>
        </li>
      );
    }
  }

  activeDlyOutlet(outletID) {
    var orderOutletId =
      this.state.delivery_outlet_id !== ""
        ? this.state.delivery_outlet_id
        : cookie.load("orderOutletId");

    return orderOutletId === outletID ? "active" : "";
  }

  /* pick outlet */
  pickOutlet(loaddata, event) {
    if (event !== "" && event !== undefined) {
      event.preventDefault();
    }

    this.selectDatetm();
    var unitNum = this.showUnitNum(
      loaddata.outlet_unit_number1,
      loaddata.outlet_unit_number2
    );

    var orderHandled =
      stripslashes(loaddata.outlet_name) +
      " " +
      loaddata.outlet_address_line1 +
      " " +
      loaddata.outlet_address_line2 +
      ", " +
      unitNum +
      " Singapore " +
      loaddata.outlet_postal_code;

    var pickupInfo = [];
    pickupInfo["orderOutletId"] = loaddata.outlet_id;
    pickupInfo["orderOutletName"] = stripslashes(loaddata.outlet_name);
    pickupInfo["orderPostalCode"] = loaddata.outlet_postal_code;
    pickupInfo["orderTAT"] = loaddata.outlet_pickup_tat;
    pickupInfo["orderHandled"] = orderHandled;
    pickupInfo["orderHandledByText"] = orderHandled;
    pickupInfo["defaultAvilablityId"] = pickupId;

    this.setState(
      {
        pickupInfo: pickupInfo,
        seletedAvilablityId: pickupId,
        seletedOutletId: loaddata.outlet_id,
        order_tat_time: loaddata.outlet_pickup_tat,
        orderHandled: orderHandled,
      },
      function () {
        this.selectDatetm();
      }.bind(this)
    );
  }

  pickWithoutOutlet() {
    this.selectDatetm();
  }

  deliveryOutlet(loaddata, event) {
    event.preventDefault();
    $(".delivery_outletpoup").find(".outlet_error").html("");
    this.setState(
      { delivery_outlet_id: loaddata.outlet_id },
      function () {
        this.selectDlyOutlet();
      }.bind(this)
    );
  }

  trgContinuBtn(idTxt) {
    $("#" + idTxt).trigger("click");
  }

  componentDidMount() {
    if (this.props.match.path !== "/shop") {
      $("html, body").animate(
        {
          scrollTop: $("body").offset().top,
        },
        100
      );
    }

    if (
      cookie.load("loginpopupTrigger") !== "" &&
      typeof cookie.load("loginpopupTrigger") !== undefined &&
      typeof cookie.load("loginpopupTrigger") !== "undefined"
    ) {
      if (cookie.load("loginpopupTrigger") === "login") {
        $.magnificPopup.open({
          items: {
            src: "#login-split-popup",
          },
          type: "inline",
        });
        cookie.remove("loginpopupTrigger", { path: "/" });
      }
    }
    if (
      cookie.load("changeaddress") !== "" &&
      typeof cookie.load("changeaddress") !== undefined &&
      typeof cookie.load("changeaddress") !== "undefined"
    ) {
      if (cookie.load("changeaddress") === "yes") {
        $.magnificPopup.open({
          items: {
            src: "#awesome-popup",
          },
          type: "inline",
        });
        cookie.remove("changeaddress", { path: "/" });
      }
    }

    $(".custom_close").click(function (e) {
      e.preventDefault();
      $(".custom_alertcls, .custom_center_alertcls").hide();
    });

    $(".test-popup-link").magnificPopup({
      type: "image",
      showCloseBtn: true,
      verticalFit: true,
      callbacks: {
        change: function () {
          this.wrap.addClass("awardpopup");
        },
      },
      // other options
    });

    this.props.getMenuData("copper-header-menu");
    if ($(".open-popup-link").length > 0) {
      $(".open-popup-link").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    if ($(".trigger_login").length > 0) {
      $(".trigger_login").magnificPopup({
        type: "inline",
        midClick: true,
      });
    }

    /* Input lable animation */
    if ($(".input-focus").length > 0) {
      $(".input-focus").focus(function () {
        $(this).parents(".focus-out").addClass("focused");
      });
      $(".input-focus").blur(function () {
        var inputValue = $(this).val();
        if (inputValue == "") {
          $(this).removeClass("filled");
          $(this).parents(".focus-out").removeClass("focused");
        } else {
          $(this).addClass("filled");
        }
      });
    }

    if ($(".hsearch_trigger").length > 0) {
      $(".hsearch_trigger").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec").toggleClass("open");
        $(".hsearch_sec .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(".hsearch_trigger, .hsearch_sec, .hsearch_sec * ")
        ) {
          if ($(".hsearch_sec").is(":visible")) {
            $(".hsearch_sec").removeClass("open");
            $(".hsearch_trigger").removeClass("active");
            $(".hsearch_sec .form-control").blur();
          }
        }
      });
    }

    if ($(".hsearch_trigger_mbl").length > 0) {
      $(".hsearch_trigger_mbl").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".search_result_mbl").hide();
        $(this).toggleClass("active");
        $(".hsearch_sec_mbl").toggleClass("open");
        $(".hsearch_sec_mbl .form-control").focus();
      });
      $(document).click(function (e) {
        if (
          !$(e.target).is(
            ".hsearch_trigger_mbl, .hsearch_sec_mbl, .hsearch_sec_mbl * "
          )
        ) {
          if ($(".hsearch_sec_mbl").is(":visible")) {
            $(".hsearch_sec_mbl").removeClass("open");
            $(".hsearch_trigger_mbl").removeClass("active");
            $(".hsearch_sec_mbl .form-control").blur();
          }
        }
      });
    }

    if ($(".mobile_mainacc_menutrigger").length > 0) {
      $(".mobile_mainacc_menutrigger").click(function (e) {
        e.stopPropagation();
        if ($(".mobile_mainacc_menulist").is(":visible")) {
          $(".mobile_mainacc_menulist").hide();
        } else {
          $(".mobile_mainacc_menulist").show();
        }
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".mobile_account_item, .mobile_account_item * ")) {
          if ($(".mobile_mainacc_menulist").is(":visible")) {
            $(".mobile_mainacc_menulist").hide();
          }
        }
      });
    }

    /*NEW MENU SECTION*/
    if ($(".mobile_account_menu_click").length > 0) {
      $(".mobile_account_menu_click").click(function (e) {
        e.preventDefault();
        e.stopPropagation();
        $(".mobile_account_menu").slideToggle();
      });
      $(document).click(function (e) {
        if (!$(e.target).is(".arrow_myaccount, .mobile_account_menu_col")) {
          if ($(".mobile_account_menu").is(":visible")) {
            $(".mobile_account_menu").slideToggle();
          }
        }
      });
    }

    /* Mobile header menu */
    $("body").on("click", ".mobile-menu-more", function () {
      $(this).toggleClass("active");
      $(".product-mobile-menu").toggleClass("active");
    });
    $("body").on("click", ".product-mobile-menu", function () {
      $("mobile-menu-more").toggleClass("active");
      $(this).toggleClass("active");
    });

    $(document).click(function (e) {
      if (!$(e.target).is(".product-mobile-menu")) {
        if ($(".product-mobile-menu").hasClass("open")) {
          $(".hcategory_menu").removeClass("open");
          $(".mobile-menu-more").removeClass("active");
        }
      }
    });

    $(".disbl_href_action").click(function (e) {
      e.preventDefault();
    });

    /*this.getOptions.bind(this,"mill");*/
    //  this.getSearchProductList();

    var currentThis = this;
    if (
      cookie.load("cart_group_id") !== "" &&
      typeof cookie.load("cart_group_id") !== undefined &&
      typeof cookie.load("cart_group_id") !== "undefined" &&
      cookie.load("group_start_time") !== "" &&
      typeof cookie.load("group_start_time") !== undefined &&
      typeof cookie.load("group_start_time") !== "undefined" &&
      this.state.group_order_confirm === ""
    ) {
      var countDownDate = new Date(
        cookie.load("group_start_time").split('"').join("")
      ).getTime();
      var x = setInterval(function () {
        if ($("#pending_time_left").length > 0) {
          $("#pending_time_left").show();
        }
        var now = new Date().getTime();
        var distance = countDownDate - now;
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        $("#pending_time_left").html(
          "Time Left: " + minutes + "m " + seconds + "s "
        );
        if (distance < 0) {
          clearInterval(x);
          currentThis.cancelGrouporder();
          $("#pending_time_left").hide();
          showAlert(
            "Notice",
            "The Link you have followed has expired. Please create link to start you group order."
          );
          $.magnificPopup.open({
            items: {
              src: ".alert_popup",
            },
            type: "inline",
          });
        }
      }, 1000);
    }

    $("#login_password_enter").keyup(function (e) {
      var login_password_enter = $("#login_password_enter").val();
      var login_email_enter = $("#login_email_enter").val();
      if (e.keyCode == 13) {
        currentThis.handleSignin_Submit(
          login_email_enter,
          login_password_enter
        );
      }
    });
  }

  cancelGrouporder() {
    var qs = require("qs");
    var postObject = {
      app_id: appId,
      cart_group_id: cookie.load("cart_group_id"),
      cart_id: cookie.load("cart_id"),
    };
    axios
      .post(apiUrl + "cart/cancel_group", qs.stringify(postObject))
      .then((response) => {
        if (response.data.status === "ok") {
          this.setState({ cartTriggerFlg: "yes" });
          cookie.remove("cart_group_id", { path: "/" });
          cookie.remove("cart_id", { path: "/" });
          cookie.remove("group_order_confirm", { path: "/" });
          cookie.remove("group_start_time", { path: "/" });
          cookie.remove("defaultAvilablityId", { path: "/" });
          this.deleteOrderCookie("Yes");
          setTimeout(function () {
            location.href = siteURL;
          }, 1000);
        } else {
        }
      });
  }

  componentDidUpdate() {}

  getOptions = (input) => {
    var searchProResult = this.state.searchProResult;
    var searchResult = [];
    /*if (Object.keys(searchProResult).length > 0) {*/
    /*searchProResult.map((loadData) =>
        searchResult.push({ value: loadData.value, label: loadData.label })
       );*/
    searchResult.push({ value: "wqewrr", label: "fish cury" });
    searchResult.push({ value: "werew3", label: "fish cury2" });
    return { options: searchResult };
    /*}*/
  };

  ViewProducts(event) {
    var productSlug = event.value;
    //this.props.history.push("/products/cat-ftrpro/slug-ftrpro/" + productSlug);
  }

  checkActiveDivHd(avlType) {
    var clsTxt = "ordericon_link ";
    var availability = this.state.defaultAvilTy;
    var orderOutletId = cookie.load("orderOutletId");
    if (
      availability == avlType &&
      orderOutletId !== "" &&
      orderOutletId !== undefined
    ) {
      clsTxt += "active";
    } else if (
      availability == avlType &&
      (avlType === cateringId || avlType === reservationId)
    ) {
      clsTxt += "active";
    }
    return clsTxt;
  }

  myAccountAction() {
    var currenturl = window.location.href;
    var substringtxt = "myaccount";

    if (currenturl.includes(substringtxt) !== true) {
      return (
        <div>
          <a
            href={baseUrl + "myaccount"}
            className="myacunt_act"
            title="My Account"
          >
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    } else {
      return (
        <div>
          <a className="myacunt_act disbl_href_action" href="/">
            {" "}
            My Account{" "}
          </a>
          <span className="mobile_mainacc_menutrigger"></span>
        </div>
      );
    }
  }

  changPostalValue(type, pstVl) {
    if (type === 1) {
      $("#postalcode").val(pstVl);
    } else {
      $("#postalcode").val(pstVl);
    }
  }

  userAddressList(typeTxt) {
    if (this.state.secondaryaddresslist.length > 0) {
      var addListTxt = this.state.secondaryaddresslist.map((addr, index) => (
        <div className="address_linfo" key={typeTxt + "-" + index}>
          <div className="custom_radio">
            <input
              type="radio"
              name={"address_chk" + typeTxt}
              value={addr.postal_code}
              className="address_chk"
              onChange={this.changPostalValue.bind(
                this,
                typeTxt,
                addr.postal_code
              )}
            />
            <span>
              {addressFormat(
                addr.unit_code,
                addr.unit_code2,
                addr.address,
                addr.country,
                addr.postal_code
              )}
            </span>
          </div>
        </div>
      ));

      return (
        <div>
          {addListTxt}
          <div className="address_linfo" key={typeTxt + "dfl"}>
            <div className="custom_radio">
              <input
                type="radio"
                name={"address_chk" + typeTxt}
                value=""
                defaultChecked={true}
                className="address_chk"
                onChange={this.changPostalValue.bind(this, typeTxt, "")}
              />
              <span>Enter New Address</span>
            </div>
          </div>
        </div>
      );
    } else {
      return "";
    }
  }

  setdateTimeFlg = (field, value) => {
    if (field == "tmflg") {
      this.setState({ getDateTimeFlg: value });
    } else if (field == "ordDate") {
      var ordTime = "";
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_date: value, seleted_ord_time: ordTime });
    } else if (field == "ordTime") {
      var tmSltArr = value;
      $(".ordrdatetime_error").html("");
      this.setState({ seleted_ord_time: tmSltArr["startTime"] });
    } else if (field == "triggerErrorPopup") {
      $.magnificPopup.open({
        items: {
          src: "#outlet-error-popup",
        },
        type: "inline",
      });
    }
  };

  setOrderOutletDateTimeData() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;

    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      console.log(seletedOrdTime, "seletedOrdTime");

      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;

      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });

        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("deliveryPostalCode", orderInfoData["orderPostalCode"], {
            path: "/",
          });
          cookie.save("nearest_postal_code", orderInfoData["orderPostalCode"], {
            path: "/",
          });
        }
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        this.setState({ defaultAvilTy: orderInfoData["defaultAvilablityId"] });
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });

        if (
          this.props.match.path ===
            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
          this.props.match.path ===
            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
          this.props.match.path === "/food/:siteLocation/:slugRestaurant/" ||
          this.props.match.path === "/retail/:siteLocation/:slugRestaurant/"
        ) {
          location.reload();
        }

        $.magnificPopup.close();

        if (this.props.match.path === "/checkout") {
          return false;
        } else if (
          this.props.match.path === "/food/:siteLocation/:slugRestaurant/"
        ) {
          location.reload();
        } else if (cookie.load("popuptriggerFrom") === "FeaturedPro") {
          cookie.remove("popuptriggerFrom", { path: "/" });
          this.props.history.push("/");
        } else if (this.props.match.path === "/brands/:brandSlug") {
          $.magnificPopup.close();
          if (cookie.load("goto_markte_page") === "Yes") {
            var brand_shop_type = cookie.load("brand_shop_type");
            var brand_mall_slug = cookie.load("brand_mall_slug");
            var mall_outlet_count =
              cookie.load("mall_outlet_count") !== "" &&
              typeof cookie.load("mall_outlet_count") !== undefined &&
              typeof cookie.load("mall_outlet_count") !== "undefined"
                ? cookie.load("mall_outlet_count")
                : "";
            var slug_name =
              cookie.load("brand_shop_type") +
              "/" +
              cookie.load("brand_mall_slug") +
              "/" +
              cookie.load("brand_outlet_slug");
            cookie.remove("brand_outlet_slug", { path: "/" });
            cookie.remove("brand_shop_type", { path: "/" });

            //  return false;
            if (
              brand_shop_type !== "" &&
              brand_shop_type !== "undefined" &&
              typeof brand_shop_type !== undefined &&
              typeof brand_shop_type !== "undefined" &&
              brand_mall_slug !== "" &&
              brand_mall_slug !== "undefined" &&
              typeof brand_mall_slug !== undefined &&
              typeof brand_mall_slug !== "undefined"
            ) {
              if (mall_outlet_count > 1) {
                var loc_url = siteURL + "mall/" + brand_mall_slug;
              } else {
                var loc_url = siteURL + slug_name;
              }
              location.href = loc_url;
            } else {
              location.reload();
            }
          } else {
            location.reload();
          }
        } else if (this.props.match.path === "/mall/:sluglocation") {
          $.magnificPopup.close();
          location.reload();
        } else if (this.props.match.path === "/marketplace") {
          $.magnificPopup.close();
          location.reload();
        } else if (this.props.match.path === "/") {
          $.magnificPopup.close();
          if (
            cookie.load("mall_slug") !== "" &&
            cookie.load("mall_slug") !== undefined
          ) {
            var slug_name = "mall/" + cookie.load("mall_slug");
            this.props.history.push(slug_name);
          }
          location.reload();
        }
      }
    } else {
      // const deliveryPostalCode =
      //  cookie.load("deliveryPostalCode") !== "" &&
      //  typeof cookie.load("deliveryPostalCode") !== undefined &&
      //  typeof cookie.load("deliveryPostalCode") !== "undefined"
      //    ? cookie.load("deliveryPostalCode")
      //    : "";

      const deliveryPostalCode =
        this.state.pickup_postal_code !== "" &&
        typeof this.state.pickup_postal_code !== undefined &&
        typeof this.state.pickup_postal_code !== "undefined"
          ? this.state.pickup_postal_code
          : "";
      if (deliveryPostalCode === "" || deliveryPostalCode === undefined) {
        $("#postalcode_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }

      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  setOrderOutletDateTimeData_home() {
    var seletedOrdDate = this.state.seleted_ord_date;
    var seletedOrdTime = this.state.seleted_ord_time;
    if (
      seletedOrdDate !== "" &&
      seletedOrdTime !== "" &&
      seletedOrdDate !== null &&
      seletedOrdTime !== null
    ) {
      var orderInfoData =
        this.state.seletedAvilablityId === pickupId
          ? this.state.pickupInfo
          : this.state.deliveryInfo;

      if (Object.keys(orderInfoData).length > 0) {
        var OrderDate = format(seletedOrdDate, "yyyy-MM-dd");
        var OrderHours = seletedOrdTime.getHours();
        var OrderMunts = seletedOrdTime.getMinutes();
        var OrderSecnd = seletedOrdTime.getSeconds();
        var orderDateTime = new Date(OrderDate);
        orderDateTime.setHours(OrderHours);
        orderDateTime.setMinutes(OrderMunts);
        orderDateTime.setSeconds(OrderSecnd);

        var deliveryDate = format(seletedOrdDate, "dd/MM/yyyy");
        var deliveryTime =
          this.convPad(OrderHours) +
          ":" +
          this.convPad(OrderMunts) +
          ":" +
          this.convPad(OrderSecnd);

        cookie.save("orderDateTime", orderDateTime, { path: "/" });
        cookie.save("deliveryDate", deliveryDate, { path: "/" });
        cookie.save("deliveryTime", deliveryTime, { path: "/" });

        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("orderZoneId", orderInfoData["orderZoneId"], {
            path: "/",
          });
          cookie.save(
            "orderDeliveryAddress",
            orderInfoData["orderDeliveryAddress"],
            { path: "/" }
          );
        }

        cookie.save("orderOutletId", orderInfoData["orderOutletId"], {
          path: "/",
        });
        cookie.save("orderOutletName", orderInfoData["orderOutletName"], {
          path: "/",
        });

        cookie.save("orderPostalCode", orderInfoData["orderPostalCode"], {
          path: "/",
        });
        if (this.state.seletedAvilablityId === deliveryId) {
          cookie.save("deliveryPostalCode", orderInfoData["orderPostalCode"], {
            path: "/",
          });
        }
        cookie.save("orderTAT", orderInfoData["orderTAT"], { path: "/" });
        cookie.save("orderHandled", orderInfoData["orderHandled"], {
          path: "/",
        });
        cookie.save(
          "defaultAvilablityId",
          orderInfoData["defaultAvilablityId"],
          { path: "/" }
        );
        this.setState({ defaultAvilTy: orderInfoData["defaultAvilablityId"] });
        cookie.save("orderHandledByText", orderInfoData["orderHandledByText"], {
          path: "/",
        });

        $.magnificPopup.close();
        this.props.history.push("/marketplace");
      }
    } else {
      const deliveryPostalCode =
        cookie.load("deliveryPostalCode") !== "" &&
        typeof cookie.load("deliveryPostalCode") !== undefined &&
        typeof cookie.load("deliveryPostalCode") !== "undefined"
          ? cookie.load("deliveryPostalCode")
          : "";
      if (deliveryPostalCode === "" || deliveryPostalCode === undefined) {
        $("#postalcode_error").html(
          '<span class="error">Please enter valid postal code.</span>'
        );
        return false;
      }

      $(".ordrdatetime_error").html(
        '<span class="error"> Please select order date and time.</span>'
      );
    }
  }

  convPad(d) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }

  cart_Outlet_List = (field, value) => {
    if (
      this.props.location.pathname !== "/myaccount" &&
      this.props.location.pathname !== "/myorders" &&
      this.props.location.pathname !== "/mypromotions" &&
      this.props.match.path !== "/thankyou/:orderId"
    ) {
      if (field !== "") {
        if (field === "cartOutletList" && this.state.slugRestaurant !== "") {
          this.props.sateValChange("cartOutletList", value);
        }
        if (field === "totalCartCount") {
          if (this.state.slugRestaurant !== "") {
            this.props.sateValChange("totalCartCount", value);
          }
        }
        if (field === "cartDetails" && value !== "") {
          this.props.sateValChange("cartDetails", value);
        }
        if (field === "cartGroups" && value !== "") {
          this.props.sateValChange("cartGroups", value);
        }
        if (field === "cartflg" && value !== "") {
          this.setState({ cartTriggerFlg: value });
        }
      }
    }
  };
  changeDate() {
    this.findOutletBasedZone(2, this.state.defaultAvilTy);
  }
  /* ViewProducts */

  switchOurCart(e) {
    e.preventDefault();
    cookie.remove("group_order_confirm", { path: "/" });
    cookie.remove("cart_group_id", { path: "/" });
    cookie.remove("cart_id", { path: "/" });
    this.setState({ cartTriggerFlg: "yes" });
    $.magnificPopup.close();
  }
  switchOurCartCancel(e) {
    e.preventDefault();
    $.magnificPopup.close();
  }
  changePostalCode(e) {
    e.preventDefault();
    $.magnificPopup.open({
      items: {
        src: "#awesome-popup",
      },
      type: "inline",
    });
    return false;
  }

  availabilityChange() {
    var opentavilablity = true;
    if (this.state.opentavilablity === true) {
      opentavilablity = false;
    }
    this.setState({ opentavilablity: opentavilablity });
  }

  openForgotPassword() {
    $("#close_mobile_menu").trigger("click");
    $(".mobile-menu").removeClass("active");
    $("body").removeClass("mbl-hiden");

    $.magnificPopup.open({
      items: {
        src: "#change-password-popup",
      },
      type: "inline",
    });
    return false;
  }

  handleChangeTxt = (event) => {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;

    $(".postal_error").hide();

    this.setState(
      { pickup_postal_code: postalCode, selectPostalCode: "Yes" },
      function () {
        if (parseInt(postCdLth) >= 6) {
          this.findOutletBasedZone(0, deliveryId);
        }
      }
    );
  };

  handleChangeTxt_home = (event) => {
    var postalCode = event.target.value;
    var postCdLth = postalCode !== "" ? postalCode.length : 0;

    $(".postal_error").hide();
    this.setState(
      { deliveryPostalCode_home: postalCode, selectPostalCode: "Yes" },
      function () {
        if (parseInt(postCdLth) >= 6) {
          $(".postal_error_home").hide();
          this.findOutletBasedZone_home(0, deliveryId);
        }
      }
    );
  };

  validateIntval(e) {
    const re = /[0-9]+/g;
    if (!re.test(e.key)) {
      e.preventDefault();
    }
  }

  siteLocation_address_list() {
    var location_id = this.props.siteLocID;

    if (this.props.siteLocID !== "" && this.props.siteLocID !== undefined) {
      location_id = this.props.siteLocID;
    } else {
      location_id =
        cookie.load("sl_location_id") !== "" &&
        typeof cookie.load("sl_location_id") !== undefined &&
        typeof cookie.load("sl_location_id") !== "undefined"
          ? cookie.load("sl_location_id")
          : "";
    }

    if (
      location_id !== "" &&
      location_id !== undefined &&
      location_id !== "undefined"
    ) {
      if (
        this.state.siteLocationList !== undefined &&
        this.state.siteLocationList !== "" &&
        this.state.siteLocationList !== null &&
        location_id !== "" &&
        location_id !== undefined
      ) {
        var site = this.state.siteLocationList.map((item, index) => {
          if (location_id == item.sl_location_id) {
            return (
              <div className="pickup_add" onClick={this.changeDate.bind(this)}>
                <div className="up-delheading">Pickup Location : </div>
                <div className="up-add">
                  <strong>
                    {" "}
                    {item.sl_name}, {""}{" "}
                    {addressFormat(
                      item.sl_pickup_unit_number1,
                      item.sl_pickup_unit_number2,
                      item.sl_pickup_address_line1,
                      item.sl_pickup_address_line2,
                      item.sl_pickup_postal_code
                    )}{" "}
                  </strong>
                </div>
              </div>
            );
          }
        });
        return site;
      }
    } else {
      return (
        <div className="pickup_add" onClick={this.changeDate.bind(this)}>
          <div className="up-delheading">Pickup Location : </div>
          <div className="up-add">
            <strong>
              {" "}
              {""} {cookie.load("orderHandledByText")}{" "}
            </strong>
          </div>
        </div>
      );
    }
  }

  activeShop(event) {
    if (event !== "" && event !== undefined && event !== "undefined") {
      event.preventDefault();
    }
    if (this.props.match.path === "/") {
      if ($("#home_brands").length > 0) {
        $("html, body").animate(
          {
            scrollTop: $("#home_brands").offset().top - 80,
          },
          1000
        );
      }
    } else {
      this.props.history.push("/shop");
    }
  }

  render() {
    var currenturl = window.location.href;
    var substring = "products";
    var isCheckout = "checkout";

    var showCatryName =
      typeof this.props.showCatryName !== "undefined"
        ? this.props.showCatryName
        : "Category Name";

    return (
      <>
        <header>
          {this.state.globalsettings !== "" &&
            this.state.globalsettings.client_web_maintenance_mode === "Yes" && (
              <div class="top-info-menu">
                <div class="container">
                  <div class="info-text">
                    <p>{this.state.globalsettings.client_maintenance_desc}</p>
                  </div>
                </div>
              </div>
            )}

          <div className="header-top-cls">
            <div className="container">
              <div className="logo-main-section">
                <div className="menu_icon trigger_menunav_act">
                  <span className="icon-bar icon-bar1"></span>
                  <span className="icon-bar icon-bar2"></span>
                  <span className="icon-bar icon-bar3"></span>
                </div>
                <div className="mobile-menu">
                  <div className="mobile-menu-header">
                    <div className="mobile-menu-close">
                      <span id="close_mobile_menu"></span>
                    </div>
                  </div>
                  <div className="mobile-menu-body">
                    <ul className="menu">
                      <li>
                        <Link
                          to="/"
                          title="Home"
                          onClick={(e) => {
                            $("#close_mobile_menu").trigger("click");
                          }}
                          className={
                            this.props.match.path === "/" ? "active" : ""
                          }
                        >
                          <span>Home</span>
                        </Link>
                      </li>
                      <li>
                        <a
                          title="Shop"
                          onClick={(e) => {
                            $("#close_mobile_menu").trigger("click");
                          }}
                          href={baseUrl + "shop"}
                          className={
                            this.props.match.path === "/shop" ||
                            this.props.match.path === "/marketplace" ||
                            this.props.match.path === "/mall/:sluglocation" ||
                            this.props.match.path === "/retail/:sluglocation" ||
                            this.props.match.path === "/retail/:sluglocation" ||
                            this.props.match.path ===
                              "/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
                            this.props.match.path ===
                              "/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
                            this.props.match.path ===
                              "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
                            this.props.match.path ===
                              "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
                            this.props.match.path ===
                              "/food/:siteLocation/:slugRestaurant/"
                              ? "active"
                              : ""
                          }
                        >
                          <span>Shop</span>
                        </a>
                      </li>
                      {/* <li>
                        <Link to="/brandlist" title="Brands"
                           onClick={(e) => {
                            $("#close_mobile_menu").trigger("click");
                          }}
                          className={
                          this.props.match.path === "/brandlist" || this.props.match.path === "/brands" || this.props.match.path === "/brands/:brandSlug"  ? "active" : ""
                        } 
                         >
                           <span>Brands</span>
                        </Link>
                      </li>*/}

                      {cookie.load("UserId") && (
                        <li>
                          <a
                            href={baseUrl + "myaccount"}
                            title="My Account"
                            onClick={(e) => {
                              $("#close_mobile_menu").trigger("click");
                            }}
                            className={
                              this.props.match.path === "/myaccount" ||
                              this.props.match.path === "/myorders" ||
                              this.props.match.path === "/rewards" ||
                              this.props.match.path === "/mypromotions"
                                ? "active"
                                : ""
                            }
                          >
                            <span>My Account</span>
                          </a>
                        </li>
                      )}

                      {cookie.load("UserId") && (
                        <li>
                          <a onClick={this.openForgotPassword.bind(this)}>
                            Change Password
                          </a>
                        </li>
                      )}
                      {cookie.load("UserId") && (
                        <li>
                          <Link
                            to="/logout"
                            title="Logout"
                            onClick={(e) => {
                              $("#close_mobile_menu").trigger("click");
                            }}
                          >
                            <span> Logout</span>
                          </Link>
                        </li>
                      )}

                      {!cookie.load("UserId") && (
                        <li>
                          <a
                            href="#login-split-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link htico_sign"
                            title="Login"
                          >
                            Login
                          </a>
                        </li>
                      )}

                      {!cookie.load("UserId") && (
                        <li>
                          <a
                            href="#signup-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link"
                            title="Sign up"
                          >
                            Register
                          </a>
                        </li>
                      )}
                    </ul>

                    {/*<div className="mobile-menu-footer">
                      <a href={void 0} className="social-link">
                        <i className="fa fa-facebook-official"></i>
                      </a>
                      <a href={void 0} className="social-link">
                        <i className="fa fa-instagram"></i>
                      </a>
                    </div>*/}
                  </div>
                </div>
                <div className="logo">
                  <Link to={"/"} title="UP Shop">
                    <img src={mainLogo} alt="Logo" />
                  </Link>
                </div>
                <div className="hmenu-login-section">
                  <ul className="hmenu-login-act">
                    <li className="hsign_sec">
                      <a
                        href="/"
                        className={
                          this.props.match.path === "/" ? "active" : ""
                        }
                        title="Home"
                      >
                        <i /> <span>Home</span>
                      </a>
                    </li>
                    <li className="hsign_sec">
                      <a
                        href={baseUrl + "shop"}
                        title="Shop"
                        className={
                          this.props.match.path === "/shop" ||
                          this.props.match.path === "/marketplace" ||
                          this.props.match.path === "/mall/:sluglocation" ||
                          this.props.match.path === "/retail/:sluglocation" ||
                          this.props.match.path === "/retail/:sluglocation" ||
                          this.props.match.path ===
                            "/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
                          this.props.match.path ===
                            "/retail/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
                          this.props.match.path ===
                            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
                          this.props.match.path ===
                            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
                          this.props.match.path ===
                            "/food/:siteLocation/:slugRestaurant/"
                            ? "active"
                            : ""
                        }
                        onClick={this.activeShop.bind(this)}
                      >
                        <i /> <span>Shop</span>
                      </a>
                    </li>

                    <li className="hsign_sec">
                      <Link to="/mall/up-catering" title="Catering">
                        <i /> <span>Catering</span>
                      </Link>
                    </li>

                    {/*<li className="hsign_sec">
                      <Link to="/brandlist" title="Brands"
                           className={
                          this.props.match.path === "/brandlist" || this.props.match.path === "/brands" || this.props.match.path === "/brands/:brandSlug"  ? "active" : ""
                        }>
                        <i /> <span>Brands</span>
                      </Link>
                       </li>*/}
                    <li className="hsign_sec">
                      {!cookie.load("UserId") && (
                        <>
                          <a
                            href="#login-split-popup"
                            data-effect="mfp-zoom-in"
                            className="open-popup-link htico_sign"
                            title="Login"
                          >
                            <span>Login</span>
                          </a>
                        </>
                      )}
                    </li>
                    {cookie.load("UserId") && (
                      <li className="hsign_sec">
                        <a
                          href={baseUrl + "myaccount"}
                          className={
                            this.props.match.path === "/myaccount" ||
                            this.props.match.path === "/myorders" ||
                            this.props.match.path === "/rewards" ||
                            this.props.match.path === "/mypromotions"
                              ? "active"
                              : ""
                          }
                          title="My Account"
                        >
                          <i /> <span>My Account</span>
                        </a>

                        <ul>
                          <li>
                            <Link
                              className="htico_sign"
                              to="/logout"
                              title="Logout"
                            >
                              <i /> <span>Logout</span>
                            </Link>
                          </li>
                        </ul>
                      </li>
                    )}
                    <CartSideBar
                      {...this.props}
                      headerState={this.state}
                      prpSateValChange={this.props.sateValChange}
                      cartTriggerFlg={
                        this.state.cartTriggerFlg === "yes"
                          ? this.state.cartTriggerFlg
                          : this.props.cartTriggerFlg
                      }
                      cartClearTriggerFlg={this.props.cartClearTriggerFlg}
                      cart_Outlet_List={this.cart_Outlet_List}
                    />
                  </ul>
                  <div id="pending_time_left" style={{ display: "none" }}></div>
                </div>
              </div>
            </div>
          </div>

          {currenturl.includes(substring) && (
            <div className="hcategory_sec">
              <a href="/" className="hcategory_selected_text disbl_href_action">
                {showCatryName} <span className="hcategory_trigger"></span>
              </a>
              <div className="hcategory_menu ">
                <MenuNavigationmob />
              </div>
            </div>
          )}

          <div
            className="custom_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-success-msg">
              Great! Your item has been added to the cart
            </p>{" "}
          </div>

          <div
            className="custom_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-error-msg">Something went wrong</p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-success alert-dismissible1 alert_fixed success_hide"
            id="jquery-common-success-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              type="button"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-success-msg">
              Great! Your item has been added to the cart
            </p>{" "}
          </div>

          <div
            className="custom_center_alertcls alert-danger single-danger alert-dismissible alert_fixed error_hide"
            id="jquery-common-error-msg"
            style={{ display: "none" }}
          >
            {" "}
            <a
              href="/"
              className="custom_close"
              data-dismiss="alert"
              aria-label="Close"
            >
              {" "}
              <span aria-hidden="true">×</span>{" "}
            </a>{" "}
            <p className="jquery-common-error-msg">Something went wrong</p>{" "}
          </div>

          {/* login popup */}

          <div
            id="login-split-popup"
            className="mfp-hide popup_sec login-split-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Choose </h3>
              </div>
              <div className="full-login-new-body">
                <div className="login_pop_sub">
                  <a
                    className="button btn_black btn_minwid open-popup-link"
                    href="#guest-popup"
                    data-effect="mfp-zoom-in"
                  >
                    Guest
                  </a>
                  <a
                    className="button btn_black btn_minwid open-popup-link"
                    href="#login-popup"
                    data-effect="mfp-zoom-in"
                  >
                    Login
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div id="login-popup" className="mfp-hide popup_sec login-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Login </h3>
              </div>
              <div className="full-login-new-body">
                <Login
                  fields={this.state.fields}
                  onChange={this.fieldChange}
                  onValid={this.handleSignin}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Signup popup */}
          <div id="signup-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3> Register </h3>
              </div>
              <div className="full-login-new-body">
                <Signup
                  fields={this.state.fieldssignup}
                  onChange={this.fieldChangeSignup}
                  onValid={this.handleSignup}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Guest Checkout */}
          <div id="guest-popup" className="mfp-hide popup_sec signup-popup">
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>Guest Checkout</h3>
              </div>
              <div className="full-login-new-body">
                <Guestcheckout
                  fields={this.state.fieldscheckout}
                  onChange={this.fieldChangecheckout}
                  onValid={this.handleCheckout}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Forgot Password Popup */}
          <div
            id="forgot-password-popup"
            className="white-popup mfp-hide popup_sec login-popup forgot-password-popup"
          >
            <div className="pop-whole full-login-new">
              <div className="full-login-new-header">
                <h3>Forgot your Password?</h3>
                <p>We'll send you a link to reset your password.</p>
              </div>
              <div className="full-login-new-body">
                <Forgotpassword
                  fields={this.state.fieldsfgtpassword}
                  onChange={this.fieldforgot}
                  onValid={this.forgotpassword}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          {/* Change Password */}
          {cookie.load("userAccountType") === "2" && (
            <div
              id="change-password-popup"
              className="white-popup mfp-hide popup_sec changepw_popup"
            >
              <div className="pouup_in">
                <div id="form-msg"></div>
                <h3 className="title1 text-center">Change Password</h3>
                <Changepassword
                  fields={this.state.fieldschpassword}
                  onChange={this.fieldChangepassword}
                  onValid={this.changepassword}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          )}

          {/* Delivery Popup - Start */}
          <div
            id="delevery-popup"
            className="white-popup mfp-hide popup_sec delivery_outletpoup self_popup"
          >
            <div className="order-body">
              <div className="self_popup_hea_row">
                <div className="self_popup_hea_col_left">
                  <img className="outlet-scooter-img" src={deliveryWtImg} />
                </div>
                <div className="self_popup_hea_col">
                  <h2>Please Select</h2>
                  <p>Your Delivery Outlet</p>
                </div>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Search Outlet</label>
                          <input
                            type="input"
                            className="form-control input-focus"
                            onKeyUp={this.handleKeyPressDly}
                          />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <h2>Nearby Outlets</h2>
                      <ul className="self_outlet_inner">
                        {this.loadDeliveryOutletsList()}
                      </ul>
                      <a
                        className="button disbl_href_action"
                        id="delivery-continue-link"
                        href="/"
                        onClick={this.selectDlyOutlet.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delivery Popup - end */}

          {/*  Delivery Postal code Popup - start */}
          <div
            id="delevery-postcode-popup"
            className="white-popup mfp-hide popup_sec delevery_popup"
          >
            <div className="popup_equalrw">
              {/* <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title">
                    <img className="pop-scooter-img" src={deliveryWtImg} />
                    <h2 className="text-uppercase">Let us know</h2>
                    <small>Your Delivery Location</small>
                  </div>
                </div>
              </div>*/}
              <div className="popup_right">
                <div className="innervmid_in">
                  <div className="popup_switch_availability">
                    <a
                      href={void 0}
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        deliveryId
                      )}
                    >
                      <span>Delivery</span>
                    </a>
                    <a
                      href={void 0}
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        pickupId
                      )}
                    >
                      <span>Pickup</span>
                    </a>
                  </div>

                  {cookie.load("UserId") && (
                    <div className="address-list-cls address-list-main">
                      {this.userAddressList(1)}
                    </div>
                  )}

                  <div className="form-group">
                    <div
                      className={
                        this.state.secondaryaddresslist.length > 0
                          ? "focus-out focused"
                          : "focus-out"
                      }
                    >
                      <h4 className="uper-light">Enter your postal code</h4>
                      <input
                        type="text"
                        id="postalcode"
                        pattern="\d*"
                        maxLength="6"
                        className="form-control input-focus"
                      />
                      <div className="postal_error"></div>
                    </div>
                  </div>

                  <div className="btn_sec">
                    <div className="two-button-row">
                      <div className="go_second">
                        <a
                          href="/"
                          onClick={this.close_popup.bind(this)}
                          className="button button-left disbl_href_action"
                          title="Close"
                        >
                          Close
                        </a>
                      </div>
                      <div className="con_first delivery_submit_cls">
                        <input
                          type="button"
                          onClick={this.findOutletBasedZone.bind(
                            this,
                            1,
                            deliveryId
                          )}
                          className="button button-right delivery_submit"
                          value="Continue"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Delevery Popup - end */}

          {/* Delevery Popup error - start */}
          <div
            id="error-postal-popup"
            className="white-popup mfp-hide popup_sec error_postal_popup"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconUnhappy} />
                    <h2 className="text-uppercase">Sorry </h2>
                    <small>We can't find your postal code</small>
                  </div>
                </div>
              </div>
              <div className="popup_right">
                <div className="innervmid_in">
                  {/*<h4>Enter your postal code</h4>*/}

                  {cookie.load("UserId") && (
                    <div className="address-list-cls address-list-error">
                      {this.userAddressList(2)}
                    </div>
                  )}

                  <form className="form_sec">
                    <div className="form-group">
                      <div
                        className={
                          this.state.secondaryaddresslist.length > 0
                            ? "focus-out focused"
                            : "focus-out"
                        }
                      >
                        <label>Enter your postal code</label>
                        <input
                          type="text"
                          id="postalcode1"
                          pattern="\d*"
                          maxLength="6"
                          className="form-control input-focus"
                        />
                        <div className="postal_error"></div>
                      </div>
                    </div>
                    <div className="btn_sec delivery_submit_cls delivery_submit_div">
                      {/*<input type="button" onClick={this.selectOutlet.bind(this, 0, deliveryId)} className="button delivery_submit" value="Continue" />*/}
                      <input
                        type="button"
                        onClick={this.findOutletBasedZone.bind(
                          this,
                          0,
                          deliveryId
                        )}
                        className="button delivery_submit"
                        value="Continue"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Delevery Popup error - Start */}

          {/* success popup - Start */}
          <div
            id="awesome-popup"
            className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
          >
            <div className="popup_equalrw">
              <div className="popup_right">
                <div className="innervmid_in">
                  <div className="popup_switch_availability">
                    <a
                      href={void 0}
                      className={
                        this.state.delivery_option_true === true &&
                        this.state.defaultAvilTy === deliveryId
                          ? "active"
                          : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        deliveryId
                      )}
                    >
                      <span>Delivery</span>
                    </a>
                    <a
                      href={void 0}
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        pickupId
                      )}
                    >
                      <span>Pickup</span>
                    </a>
                  </div>

                  {this.state.delivery_option_true === true &&
                    this.state.defaultAvilTy === deliveryId && (
                      <div className="form-group">
                        <h4 className="uper-light">Your Delivery Location</h4>
                        <div
                          className={
                            cookie.load("orderPostalCode") > 0
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <input
                            type="text"
                            id="postalcode"
                            maxLength="6"
                            onKeyPress={(e) => this.validateIntval(e)}
                            className="form-control input-focus"
                            onChange={this.handleChangeTxt.bind(this)}
                            value={this.state.pickup_postal_code}
                          />
                          <div
                            className="postal_error"
                            id="postalcode_error"
                          ></div>
                        </div>
                        {this.state.seletedAvilablityId === deliveryId &&
                          this.state.deliveryaddresshow == true && (
                            <div className="greenwith-add">
                              <h5>
                                <img src={greentick} />
                                {this.state.orderDeliveryAddress}
                              </h5>
                            </div>
                          )}
                      </div>
                    )}

                  <div className="datetime_selt_sec">
                    <div className="datetime_selt_lbl">Date &amp; Time</div>

                    <OrderdatetimeSlot
                      {...this.props}
                      hdrState={this.state}
                      setdateTimeFlg={this.setdateTimeFlg}
                    />

                    <div className="ordrdatetime_error"></div>
                  </div>

                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.setOrderOutletDateTimeData.bind(this)}
                      className="button"
                      value="Take Me Shopping!"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* success popup - Start */}
          <div
            id="awesome-popup_home"
            className="white-popup mfp-hide popup_sec delevery_popup delevery_popup_datetime"
          >
            <div className="popup_equalrw">
              <div className="popup_right">
                <div className="innervmid_in">
                  <div className="popup_switch_availability">
                    <a
                      href={void 0}
                      className={
                        this.state.pickup_option_true === true ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader_home.bind(
                        this,
                        deliveryId
                      )}
                    >
                      <span>Delivery</span>
                    </a>
                    <a
                      href={void 0}
                      onClick={this.changeAvailabilityheader_home.bind(
                        this,
                        pickupId
                      )}
                      className={this.state.addactiveclass}
                    >
                      <span>Pickup</span>
                    </a>
                  </div>

                  {this.state.pickup_option_true === true &&
                    this.state.defaultAvilTy === deliveryId && (
                      <div className="form-group">
                        <h4 className="uper-light">Your Delivery Location</h4>
                        <div
                          className={
                            cookie.load("orderPostalCode") > 0
                              ? "focus-out focused"
                              : "focus-out"
                          }
                        >
                          <input
                            type="text"
                            id="postalcode"
                            maxLength="6"
                            onKeyPress={(e) => this.validateIntval(e)}
                            className="form-control input-focus popuppostalcode"
                            onChange={this.handleChangeTxt_home.bind(this)}
                            value={this.state.deliveryPostalCode_home}
                          />
                          <div
                            className="postal_error"
                            id="postalcode_error"
                          ></div>
                        </div>
                        {this.state.seletedAvilablityId === deliveryId &&
                          this.state.deliveryaddresshow === true && (
                            <div className="greenwith-add">
                              <h5>
                                <img src={greentick} />
                                {this.state.orderDeliveryAddress}
                              </h5>
                            </div>
                          )}
                      </div>
                    )}

                  <div className="datetime_selt_sec">
                    <div className="datetime_selt_lbl">Date &amp; Time</div>

                    {
                      /* !currenturl.includes(isCheckout) && ( */
                      <OrderdatetimeSlot
                        {...this.props}
                        hdrState={this.state}
                        setdateTimeFlg={this.setdateTimeFlg}
                      />
                      /*  ) */
                    }

                    <div className="ordrdatetime_error"></div>
                  </div>

                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.setOrderOutletDateTimeData_home.bind(this)}
                      className="button"
                      value="Take Me Shopping!"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* success popup - Start */}
          <div
            id="awesome-popup-old"
            className="white-popup mfp-hide awesome_popup"
          >
            <div className="popup_equalrw">
              <div className="popup_ttsec">
                <div className="innervmid_in">
                  <div className="pop_title poptt_icontop text-center">
                    <img src={iconWin} />
                    <h2 className="text-uppercase">Awesome</h2>
                    <small>We can Deliver !</small>
                  </div>
                  <div className="awesome_del">
                    <h5>Your Delivery Address :</h5>
                    <h2>{this.state.orderDeliveryAddress}</h2>
                  </div>
                  <div className="btn_sec">
                    <input
                      type="button"
                      onClick={this.gotoProducts.bind(this)}
                      className="button"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* success popup - end */}

          {/* error Popup - start */}
          <div
            id="outlet-error-popup"
            className="white-popup mfp-hide popup_sec warning_popup outlet_error_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningImg} />
                    <h2 className="text-uppercase">Sorry</h2>
                    <p>{"We can`t Deliver At the Moment!"}</p>
                    <p>Please come back again.</p>
                    <div className="alt_btns">
                      {this.state.seletedAvilablityId === pickupId ? (
                        <a
                          href="/"
                          onClick={this.gobckPkupOutletpopup.bind(this)}
                          className="button button-right popup-modal-dismiss disbl_href_action"
                        >
                          change outlet
                        </a>
                      ) : (
                        <a
                          href="/"
                          onClick={this.gobckOutletpopup.bind(this)}
                          className="button button-right popup-modal-dismiss disbl_href_action"
                        >
                          change address
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* error Popup - end */}

          {/* Takeaway Popup - Start */}
          <div
            id="takeaway-popup"
            className="white-popup mfp-hide popup_sec self_popup"
          >
            <div className="order-body">
              <div className="self_popup_hea_row">
                <div className="self_popup_hea_col_left">
                  <img src={takeawayWtImg} />
                </div>
                <div className="self_popup_hea_col">
                  <h2>Please Select</h2>
                  <p>Your Self Collection Outlet</p>
                </div>
              </div>

              <div className="self_pop_row">
                <div className="self_pop_col self_pop_col_right">
                  <div className="self_pop_item">
                    <div className="self_pop_locbx">
                      {/*<h4>Search Cedele Outlet.</h4>*/}
                      <div className="form-group">
                        <div className="focus-out">
                          <label>Search Outlet</label>
                          <input
                            type="input"
                            className="form-control input-focus"
                            onKeyUp={this.handleKeyPress}
                          />
                          <div className="outlet_error"></div>
                        </div>
                      </div>
                    </div>

                    <div className="self_outlet">
                      <h2>Nearby Outlets</h2>
                      <ul className="self_outlet_inner">
                        {this.loadOutletsList()}
                      </ul>
                      <a
                        className="button takeaway-btn-act disbl_href_action"
                        id="takeaway-continue-link"
                        href="/"
                        onClick={this.selectDatetm.bind(this)}
                      >
                        Continue
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Takeaway Popup - end */}

          {/*  Coming Soon Popup - start */}
          <div
            id="comingsoon-popup"
            className="white-popup mfp-hide popup_sec comingsoon_popup"
          >
            <div className="order-body">
              <div className="comingsoon_popup_hea_row">
                <div className="comingsoon_popup_hea_col">
                  <h2>COMING SOON.</h2>
                </div>
              </div>
              <div className="comingsoon_pop_row">
                <p> can you select another availability.</p>
                <a
                  href="/"
                  onClick={this.closepopup.bind(this)}
                  className="button disbl_href_action"
                  title="Go Back"
                >
                  Go Back
                </a>
              </div>
            </div>
          </div>
          {/* Coming Soon Popup - end */}

          {/* Warning Popup - start */}
          <div
            id="warning-popup"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_header">Warning</div>
                  <div className="alert_body">
                    <img className="warning-popup-img" src={warningImg} />
                    <input type="hidden" id="changeaddressheader" />
                    <p>By switching you are about to clear your cart.</p>
                    <p>Do you wish to proceed ?</p>
                    <div className="alt_btns">
                      <a
                        href="/"
                        className="popup-modal-dismiss button button-left disbl_href_action"
                      >
                        No
                      </a>
                      <input type="hidden" value="" id="change_shop_type" />
                      <input type="hidden" value="" id="change_site_location" />
                      <a
                        href="/"
                        onClick={this.changeAvailability.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        Yes
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Warning Popup - end */}

          {/* Warning Maximum Order Popup - start */}
          <div
            id="warning-max-orderpopup"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <h3>Sorry ! Max Limit Reached</h3>
                    <p>
                      We are only able to handle orders from maximum{" "}
                      {this.state.globalsettings !== "" &&
                        this.state.globalsettings.max_order_handle}{" "}
                      restaurants
                    </p>
                    <div className="alt_btns">
                      <a
                        href="/"
                        className="popup-modal-dismiss button button-left disbl_href_action"
                      >
                        CANCEL
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Warning Maximum Order Popup - end */}

          {/* Warning Maximum Order Popup - start */}
          <div
            id="warning-not-deliver"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <h3>Sorry!</h3>
                    <p>
                      We are only able to handle orders from this restaurant
                    </p>
                    <div className="alt_btns">
                      <a
                        href="/"
                        className="popup-modal-dismiss button button-left disbl_href_action"
                      >
                        CANCEL
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Warning Maximum Order Popup - end */}

          {/* Alert Change Group to our cart - start */}
          <div
            id="warning_group_order_close"
            className="white-popup mfp-hide popup_sec warning_popup"
          >
            <div className="custom_alert">
              <div className="custom_alertin">
                <div className="alert_height">
                  <div className="alert_body">
                    <h3>Error</h3>
                    <p>Unable to add any more items to the group order.</p>
                    <p>Clear cart and proceed with normal order ?</p>
                    <div className="alt_btns">
                      <input type="hidden" value="" id="change_shop_type" />
                      <a
                        href="/"
                        onClick={this.switchOurCart.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action group_order_cl"
                      >
                        Yes
                      </a>
                      <a
                        href="/"
                        onClick={this.switchOurCartCancel.bind(this)}
                        className="button button-right popup-modal-dismiss disbl_href_action"
                      >
                        No
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Alert Change Group to our cart - start */}

          {/* Subscriber popup */}

          <div
            id="subscriber-popup"
            className="white-popup mfp-hide popup_sec login-popup"
          >
            <div className="pop-whole">
              <div className="pop-whole-lhs">
                <div className="pop-whole-lhs-inner">
                  <img src={subscribtion} alt="Subscribtion" />
                  <h3>Subscriptions</h3>
                  <p>Update your information and continue</p>
                </div>
              </div>
              <div className="inside-popup-rhs">
                <div className="popup-header textcenter">
                  <h4>Enter Your Information</h4>
                </div>
                <Subscriber
                  fields={this.state.fieldsubscripe}
                  onChange={this.fieldChangeSubscripe}
                  onValid={this.handleSubscripe}
                  onInvalid={() => console.log("Form invalid!")}
                />
              </div>
            </div>
          </div>

          <div className="mbl-menu-overly"></div>
        </header>

        {(this.props.match.path ===
          "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue/:proValue" ||
          this.props.match.path ===
            "/food/:siteLocation/:slugRestaurant/:slugType/:slugValue" ||
          this.props.match.path === "/food/:siteLocation/:slugRestaurant/" ||
          this.props.match.path === "/retail/:siteLocation/:slugRestaurant/" ||
          this.props.match.path === "/marketplace") && (
          <div className="up-header-select headerinfo-boxes marketplace-boxes">
            <div
              className={
                cookie.load("orderDeliveryAddress") !== "" &&
                typeof cookie.load("orderDeliveryAddress") !== undefined &&
                typeof cookie.load("orderDeliveryAddress") !== "undefined"
                  ? "container"
                  : "container address_empty"
              }
            >
              {this.state.defaultAvilTy === deliveryId &&
                cookie.load("deliveryPostalCode") !== "" &&
                typeof cookie.load("deliveryPostalCode") !== undefined &&
                typeof cookie.load("deliveryPostalCode") !== "undefined" && (
                  <div className="up-form-location">
                    <div
                      className="hib hib-box1 form-control marketplace-control"
                      onClick={this.changeDate.bind(this)}
                    >
                      <div className="up-delheading">Delivering To :</div>
                      <div className="up-add">
                        <strong>
                          {" "}
                          {cookie.load("orderDeliveryAddress") +
                            " " +
                            cookie.load("orderPostalCode")}{" "}
                        </strong>
                      </div>
                      <span className="down-arrow"></span>
                    </div>
                    <a
                      href="/"
                      onClick={this.changePostalCode.bind(this)}
                      className="change-location-header"
                    ></a>
                  </div>
                )}

              {this.state.defaultAvilTy === pickupId && (
                <div className="up-form-location">
                  <div className="hib hib-box1 form-control marketplace-control">
                    {this.siteLocation_address_list()}
                  </div>
                </div>
              )}

              <div className="marketplace-form-box">
                <div className="up-form-date">
                  {cookie.load("deliveryDate") !== "" &&
                    typeof cookie.load("deliveryDate") !== undefined &&
                    typeof cookie.load("deliveryDate") !== "undefined" && (
                      <div
                        className="hib hib-box2 form-control"
                        onClick={this.changeDate.bind(this)}
                      >
                        <span>
                          <em>Date & Time :</em>
                          <strong> {cookie.load("deliveryDate")}</strong>
                        </span>
                      </div>
                    )}
                </div>
                <div className="up-form-time">
                  {cookie.load("deliveryTime") !== "" &&
                    typeof cookie.load("deliveryTime") !== undefined &&
                    typeof cookie.load("deliveryTime") !== "undefined" && (
                      <div
                        className="hib hib-box3 form-control"
                        onClick={this.changeDate.bind(this)}
                      >
                        <span>
                          {/* <strong>Time : </strong> */}
                          <strong>
                            {loadHeaderTime(cookie.load("deliveryTime"))}
                          </strong>
                        </span>
                      </div>
                    )}
                </div>
              </div>

              {cookie.load("cart_group_id") === "" ||
              typeof cookie.load("cart_group_id") === undefined ||
              typeof cookie.load("cart_group_id") === "undefined" ? (
                <div className="product-ava">
                  <div className="product-lo-top" id="availability_div">
                    <Link
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        deliveryId
                      )}
                    >
                      <span>Delivery</span>
                    </Link>
                    <Link
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        pickupId
                      )}
                    >
                      <span>Pickup</span>
                    </Link>
                  </div>
                  <span className="pro-line"></span>
                </div>
              ) : (
                <div className="product-ava">
                  <div className="product-lo-top" id="availability_div">
                    <a
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                    >
                      <span>Delivery</span>
                    </a>
                    <a
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                    >
                      <span>Pickup</span>
                    </a>
                  </div>
                  <span className="pro-line"></span>
                </div>
              )}

              <div
                className="custom_alertcls alert-danger required_availability"
                style={{ display: "none" }}
              >
                <p>Please select an available date and time.</p>
              </div>
            </div>
          </div>
        )}

        {(this.props.match.path === "/brands/:brandSlug" ||
          this.props.match.path === "/mall/:sluglocation" ||
          this.props.match.path === "/retail/:sluglocation") && (
          <div className="up-header-select headerinfo-boxes marketplace-boxes">
            <div
              className={
                cookie.load("orderDeliveryAddress") !== "" &&
                typeof cookie.load("orderDeliveryAddress") !== undefined
                  ? "container"
                  : "container address_empty"
              }
            >
              {this.state.defaultAvilTy === deliveryId &&
                cookie.load("deliveryPostalCode") !== "" &&
                typeof cookie.load("deliveryPostalCode") !== undefined &&
                typeof cookie.load("deliveryPostalCode") !== "undefined" && (
                  <div className="up-form-location">
                    <div
                      className="hib hib-box1 form-control marketplace-control"
                      onClick={this.changeDate.bind(this)}
                    >
                      <div className="up-delheading">Delivering To :</div>
                      <div className="up-add">
                        <strong>
                          {" "}
                          {cookie.load("orderDeliveryAddress") +
                            " " +
                            cookie.load("orderPostalCode")}{" "}
                        </strong>
                      </div>
                      <span className="down-arrow"></span>
                    </div>
                    <a
                      href="/"
                      onClick={this.changePostalCode.bind(this)}
                      className="change-location-header"
                    ></a>
                  </div>
                )}

              {this.state.defaultAvilTy === pickupId && (
                <div className="up-form-location">
                  <div className="hib hib-box1 form-control marketplace-control">
                    {this.siteLocation_address_list()}
                  </div>
                </div>
              )}

              {cookie.load("cart_group_id") === "" ||
              typeof cookie.load("cart_group_id") === undefined ||
              typeof cookie.load("cart_group_id") === "undefined" ? (
                <div className="marketplace-form-box">
                  <div className="up-form-date">
                    {cookie.load("deliveryDate") !== "" &&
                      typeof cookie.load("deliveryDate") !== undefined &&
                      typeof cookie.load("deliveryDate") !== "undefined" && (
                        <div
                          className="hib hib-box2 form-control"
                          onClick={this.changeDate.bind(this)}
                        >
                          <span>
                            <em>Date & Time :</em>
                            <strong> {cookie.load("deliveryDate")}</strong>
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="up-form-time">
                    {cookie.load("deliveryTime") !== "" &&
                      typeof cookie.load("deliveryTime") !== undefined &&
                      typeof cookie.load("deliveryTime") !== "undefined" && (
                        <div
                          className="hib hib-box3 form-control"
                          onClick={this.changeDate.bind(this)}
                        >
                          <span>
                            {/* <strong>Time : </strong> */}
                            <strong>
                              {loadHeaderTime(cookie.load("deliveryTime"))}
                            </strong>
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <div className="marketplace-form-box">
                  <div className="up-form-date">
                    {cookie.load("deliveryDate") !== "" &&
                      typeof cookie.load("deliveryDate") !== undefined &&
                      typeof cookie.load("deliveryDate") !== "undefined" && (
                        <div className="hib hib-box2 form-control">
                          <span>
                            <em>Date & Time :</em>
                            <strong> {cookie.load("deliveryDate")}</strong>
                          </span>
                        </div>
                      )}
                  </div>
                  <div className="up-form-time">
                    {cookie.load("deliveryTime") !== "" &&
                      typeof cookie.load("deliveryTime") !== undefined &&
                      typeof cookie.load("deliveryTime") !== "undefined" && (
                        <div className="hib hib-box3 form-control">
                          <span>
                            <strong>
                              {loadHeaderTime(cookie.load("deliveryTime"))}
                            </strong>
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              )}

              {cookie.load("cart_group_id") === "" ||
              typeof cookie.load("cart_group_id") === undefined ||
              typeof cookie.load("cart_group_id") === "undefined" ? (
                <div className="product-ava">
                  <div className="product-lo-top" id="availability_div">
                    <Link
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        deliveryId
                      )}
                    >
                      <span>Delivery</span>
                    </Link>
                    <Link
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                      onClick={this.changeAvailabilityheader.bind(
                        this,
                        pickupId
                      )}
                    >
                      <span>Pickup</span>
                    </Link>
                  </div>
                  <span className="pro-line"></span>
                </div>
              ) : (
                <div className="product-ava">
                  <div className="product-lo-top" id="availability_div">
                    <a
                      className={
                        this.state.defaultAvilTy === deliveryId ? "active" : ""
                      }
                    >
                      <span>Delivery</span>
                    </a>
                    <a
                      className={
                        this.state.defaultAvilTy === pickupId ? "active" : ""
                      }
                    >
                      <span>Pickup</span>
                    </a>
                  </div>
                  <span className="pro-line"></span>
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateTopProps = (state) => {
  var overAllcart = Array();
  var cartDetails = Array();
  var cartItems = Array();
  var siteLocID = "";
  var cartStatus = "";
  var cartTotalItmCount = "";

  if (Object.keys(state.cartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cartlistdetail[0])
      ? Array()
      : state.cartlistdetail[0].result_set;
    if (
      state.cartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllcart = resultSetArr;
      cartDetails = resultSetArr.cart_details;
      cartTotalItmCount = resultSetArr.cart_details.cart_total_items;
      siteLocID = resultSetArr.siteLocID;
      cartStatus = "success";
    } else {
      cartStatus = "failure";
    }
  }

  var zonedetailArr = Array();
  if (Object.keys(state.zonedetail).length > 0) {
    if (state.zonedetail[0].status === "ok") {
      zonedetailArr = state.zonedetail[0].result_set;
    }
  }

  var outletsArr = Array();
  if (Object.keys(state.outlets).length > 0) {
    if (state.outlets[0].status === "ok") {
      outletsArr = state.outlets[0].result_set;
    }
  }

  var alloutletsArr = Array();
  if (Object.keys(state.alloutlets).length > 0) {
    if (state.alloutlets[0].status === "ok") {
      alloutletsArr = state.alloutlets[0].result_set;
    }
  }

  var secondarydataArr = Array();
  if (Object.keys(state.secondaryaddress).length > 0) {
    if (state.secondaryaddress[0].status === "ok") {
      secondarydataArr = state.secondaryaddress[0].result_set;
    }
  }

  return {
    globalsettings: state.settings,
    siteLocationList: state.sitelocation,
    zonedetails: zonedetailArr,
    outletslist: outletsArr,
    alloutletslist: alloutletsArr,
    logindata: state.login,
    fblogin: state.fblogin,
    forgetpassword: state.forgetpassword,
    registration: state.registration,
    menudata: state.menudata,
    secondaryaddresslist: secondarydataArr,
    siteLocID: siteLocID,
    changepassword: state.changepassword,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getGlobalSettings: () => {
      dispatch({ type: GET_GLOBAL_SETTINGS });
    },
    getloadSiteLocation: () => {
      dispatch({ type: GET_SITE_LOCATION });
    },
    getZoneDetail: (outletId, zoneId, shop_type) => {
      dispatch({ type: GET_ZONE_DETAIL, outletId, zoneId, shop_type });
    },
    getPickupOutlets: () => {
      dispatch({ type: GET_PICKUP_OUTLETS });
    },
    getAllOutlets: (availability) => {
      dispatch({ type: GET_ALL_OUTLETS, availability });
    },
    destroyCartDetail: () => {
      dispatch({ type: DESTROY_CART_DETAIL });
    },
    getLoginData: (formPayload) => {
      dispatch({ type: GET_LOGINDATA, formPayload });
    },
    getRegistration: (formPayload) => {
      dispatch({ type: GET_REGISTRATION, formPayload });
    },
    getForgetPassword: (formPayload) => {
      dispatch({ type: GET_FORGET_PASSWORD, formPayload });
    },
    getMenuData: (menuslug) => {
      dispatch({ type: GET_MENUDATA, menuslug });
    },
    getSecondaryAddress: () => {
      dispatch({ type: GET_ALLUSERSECADDRDATA });
    },
    getCartDetail: () => {
      dispatch({ type: GET_CART_DETAIL });
    },
    getChangePassword: (formPayload) => {
      dispatch({ type: GET_CHANGEPASSWORD, formPayload });
    },
  };
};
export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withRouter(Header));
